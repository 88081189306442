import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AuthService, StaticTextService } from '../../services';
import { AutogrowDirective } from '../../directives';
import { CONSTANTS } from '../../app.constants';
import { Router } from '@angular/router';
import * as momentTimeZone from 'moment-timezone';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent extends DialogComponent<any, any> {

  @ViewChild('replyBtn') focusedElement: any;
  @ViewChild('cancelbtn') focusedElement1: any;


  title: any;
  message: any;
  messageText = '';
  t1 = '';
  t2 = '';
  t3 = '';
  
  constants = CONSTANTS;
  isReply: boolean = false;
  constructor(dialogService: DialogService, public text: StaticTextService, public auth: AuthService,private router: Router,   ) {
    super(dialogService);
  }

  ngOnInit() {
    
    // if (this['message']) {
    //   this.messageText += this['message'];
    // }  
    if (this.message == undefined) this.isReply = true;
    else this.isReply = false;
  }

  ngAfterViewInit() {
    
    
    // if (this.focusedElement != undefined) {
    //   this.focusedElement.nativeElement.focus();
    // }
    // if (this.focusedElement == undefined) {
    //   if (this.focusedElement1 != undefined) {
    //     this.focusedElement1.nativeElement.focus();
    //   }
    // }

  }


  reply() {
    this.isReply = true;
    
  }

  isReplyClicked() {
    if (this.isReply) return true;
    else return false;
  }

  send() {
    this.result = this.messageText;
    this.close();
  }


  isString() {
    if (typeof this.message == 'string') return true;
    else return false;
  }

  isMessageEmpty(){
    
    return this.messageText.trim().length == 0;

  }

  Close() {
    this.result = false;
    this.close()
  }
  getClick(id){
    if(this.t1 && this.t1.match('Quote ')) this.router.navigate(['/quote/'+ id + '/view' ])
    else this.router.navigate(['/orders/' + id + '/view'])
    this.close();
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

}
