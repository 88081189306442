import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PricingService, StaticTextService, StripeService } from '@services';
import { getProp } from '@styled';
import * as utils from '@utils';
import { get, isNil, to, __DEV__ } from '@utils';
import { ErrorHandler } from 'app/error-handler/error.handler';

@Component({
	selector: 'address-form',
	template: `
		<form [formGroup]="fc">
			<v-stack spacing="3">
				<!-- name -->
				<skeleton *ngIf="isLoading && config?.name" sx py="2"></skeleton>
				<mat-form-field *ngIf="!isLoading && config?.name">
					<input
						#nameRef
						placeholder="{{ config?.name?.placeholder }}"
						formControlName="name"
						matInput
						required
					/>
					<mat-error sx textAlign="left">{{ _getErr('name') }}</mat-error>
				</mat-form-field>

				<!-- street line 1 -->
				<skeleton *ngIf="isLoading && config?.addressLine1" sx py="2"></skeleton>
				<mat-form-field *ngIf="!isLoading && config?.addressLine1">
					<input
						#addressLine1Ref
						placeholder="{{ config?.addressLine1?.placeholder }}"
						formControlName="addressLine1"
						matInput
						required=""
					/>
					<mat-error sx textAlign="left">{{ _getErr('addressLine1') }}</mat-error>
				</mat-form-field>

				<!-- street line 2 -->
				<skeleton *ngIf="isLoading && config?.addressLine2" sx py="2"></skeleton>
				<mat-form-field *ngIf="!isLoading && config?.addressLine2">
					<input
						#addressLine2Ref
						placeholder="{{ config?.addressLine2?.placeholder }}"
						formControlName="addressLine2"
						matInput
					/>
					<mat-error sx textAlign="left">{{ _getErr('addressLine2') }}</mat-error>
				</mat-form-field>

				<!-- city -->
				<skeleton *ngIf="isLoading && config?.city" sx py="2"></skeleton>
				<mat-form-field *ngIf="!isLoading && config?.city">
					<input
						#cityRef
						placeholder="{{ config?.city?.placeholder }}"
						formControlName="city"
						matInput
						required
					/>
					<mat-error sx textAlign="left">{{ _getErr('city') }}</mat-error>
				</mat-form-field>

				<stack
					*ngIf="config?.state || config?.county || config?.zip"
					direction="['column', 'row']"
					spacing="4"
					sx
					flex="1"
				>
					<!-- state -->
					<skeleton *ngIf="isLoading && config?.state" sx py="2" flex="1"></skeleton>
					<app-mat-single-search
						#stateRef
						*ngIf="!isLoading && config?.state"
						(selectedChange)="_handleChangeState($event)"
						[matSearchCtrl]="fields?.state"
						initializeData="{{ fields?.state?.value }}"
						[entityList]="_states"
						[entityName]="'State'"
						[filterKey]="'State_Short_Name'"
						[matSelPlaceHolder]="'State*'"
						[placeHolderSel]="config?.state?.placeholder"
						[selectAll]="false"
						[selectKey]="'State_Short_Name'"
						required
						sx
						flex="1"
					></app-mat-single-search>

					<!-- county -->
					<skeleton *ngIf="isLoading && config?.county" sx py="2" flex="1"></skeleton>
					<app-mat-single-search
						#countyRef
						*ngIf="!isLoading && config?.county"
						[initializeData]="fields?.county?.value"
						[matSearchCtrl]="fields?.county"
						[entityList]="_counties"
						[entityName]="'County'"
						[filterKey]="'County_Name'"
						[matSelPlaceHolder]="'County'"
						[placeHolderSel]="config?.county?.placeholder"
						[selectAll]="false"
						[selectKey]="'County_Name'"
						required
						sx
						flex="1"
					></app-mat-single-search>

					<!-- zip code -->
					<skeleton
						*ngIf="isLoading && config?.zip"
						sx
						py="2"
						minW="['100%', '30%']"
					></skeleton>
					<mat-form-field *ngIf="!isLoading && config?.zip" sx w="['100%', '30%']">
						<input
							#zipRef
							placeholder="{{ config?.zip?.placeholder }}"
							onkeypress="return event.charCode >= 48 && event.charCode <= 57"
							formControlName="zip"
							matInput
							required
						/>
						<button
							[matMenuTriggerFor]="zipInfo"
							mat-icon-button
							matSuffix
							sx
							_focus="{ 'outline': 'none' }"
						>
							<icon icon="info_outline" size="sm"></icon>
						</button>
						<mat-menu #zipInfo="matMenu" xPosition="before">
							<p sx my="1" mx="4" fontSize="0">
								Don't know your postal code? Please find it
								<a
									target="_blank"
									href="https://tools.usps.com/go/ZipLookupAction!input.action"
									sx
									color="secondary.main"
								>
									here.
								</a>
							</p>
						</mat-menu>
						<mat-error sx textAlign="left">{{ _getErr('zip') }}</mat-error>
					</mat-form-field>
				</stack>
			</v-stack>
		</form>
	`,
})
export class AddressForm implements OnChanges, OnInit {
	_counties: any[] = [];

	_states: string[] = [];

	//
	// ─────────────────────────────────────────────────────── INTERNAL STATE API ─────
	//

	get fields() {
		return this.fc.controls;
	}

	//
	// ─────────────────────────────────────────────────────────── DATA PROPS API ─────
	//

	@Input() config: any;

	@Input() fc: FormGroup;

	@Input() isLoading: boolean = false;

	@Input() initialFocus: string;

	@Input() initialValue: any;

	//
	// ──────────────────────────────────────────────────────── CHILDREN REFS API ─────
	//

	@ViewChild('nameRef') nameRef: ElementRef;

	@ViewChild('addressLine1Ref') addressLine1Ref: ElementRef;

	@ViewChild('addressLine2Ref') addressLine2Ref: ElementRef;

	@ViewChild('cityRef') cityRef: ElementRef;

	@ViewChild('stateRef') stateRef: ElementRef;

	@ViewChild('countyRef') countyRef: ElementRef;

	@ViewChild('zipRef') zipRef: ElementRef;

	@ViewChild('countryRef') countryRef: ElementRef;

	//
	// ──────────────────────────────────────────────────────── LIFECYCLE METHODS ─────
	//

	constructor(
		protected cdr: ChangeDetectorRef,
		protected el: ElementRef,
		protected err: ErrorHandler,
		protected fb: FormBuilder,
		protected pricing: PricingService,
		protected stripe: StripeService,
		protected txt: StaticTextService,
	) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['_addressForm'])) window['_addressForm'] = this;
			if (isNil(window['utils'])) window['utils'] = utils;
		}
	}

	ngOnChanges(props: SimpleChanges) {
		for (const prop in props) {
			const current = getProp(props[prop].currentValue);
			const previous = getProp(props[prop].previousValue);

			if (prop === 'isLoading' && !current) {
				console.log('initial focus');
				this._initialFocus();
			}
		}
	}

	async ngOnInit() {
		if (this.config.state) {
			const [, states] = await to(this._getStates());
			if (this.config.county && this.fields.state.value) {
				const [, counties] = await to(this._getCounties(this.fields.state.value));
			}
		}
	}

	//
	// ───────────────────────────────────────────────────────── INTERNAL METHODS ─────
	//

	_getErr = (field: string) => this.err.getError(this.fc, field);

	_initialFocus = () =>
		this.initialFocus &&
		requestAnimationFrame(() => {
			setTimeout(
				() =>
					get(
						this,
						`${this.initialFocus}Ref.nativeElement.focus`,
						get(
							this,
							`${this.initialFocus}Ref.matSelSearch.focus`,
							get(this, `${this.initialFocus}Ref.focus`),
						),
					),
				100,
			);
		});

	_getStates = async () => {
		const [err, states] = await to(this.pricing.getAvailableStateList());
		if (!err) this._states = states;
	};

	_getCounties = async (state: string) => {
		if (state) {
			const [err, counties] = await to(this.pricing.getCountyList(state));
			if (!err) this._counties = counties;
		}
	};

	_handleChangeState = async (state: string) => {
		if (state && this.config.county) {
			await to(this._getCounties(state));
		}
	};
}
