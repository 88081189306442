
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import { HttpClient, HttpHeaders  } from '@angular/common/http'
import { ConfigService } from '../config/config.service';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class AboutService {

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private auth: AuthService,
    private cookies:CookieService
  ) { }

  getWithMaster(page){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    return this.http.get(this.config.getBasePath() + '/pages/show/' + page, httpOptions).pipe(
    map((response: any) => response,
    (error) => observableThrowError(error.error)));
  }

  getWithToken(page){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    return this.http.get(this.config.getBasePath() + '/pages/get/' + page, httpOptions).pipe(
    map((response: any) => response,
    (error) => observableThrowError(error.error)));
  }

  getEULAByUserId(userId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/users/client/' + userId + '/eula';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateUserEULA(userId, model) {
    model.access_token = this.config.getAccessToken();
    let url = this.config.getBasePath() + '/users/user/' + userId + '/updateEula';
    return this.http.put(url, model).pipe(
      map((response: any) => {
        if (response && response.token) {
          // create a cookies for newuser 
          this.cookies.put('pippinTitleUsr', JSON.stringify(response));
          this.auth.isMultiCliLogin(); 
          this.auth.removeOrg();
        }
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getActiveEULA() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/eula/active/eula';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getUserNPSAcceptance() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/users/user/'+ this.auth.getUserId() + '/npsAcceptance';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  updateUserRating(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/users/user/create/nps/rating';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllCompanyAccountsByUserId(userId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/users/get/all/companies/accounts/user/'+ userId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrgDetailsByUserNOrgId(userId, orgId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/users/get/details/user/' + userId + '/organization/' + orgId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

}
