import { Component, OnInit,Input } from '@angular/core';
import { AuthService, StaticTextService, NewOrderService,MessagesService,SocketService, ConfigService } from '../../services';
import { CONSTANTS } from '../../app.constants'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // constants=CONSTANTS;
  constants:any;
  // @Input() activeNode:any;
  constructor(
    public auth:AuthService,
    public text:StaticTextService,
    private newOrder:NewOrderService,
    public messagesService:MessagesService,
    private socket:SocketService,
    private config:ConfigService
  ) {
    this.constants = CONSTANTS;
   }

  ngOnInit() {
    this.getUnreadCount();
    this.socket.syncMessage("sidebar").subscribe((result)=>{
      this.getUnreadCountBg();
    })
  }
  
  getActiveNode(){
    return this.config.getSideBarNode();
  }

  getUnreadCount() {
    this.messagesService.getAllUnreadCount()
    .subscribe((data) => {       
      
    }, (err) => {
      
    })
  }

  getUnreadCountBg() {
    this.messagesService.getAllBackGroundUnreadCount()
    .subscribe((data) => {       
      
    }, (err) => {
      
    })
  }

  goToNewOrder(){
    this.newOrder.goToNewOrder()
  }

  requestQuote(){
    this.newOrder.goToRequestQuote()
  }

}
