import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ONLY_STRING_REGEX } from '@utils';
import { ConfigService, AuthService } from '@services';

/* ---------------------------------- */

@Component({
	selector: 'billing',
	template: `
		<container>
			<div sx mb="8" px="['6', null, null, '0']">
				<h4 sx variant="h4" fontWeight="medium" textAlign="left" mb="8">Billing</h4>

				<v-stack spacing="10">
					<payment-methods></payment-methods>
					<billing-address></billing-address>
				</v-stack>
			</div>
		</container>
	`,
})
export class Billing implements OnInit, OnDestroy {
	protected _timer: number;

	constructor(protected conf: ConfigService, private auth: AuthService) {}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this._timer = window.setTimeout(() => this.conf.setSideBarNode(0), 0);
	}

	ngOnDestroy() {
		if (this._timer) clearTimeout(this._timer);
	}
}
