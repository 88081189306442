export const card = {
	header: {
		py: 6,
		px: 12,
		borderRadiusTopLeft: 'md',
		borderRadiusTopRight: 'md',
	},
	content: {
		py: 8,
		px: [10, 12],
	},
	actions: {
		w: '100%',
		py: 4,
		px: 8,
		d: 'flex',
		bg: 'bg.paper',
		borderRadiusBottomLeft: 'md',
		borderRadiusBottomRight: 'md',
	},
	hasDivider: {
		header: {
			borderBottom: '1px solid divider.main',
		},
		actions: {
			borderTop: '1px solid divider.main',
		},
	},
};
