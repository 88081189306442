import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CONSTANTS } from '../../app.constants';
import { PricingService } from '@services';

declare var google: any

@Component({
  selector: 'app-address-comp',
  templateUrl: './address-comp.component.html',
  styleUrls: ['./address-comp.component.scss']
})
export class AddressCompComponent implements OnInit {
  @Output() addressObjectData = new EventEmitter<any>();
  @Input() borrowerLabelName: string;
  @Input() borrowerId: string;
  @Input() propSearchLabelName: string;
  @Input() fetchRecordData: string;
  @Input() newOrderForm: FormGroup
  @Input() addressObject: any;
  formGroupData: FormGroup
	allIndepdentCities : any;
	showCityInCounty:boolean = false;

  stateList: any[] = [];
  countyList: any[] = [];

  newOrderModel: any = {};
  constructor(
    private formBuilder:FormBuilder,
    private pricingService: PricingService,
    private ref:ChangeDetectorRef
  ) { 
    this.formGroupData = formBuilder.group({
      searchValue: [null],
      Property_Address_1: [null, Validators.compose([Validators.required,Validators.maxLength(127)])],
      Property_Address_2: [null, Validators.maxLength(127)],
      Property_City: [null, Validators.compose([Validators.required,Validators.maxLength(127)])],
      Property_State_Abbr: new FormControl({value: null, disabled: false}, Validators.required),
      Property_County: new FormControl({value: null, disabled: false}, Validators.required),
      Property_ZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(11)])],
    })
  }

  ngOnInit() {
    if(this.addressObject) this.getAdressFromObject()
		this.getCountyForIndependCity();
    this.getStatesList();
  }

  getAdressFromObject() {
    this.newOrderModel[this.borrowerLabelName] = this.addressObject[this.borrowerLabelName]
    this.newOrderModel.Property_Address_1 = this.addressObject.Property_Address_1;
    this.newOrderModel.Property_Address_2 = this.addressObject.Property_Address_2;
    this.newOrderModel.Property_City = this.addressObject.Property_City;
    this.newOrderModel.Property_County = this.addressObject.Property_County;
    this.newOrderModel.Property_State_Abbr =this.addressObject.Property_State_Abbr;
    this.newOrderModel.Country = this.addressObject.Country;
    this.newOrderModel.Property_ZipCode = this.addressObject.Property_ZipCode;
    this.newOrderModel.isError = this.addressObject.isError;
    this.addressObjectData.emit(this.newOrderModel);
  }

  getStatesList(){
    this.pricingService.getAvailableStateList()
    .subscribe((state) => {
      this.stateList = state;
    })
  }

  ngAfterViewInit(){
    let searchBox: any = document.getElementById(this.borrowerId);
    let options = {
      types: [
        // return only geocoding results, rather than business results.
        'geocode',
      ]
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    // Add listener to the place changed event
    autocomplete.addListener('place_changed', () => {
      this.getPlaceDetails(autocomplete.getPlace())
    });
  }

  getPlaceDetails(place){
    if (place.geometry == undefined) {
      //set an error - show the error under google search line
      // a custom validator need to be defined and an error message "Place not found" need to be shown

      //console.log("noBookmarkError:",this.noBookmarkError);
      //this.newOrderModel.Property_Address_1 = '';
      this.newOrderModel.Property_City = '';
      this.newOrderModel.Property_State_Abbr = '';
      this.newOrderModel.Property_ZipCode = '';
      // this.newOrderModel.Property_County = '';
      // document.getElementById('search-box').parentElement.classList.add('is-empty');
      // document.getElementById('Property_City').parentElement.classList.add('is-empty');
      // document.getElementById('Property_State_Abbr').parentElement.classList.add('is-empty');
      // document.getElementById('Property_ZipCode').parentElement.classList.add('is-empty');
      // this.searchError = true;
      return;
    } else {
      this.newOrderModel.searchValue = '';
      // this.latitude = place.geometry.location.lat();
      // this.longitude = place.geometry.location.lng();

      this.newOrderModel.Property_Address_1 = '';
      if(this.newOrderModel.Property_Address_2) this.newOrderModel.Property_Address_2 = '';
      this.newOrderModel.Property_City = '';
      this.newOrderModel.Property_State_Abbr = '';
      this.newOrderModel.Property_ZipCode = '';
      this.newOrderModel.Property_County = '';
      for (let i = place.address_components.length - 1; i >= 0; i--) {
        switch (place.address_components[i].types[0]) {
          case 'premise': this.newOrderModel.Property_Address_1 = place.address_components[i].long_name + ' ' +  this.newOrderModel.Property_Address_1;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'street_number': this.newOrderModel.Property_Address_1 = place.address_components[i].long_name + ' ' + this.newOrderModel.Property_Address_1;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'route': this.newOrderModel.Property_Address_1 =place.address_components[i].long_name;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality_level_1': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'locality': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'administrative_area_level_2': {

            if (this.checkSaint(place.address_components[i].long_name)) this.checkCountyNReplace(place, i)
            else this.checkCountyNReplace(place, i)
            this.newOrderModel.Property_County = place.address_components[i].long_name.toUpperCase();
            break;
          }
          case 'administrative_area_level_1':{
            this.formGroupData.controls['Property_State_Abbr'].setValue(place.address_components[i].short_name);
            this.newOrderModel.Property_State_Abbr = place.address_components[i].short_name;
            if (place.address_components[i].short_name == 'DC') this.newOrderModel.Property_County = "WASHINGTON"
            break;
          }
          case 'postal_code': this.newOrderModel.Property_ZipCode = place.address_components[i].short_name;
            break;
          case 'country': this.newOrderModel.Country = place.address_components[i].short_name;
            break;
          default: break;
        }
      }
      if (this.checkIfState(this.newOrderModel.Property_State_Abbr, this.newOrderModel.Country)) {
        if(!this.newOrderModel.Property_County){
          this.checkIfCityIsIndependent();
          this.showCityInCounty = true;
        } else this.showCityInCounty = false;
      }else {
        this.newOrderModel.Property_State_Abbr = '';
        this.newOrderModel.Property_County = '';
        this.newOrderModel.Country = '';
        // this.errors['State'] = true;
        // this.errors['County'] = true;
      }
      this.ref.detectChanges();
    }
    this.newOrderModel[this.borrowerLabelName] = this.borrowerLabelName;
    this.newOrderModel.isError = this.checkAllRequiredFields();
    this.addressObjectData.emit(this.newOrderModel);
  }

  checkIfState(stateName, country) {
    if (country == 'US' && this.stateList.findIndex((state) => state.State_Short_Name.toLowerCase() == stateName.toLowerCase()) > -1) return true;
    else return false;
  }

  clearValid(control){
    control.markAsUntouched();
  }

  checkSaint(county) {
    //console.log("saint check",county);
    var lowecaseCounties= CONSTANTS.saintArray.map((value)=>value.toLowerCase())
    if (county.toLowerCase().startsWith('st.') && lowecaseCounties.indexOf(county.toLowerCase()) == -1) return true;
    else return false;
  }

  checkCountyNReplace(place, i){
    place.address_components[i].long_name = place.address_components[i].long_name.replace(' County', '')
  } 

  getAddressLine2(event){
    if(this.newOrderModel.Property_Address_1){
      this.newOrderModel[this.borrowerLabelName] = this.borrowerLabelName;
      this.newOrderModel.Property_Address_2 = event.target.value;
      this.newOrderModel.isError = this.checkAllRequiredFields();
      this.addressObjectData.emit(this.newOrderModel);
    }
  }

  checkAllRequiredFields() {
    if(!this.newOrderModel.Property_Address_1 || !this.newOrderModel.Property_City || !this.newOrderModel.Property_County || !this.newOrderModel.Property_State_Abbr || !this.newOrderModel.Property_ZipCode) return true;
    else return false;
  }

  checkIfCityIsIndependent() {
    let findCityIndex = this.allIndepdentCities.findIndex((city) => {
      if(city) return this.newOrderModel.Property_City.toLowerCase() === city.System_Independent_City.toLowerCase()
      else return false;
    })
    if(findCityIndex > -1 ) {
      if(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name) this.formGroupData.controls.Property_County.setValue(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name);
      else this.newOrderModel.Property_County.setValue(this.newOrderModel.Property_City.value);
    }else this.newOrderModel.Property_County.setValue(this.newOrderModel.Property_City.value);
    }

  getCountyForIndependCity = (state?: string) => {
    this.pricingService.getIndpendentCities()
    .subscribe((cities) => {
      this.allIndepdentCities = cities;
    })
  };
}
