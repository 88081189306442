import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
	AuthService,
	AwsS3Service,
	ConfigService,
	FileService,
	MessagesService,
	NewOrderService,
	OrderListingService,
	ProgressBarService,
	RoutingStateService,
	StaticTextService,
} from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from '../../app.constants';
import { DialogService } from 'ng2-bootstrap-modal';
import { ErrorComponent } from '../../dialog/error/error.component';
import { MessageComponent } from '../../dialog/message/message.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { CancelDialogComponent } from '../../dialog/cancel-dialog/cancel-dialog.component';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { DatePipe, Location } from '@angular/common';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { UUID } from 'angular2-uuid';
import * as _ from 'underscore';
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['./order-details.component.scss'],
	providers: [DatePipe],
})
export class OrderDetailsComponent implements OnInit {
	@ViewChild('fileInput') fileInput: ElementRef;
	primaryColor = '#03a9f4';
	orderDetails: any = {};
	orderNotes: any = {};
	orderIns: any = {};
	messages: any = [];
	documents = [];
	constants = CONSTANTS;
	parentMsgId = null;
	orderStatus: any = {};
	clientDocs: any = [];
	adminDocs: any = [];
	newDocs: any = [];
	dwnlFlag: boolean = false;
	orderProducts: any = [];
	dwndMessage: string = '';
	orderId: number;
	orderBillingAddress: string = '';
	orderPropertyAddress: string = '';
	co_Borrower_orderPropertyAddress: string = '';
  orderTotPrice: any = 0;
	
	mainChargeRefundedAmount;
	timer: any = [];
  copyMessage = '';
  ordTrackerLink: string = '';
	constructor(
		public auth: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		public text: StaticTextService,
		public newOrder: NewOrderService,
		private progressServ: ProgressBarService,
		private orderList: OrderListingService,
		private dialogService: DialogService,
		private location: Location,
		private _datePipe: DatePipe,
		private fileService: FileService,
		private msgService: MessagesService,
		private config: ConfigService,
		private s3Service: AwsS3Service,
		private routingState: RoutingStateService,
	) {}

	private static previousUrlIncludesUnWantedUrl(previousUrl) {
		return (
			previousUrl &&
			(previousUrl.includes('login') ||
				previousUrl.includes('view') ||
				previousUrl.includes('orders/new/step2') ||
				previousUrl.includes('orders/new/step3') ||
				previousUrl.includes('orders/new/step4')) ||
				previousUrl.includes('convert')
		);
	}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this.getOrderProducts(this.route.snapshot.params['orderId']);
		this.progressServ.setPrimaryColor(this.primaryColor);
		this.progressServ.setHeight(2);
		this.progressServ.setStage(0);
		this.progressServ.setProgressState((0 / 4) * 100);
		this.progressServ.setBarType(2);
		this.progressServ.setTotalStages(5);
		if (this.auth.getUserOrgId())
			this.getOrganizationOrderDetails(this.route.snapshot.params['orderId']);
		else this.getOrderDetails(this.route.snapshot.params['orderId']);
		this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
		this.fetchUploadProgress();
	}

	getOrderProducts(orderId) {
		this.orderList.getCustomerOrderProducts(orderId).subscribe(
			products => {
				this.orderProducts = products;
        this.orderTotPrice = 0;
        products.forEach((prodLine: any) => this.orderTotPrice += prodLine.Order_Product_Price);
			},
			err => {},
		);
	}

	getOrderAdditionalDetails() {
		this.getOrderNotes(this.route.snapshot.params['orderId']);
		this.getOrderMessages(this.route.snapshot.params['orderId']);
		this.orderStatus = this.orderList.getOrderStatus();
		this.getOrderDocs();
		this.orderBillingAddress = this.getBillingAddress();
		this.orderPropertyAddress = this.buildAddress(this.orderDetails);
		this.co_Borrower_orderPropertyAddress = this.co_BorowerBuildAddress(this.orderDetails);
		this.constructOrdTrackerLink(this.orderDetails);		
	}

	constructOrdTrackerLink(ordDetails) {
		this.ordTrackerLink = this.config.getBasePath() + '/orders/'+ordDetails.Order_ID+'/status/'+ordDetails.Customer_ID ;
	  }

	goToNewOrder() {
		this.newOrder.goToNewOrder();
	}

	getOrderDetails(orderId) {
		this.orderList.getUserOrderDetails(orderId).subscribe(
			details => {
				this.orderDetails = details;
				if (details) {
					this.getOrderAdditionalDetails();
					this.orderDetails.Order_Mortgage_Date = this._datePipe.transform(
						this.orderDetails.Order_Mortgage_Date,
						'M/d/yyyy',
					);
					
					this.setProgressBarStatus(details['Order_Status']);
					this.allignFieldLabels();
					if (details.stripeCharge) {
						this.mainChargeRefundedAmount = this.getTotalAmountFromRefunds(
							details.stripeCharge.refunds,
						);
					}
				} else this.router.navigate(['/orders/current']);
			},
			err => {
				console.log(err);
				if (err.code == 'PTU10') {
					this.router.navigate(['/orders/current']);
				} else {
					this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
					this.auth.logout();
					this.router.navigate(['/login']);
				}
			},
		);
	}

	getTotalAmountFromRefunds(refunds) {
		let total = 0;
		for (const refund of refunds.data) {
			total += refund.amount;
		}
		return total / 100;
	}

	getOrganizationOrderDetails(orderId) {
		this.orderList.getOrganizationOrderDetails(orderId).subscribe(
			details => {
				this.orderDetails = details;
				if (details) {
					this.getOrderAdditionalDetails();
					this.orderDetails.Order_Mortgage_Date = this._datePipe.transform(
						this.orderDetails.Order_Mortgage_Date,
						'M/d/yyyy',
					);
					this.setProgressBarStatus(details['Order_Status']);
					this.allignFieldLabels();
					if (details.stripeCharge) {
						this.mainChargeRefundedAmount = this.getTotalAmountFromRefunds(
							details.stripeCharge.refunds,
						);
					}
				} else this.router.navigate(['/orders/current']);
			},
			err => {
				if (err.code == 'PTU10') {
					this.router.navigate(['/orders/current']);
				} else {
					this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
					this.auth.logout();
					this.router.navigate(['/login']);
				}
			},
		);
	}

	allignFieldLabels() {
		document.getElementById('File_ID').parentElement.classList.remove('is-empty');
		document.getElementById('Order_ID').parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Time'))
			document.getElementById('Order_Time').parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Buyer'))
			document.getElementById('Order_Buyer').parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Co_Buyer'))
			document
				.getElementById('Order_Co_Buyer')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Seller'))
			document.getElementById('Order_Seller').parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Co_Seller'))
			document
				.getElementById('Order_Co_Seller')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Parcel'))
			document.getElementById('Order_Parcel').parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Loan'))
			document.getElementById('Order_Loan').parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Mortgage_Amount'))
			document
				.getElementById('Order_Mortgage_Amount')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Mortgage_Date'))
			document
				.getElementById('Order_Mortgage_Date')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Book'))
			document.getElementById('Order_Book').parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Page'))
			document.getElementById('Order_Page').parentElement.classList.remove('is-empty');
		if (document.getElementById('Product_Description'))
			document
				.getElementById('Product_Description')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Custom_Price_Tax'))
			document
				.getElementById('Order_Custom_Price_Tax')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Property_Address_1'))
			document
				.getElementById('Property_Address_1')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Instruction_Text'))
			document
				.getElementById('Instruction_Text')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Status_Bar'))
			document
				.getElementById('Order_Status_Bar')
				.parentElement.classList.remove('is-empty');
		if (document.getElementById('Order_Status'))
			document.getElementById('Order_Status').parentElement.classList.remove('is-empty');
		if (document.getElementById('Property_Order_Ins'))
			document
				.getElementById('Property_Order_Ins')
				.parentElement.classList.remove('is-empty');
	}

	uploadDocument(event) {
		if (this.validateFilenames(event)) {
			var fileUploads = [];
			if (this.checksize(event.target.files)) {
				var totDocs = event.target.files.length;
				for (var i = 0; i < event.target.files.length; i++) {
					var docId = UUID.UUID();
					this.newDocs.push({
						docId: docId,
						name: event.target.files[i].name,
						completed: 0,
					});
					fileUploads.push(this.uploadToS3AndUpdatePath(docId, event.target.files[i]));
				}
				Promise.all(fileUploads)
					.then(files => {
						this.removeCompletedDocs(files);
						var succesFiles = files.filter(file => file !== undefined);
						if (succesFiles.length > 0) {
							this.getOrderDocs();
							if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.completed)
								this.setCompOrderMessages(this.orderDetails.Order_ID);
								// this.getOrderMessages(this.orderDetails.Order_ID);
						}
					})
					.catch(err => {
						this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
					});
			} else {
				this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
			}
		} else {
			this.openErrorPopup(CONSTANTS.apiErrors.validateFile);
		}
	}

	removeCompletedDocs(files) {
		for (var i = 0, len = files.length; i < len; i++) {
			for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
				if (files[i] && files[i].docId === this.newDocs[j].docId) {
					this.newDocs.splice(j, 1);
					len2 = this.newDocs.length;
				}
			}
		}
	}

	checksize(files) {
		var largeFiles = Array.prototype.filter.call(
			files,
			file => file.size > this.config.getMaxFileSize() * 1024 * 1024,
		);
		if (largeFiles.length > 0) return false;
		else return true;
	}

	openErrorPopup(msg) {
		let disposable = this.dialogService
			.addDialog(ErrorDialogComponent, {
				message: msg,
			})
			.subscribe(res => {});
	}

	validateFilenames(event) {
		var flag = 0;
		for (var i = 0; i < event.target.files.length; i++) {
			var filename = event.target.files[i].name;
			if (filename.indexOf(',') > -1) flag++;
		}
		if (flag == 0) return true;
		else return false;
	}

	getOrderNotes(orderId) {
		this.orderList.getOrderNotes(orderId).subscribe(
			notes => {
				notes.forEach(note => {
					if (note.Instruction_Type == CONSTANTS.instructionType.orderNotes)
						this.orderNotes = note;
					if (note.Instruction_Type == CONSTANTS.instructionType.orderIns)
						this.orderIns = note;
				});
			},
			err => {
				console.log(err);
			},
		);
	}

	setCompOrderMessages(orderId) {
		this.orderList.postDocUploadMsg(orderId).subscribe(() =>
			this.getOrderMessages(orderId),
      err => {
        console.log(err);
      },
		);
	}

	getOrderMessages(orderId) {
		this.orderList.getOrderMessages(orderId).subscribe(
			messages => {
				this.messages = messages;
				this.messages.forEach(message => {
					this.isThreadedMessage(message);
				});
			},
			err => {
				console.log(err);
			},
		);
	}

	isThreadedMessage(msg) {
		if (msg.Parent_Message_ID && this.parentMsgId == msg.Parent_Message_ID) {
			msg.replyFlag = false;
		} else {
			this.parentMsgId = msg.Parent_Message_ID;
			msg.replyFlag = true;
		}
	}

	setProgressBarStatus(status) {
		var stage = this.getStage(status);
		this.progressServ.setStage(stage);
		this.progressServ.setProgressState(((stage - 1) / 3) * 100);
	}

	getStage(status) {
		if (
			status == CONSTANTS.orderStatus.received ||
			status == CONSTANTS.orderStatus.assigned
		)
			return 1;
		else if (status == CONSTANTS.orderStatus.confirmed) return 2;
		else if (status == CONSTANTS.orderStatus.processing) return 3;
		else if (status == CONSTANTS.orderStatus.completed) return 4;
	}

	cancelOrder() {
		this.orderList.cancelOrder(this.route.snapshot.params['orderId']).subscribe(
			data => {
				this.openSuccessDialog(this.text.get('BTN12'), this.text.get('TXT28'));
				this.router.navigate(['/orders/cancelled']);
			},
			err => {
				console.log(err);
			},
		);
	}

	checkForCancel() {
		this.openCancelDialog().subscribe(res => {
			if (res) this.openMessageInterface(null, 'cancel');
		});
	}

	buildAddress(model) {
		let address = '';
		if (model.Property_Address_1) address += model.Property_Address_1;
		if (model.Property_Address_2) {
			if (address) address += '\n';
			address += model.Property_Address_2;
		}
		if (model.Property_City) {
			if (address) address += '\n';
			address += model.Property_City;
		}
		if (model.Property_State_Abbr) {
			if (address) address += ', ';
			address += model.Property_State_Abbr;
		}
		if (model.Property_ZipCode) {
			if (address) address += ' ';
			address += model.Property_ZipCode;
		}
		return address;
	}

	 co_BorowerBuildAddress(model){
		let address = '';
		if (model.Co_Borrower_Property_Address_1) address += model.Co_Borrower_Property_Address_1;
		if (model.Co_Borrower_Property_Address_2) {
			if (address) address += '\n';
			address += model.Co_Borrower_Property_Address_2;
		}
		if (model.Co_Borrower_Property_City) {
			if (address) address += '\n';
			address += model.Co_Borrower_Property_City;
		}
		if (model.Co_Borrower_Property_State_Abbr) {
			if (address) address += ', ';
			address += model.Co_Borrower_Property_State_Abbr;
		}
		if (model.Co_Borrower_Property_ZipCode) {
			if (address) address += ' ';
			address += model.Co_Borrower_Property_ZipCode;
		}
		return address;
	}
	/**
	 * Builds and returns billing address details from Stripe Charge object.
	 * If the order has not a Charge created we return null.
	 *
	 * @return address          Formed address as a string
	 * @return null            Null if there is no stripeCharge associated with the order
	 */
	getBillingAddress(): string {
		/** DO NOT USE THIS METHOD DIRECTLY IN TEMPLATE
		 * ANGULAR'S CHANGE DETECTION WILL ENTER TO THIS METHOD HUNDREDS OF TIMES. */
		let address = '';
		if (this.orderDetails) {
			if (
				this.orderDetails.stripeCharge &&
				this.orderDetails.stripeCharge.billing_details
			) {
				const ba = this.orderDetails.stripeCharge.billing_details.address;
				address += ba.line1 + '\n';
				address += ba.line2 ? ba.line2 + '\n' : '';
				address += ba.city + ', ';
				address += ba.state + ' ';
				address += ba.postal_code;
			} else address = null;
		} else address = null;
		return address;
	}

	openErrorDialog() {
		let disposable = this.dialogService
			.addDialog(ErrorComponent, {
				title: this.text.get('BTN12'),
				message: this.text.get('TXT31'),
			})
			.subscribe(res => {});
	}

	sendMessage(text) {
		var data = {
			Message_Text: text,
		};
		this.orderList.sendMessage(this.route.snapshot.params['orderId'], data).subscribe(
			data => {
				this.getOrderMessages(this.route.snapshot.params['orderId']);
			},
			err => {
				console.log(err);
				this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
			},
		);
	}

	openMessageInterface(msg, type) {
		let disposable = this.dialogService
			.addDialog(MessageComponent, {
				title: this.text.get('BTN13'),
				message: msg,
			})
			.subscribe(res => {
				if (res) {
					if (type == 'cancel') {
						var data = {};
						data['Message_Text'] = res;
						data['Order_ID'] = this.orderDetails.Order_ID;
						data['Sender_ID'] = this.auth.getUserId();
						this.orderList.cancelOrderMessage(data).subscribe(
							data => {
								this.getOrderMessages(this.route.snapshot.params['orderId']);
								this.openSuccessDialog(
									'Success',
									'We have received your request. We will get back to you very shortly.',
								);
							},
							err => {
								console.log(err);
								this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
							},
						);
					} else this.sendMessage(res);
				}
			});
	}

	openConfirmDialog() {
		return this.dialogService.addDialog(ConfirmComponent, {
			title: this.text.get('BTN12'),
			message:
				this.text.get('LBL49') +
				': ' +
				this.orderDetails.Order_ID +
				'(' +
				this.text.get('LBL53') +
				': ' +
				this.orderDetails.File_ID +
				')',
		});
	}

	openCancelDialog() {
		return this.dialogService.addDialog(CancelDialogComponent, {
			title: this.text.get('BTN12') + ' ' + this.orderDetails.Order_ID,
			message: this.text.get('TXT31'),
			buttonValue : this.text.get('BTN13')
		});
	}

	openSuccessDialog(title, msg) {
		let disposable = this.dialogService
			.addDialog(SuccessComponent, {
				title: title,
				message: msg,
			})
			.subscribe(res => {
				if (res) this.getOrderMessages(this.route.snapshot.params['orderId']);
			});
	}

	downloadFile(filename, orderDocId) {
		this.orderList.download(orderDocId).subscribe(
			userFile => {
				if (userFile) {
					var link = document.createElement('a');
					document.body.appendChild(link);
					link.download = filename;
					link.href = userFile.Link;
					link.click();
					document.body.removeChild(link);
				}
			},
			err => {
				console.log(err);
				this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
			},
		);
	}

	getByteArray(file) {
		var sliceSize = 512;

		var byteCharacters = atob(file);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);

			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}
		return byteArrays;
	}

	getOrderDocs() {
		this.orderList.getOrderDocuents(this.route.snapshot.params['orderId']).subscribe(
			data => {
				if (data) {
					this.documents = data;
					this.clientDocs = this.documents.filter(x => x.Document_Type == '2');
					this.adminDocs = this.documents.filter(x => x.Document_Type == '1');
				}
			},
			err => {
				console.log(err);
			},
		);
	}

	goBack() {
		let arrayHistory = this.routingState.getHistory();
		const previousUrl = this.routingState.getPrevUrl();
		let startingIndex = _.findIndex(arrayHistory, x =>
			x.includes('orders/' + this.orderDetails.Order_ID + '/view'),
		);
		let lastIndex = _.findLastIndex(arrayHistory, x =>
			x.includes('orders/' + this.orderDetails.Order_ID + '/view'),
		);
		arrayHistory.splice(startingIndex, lastIndex + 1);
		let lastLoc;
		if (arrayHistory.length) lastLoc = arrayHistory[arrayHistory.length - 1];
		else this.router.navigate(['/orders/current']);
		if (OrderDetailsComponent.previousUrlIncludesUnWantedUrl(previousUrl)) {
			this.router.navigate(['/orders/current']).then();
		} else if (lastLoc) {
			if (lastLoc.indexOf('?') > 0) {
				let lastLocation = lastLoc.slice(0, lastLoc.indexOf('?'));
				return this.router.navigate([lastLocation]);
			} else this.router.navigate([lastLoc]);
		}
	}

	openConfirmStatusDialog() {
		return this.dialogService.addDialog(SuccessComponent, {
			title: this.text.get('BTN16'),
			message: this.text.get('TXT50'),
		});
	}

	clientConfOrder() {
		this.orderList.clientConfOrder(this.route.snapshot.params['orderId']).subscribe(
			data => {
				this.openConfirmStatusDialog();
				this.getOrderDetails(this.route.snapshot.params['orderId']);
			},
			err => {
				console.log(err);
			},
		);
	}

	deleteFile(filename, orderDocId) {
		this.dialogService
			.addDialog(ConfirmComponent, {
				title: 'Delete Document',
				message: 'Are you sure want to delete ' + filename + ' ?',
			})
			.subscribe(res => {
				if (res) {
					this.fileService.delete(orderDocId, this.auth.getUserId()).subscribe(
						data => {
							this.getOrderDocs();
						},
						err => {
							console.log(err);
							this.openErrorPopup(CONSTANTS.apiErrors.docdelete);
						},
					);
				}
			});
	}

	getEstimationTime() {
		if (this.orderDetails && this.orderDetails.Order_ETA_Range) {
			this.orderDetails.Order_ETA = this._datePipe.transform(
				this.orderDetails.Order_ETA,
				'M/d/yyyy',
			);
			let getDate = new Date(this.orderDetails.Order_ETA).toLocaleDateString('en-US', {
				timeZone: 'America/New_York',
			});
			return this.orderDetails.Order_ETA_Range + ' days by ' + getDate;
		} else return ' ';
	}

	getPlaceHolderLabel() {
		if (
			this.orderDetails &&
			(this.orderDetails.Order_ETA_Range || this.orderDetails.Order_ETA)
		)
			return 'Due Date';
		else if (this.orderDetails.Order_Estimated_Time && !this.orderDetails.Order_ETA)
			return 'Estimation Time';
	}

	openFileSelector() {
		this.fileInput.nativeElement.value = null;
		this.fileInput.nativeElement.click();
	}

	replyToThreadMsg(msg) {
		if (msg.Parent_Message_ID) {
			this.msgService.getAllMsgs(msg.Parent_Message_ID).subscribe(
				allMsgs => {
					msg.allMsg = allMsgs;
					this.openThreadMessageInterface(msg);
				},
				err => {
					console.log(err);
				},
			);
		} else {
			this.msgService.getMsgData(msg.Message_ID).subscribe(
				data => {
					this.openThreadMessageInterface(data);
				},
				err => {
					console.log(err);
				},
			);
		}
	}

	openThreadMessageInterface(msg) {
		let disposable = this.dialogService
			.addDialog(MessageComponent, {
				title: this.text.get('BTN13'),
				message: msg,
			})
			.subscribe(res => {
				if (res) {
					if (msg.Parent_Message_ID)
						this.sendMessageWithOrderId({
							Message_Text: res,
							Parent_Message_ID: msg.Parent_Message_ID,
						});
					else {
						var data = {
							msgDataToSent: {
								Order_ID: this.orderDetails.Order_ID,
								SenderId: this.auth.getUserId(),
								Message_Text: res,
							},
							Message_ID: msg.Message_ID,
						};
						this.updateParentId(data);
					}
				}
			});
	}

	sendMessageWithOrderId(data) {
		this.orderList.sendMessage(this.orderDetails.Order_ID, data).subscribe(
			data => {
				this.getOrderMessages(this.orderDetails.Order_ID);
			},
			err => {
				console.log(err);
			},
		);
	}

	updateParentId(msgData) {
		this.msgService.updateParentId(msgData).subscribe(
			data => {
				this.getOrderMessages(this.orderDetails.Order_ID);
			},
			err => {
				console.log(err);
			},
		);
	}

	downloadAllFiles(orderId) {
		this.dwnlFlag = true;
		this.afterDwnd();
		this.orderList.downloadAll(orderId).subscribe(
			docs => {
				docs.forEach(doc => {
					var link = document.createElement('a');
					link.target = '_blank';
					link.href = doc.Link;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				});
				this.dwnlFlag = false;
			},
			err => {
				this.dwnlFlag = false;
				if (err.code == 'PTU25') {
					this.openErrorPopup(CONSTANTS.apiErrors.docunavailable);
				} else this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
			},
		);
	}

	isPricingDone() {
		if (
			this.orderProducts.length &&
			this.orderDetails.Order_Status > CONSTANTS.orderStatus.received
		)
			return true;
		return null;
	}

	afterDwnd() {
		this.dwndMessage = 'Please wait. Your downloads will start';
		this.timer.push(setTimeout(() => (this.dwndMessage = ''), 3000));
	}

	ngOnDestroy() {
		if (this.timer) {
			this.timer.forEach(time => clearTimeout(time));
		}
	}

	fetchUploadProgress() {
		this.s3Service.progress.subscribe(res => {
			var matchedIndex = this.newDocs.findIndex(doc => doc.name == res.name);
			if (this.newDocs[matchedIndex]) {
				this.newDocs[matchedIndex].completed =
					Math.round((res.progress.loaded / res.progress.total) * 100 * 100) / 100;
				this.newDocs[matchedIndex].completed = Math.round(
					this.newDocs[matchedIndex].completed,
				);
			}
		});
	}

	extractFilename(filePath) {
		var parts = filePath.split('/');
		var filename = parts[parts.length - 1];
		return filename;
	}

	uploadToS3AndUpdatePath(docId, file) {
		return this.s3Service
			.onManagedUpload(file, this.route.snapshot.params['orderId'])
			.then(data => {
				return this.s3Service
					.updateDocumentPath(
						this.route.snapshot.params['orderId'],
						this.orderDetails.Property_ID,
						file,
						data.Key,
						this.extractFilename(data.Key),
					)
					.then(() => {
						return { docId: docId, name: file.name, completed: 100 };
					})
					.catch(err => {
						throw err;
					});
			})
			.catch(err => {
				this.openErrorPopup(CONSTANTS.apiErrors.multiupload);
			});
	}

	createOrderFly(ordId) {
		this.router.navigate(['/update/' + ordId + '/view']);
	}

	checkDaylight(etaDate) {
		if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
		else return 'EST';
	}

	checkDateOrNot(){
		if(typeof this.orderDetails.Order_Estimated_Time == 'string' && this.orderDetails.Order_Estimated_Time.includes('business days')) return true;
		else return false;
	}

  checkIfDataAvailable(data) {
    if (data) return data;
    else return '';
  }

  dataForResware() {
    console.log("dataForResware=>")
    this.orderList.createXML(this.orderDetails.Order_ID)
      .subscribe((blobData) => {
        if (blobData) this.composeAndDownload(blobData, this.orderDetails.Order_ID);
      }, (err) => {
        this.openErrorPopup('Error while fetching data.');
      });
  }
  
  composeAndDownload(blobData, orderId) {
     let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", "Report_" + orderId + ".xml");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }  

  checkIfReswareClient() {
    // also make a check to identify whether the client require this button
    return this.orderDetails.Order_Status ==  this.constants.orderStatus.completed
  }
}
