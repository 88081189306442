
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders} from '@angular/common/http'
@Injectable()
export class InvoiceService {

  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  getInvoiceOrders(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/invoice/getinvoice';      
    return this.http.post(url, data ,httpOptions).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderProducts(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/invoice/order/'+ orderId +'/orderProducts';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getInvoiceSpan() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/invoice/'+ this.auth.getUserId() +'/period/span';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  generatePDF(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType:'text' as 'json'
    }
    let url = this.config.getBasePath() + '/invoice/client/generatePDF';
    return this.http.post(url,data,httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),);
  }

}
