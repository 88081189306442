import { isEmptyArray, isFn, __DEV__ } from './assertion';
import { FunctionArguments, AnyFunction } from './types';
import memoize from 'memoize-one';

/* ---------------------------------- */

export { memoize };

/* ---------------------------------- */

export const runIfFn = (valOrFn: any, ...args: any) =>
	isFn(valOrFn) ? (isEmptyArray(args) ? valOrFn() : valOrFn(...args)) : valOrFn;

/* ---------------------------------- */

export const compose = (...fns: any) => (arg: any) =>
	fns.forEach((fn: any) => {
		fn === null || fn === void 0 ? void 0 : fn(arg);
	});

/* ---------------------------------- */

export const callAllHandlers = (...fns: any) => (event: any) =>
	fns.some(fn => {
		fn === null || fn === void 0 ? void 0 : fn(event);
		return event === null || event === void 0 ? void 0 : event.defaultPrevented;
	});

/* ---------------------------------- */

export const once = (fn?: Function | null) => {
	let result: any;

	return function (this: any, ...args: any[]) {
		if (fn) {
			result = fn.apply(this, args);
			fn = null;
		}
		return result;
	};
};

/* ---------------------------------- */

type MessageOptions = {
	condition: boolean;
	message: string;
};

export const createWarning = once((options: MessageOptions) => {
	const { condition, message } = options;
	if (condition && __DEV__) console.warn(message);
});

export const createError = once((options: MessageOptions) => {
	const { condition, message } = options;
	if (condition && __DEV__) console.error(message);
});

/* ---------------------------------- */

export const asAsync = (req: any) =>
	new Promise((resolve, reject) =>
		req.subscribe(
			(res: any) => resolve(res),
			(err: any) => reject(err),
		),
	);

/* ---------------------------------- */

export const makeAsync = (request: any, transform?: AnyFunction) => (...args: any) =>
	new Promise((resolve, reject) =>
		request(...args).subscribe(
			(res: any) => resolve(isFn(transform) ? transform(res) : res),
			(err: any) => reject(err),
		),
	);

/* ---------------------------------- */
