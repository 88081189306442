import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { types } from '@services/new-order';
import { get, toNumber, unformatMoney, Dict, convertToNumber } from '@utils';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { ConfigService } from '../config/config.service';
import * as stripe from '../stripe';

@Injectable()
export class NewOrderService {
	protected _docs: any[] = [];
	protected _billingData: types.Billing;
	protected _pricingData: types.Pricing;
	protected _propertyData: types.Property;
	protected _quoteData: any;

	// the order type
	protected _searchType: number = 1;
	// 1 → full address, 2 → borrower details, 3 → parcel details
	protected _searchCriteria: number = 1;
	// 1 → auto filled, 2 → manual entry
	protected _searchMode: number = 1;
	protected _searchIsAutofilled: boolean = true;

	protected _stripeCard: Dict = null;
	protected _stripeCardId: string = null;
	protected _stripeCustomerId: string = null;
	protected _stripeIncidentalToken: string;
	protected _stripeOrderToken: string;

	constructor(
		protected auth: AuthService,
		protected config: ConfigService,
		protected http: HttpClient,
		protected router: Router,
	) {}

	/* ---------------------------------- */

	protected get propertySerialized() {
		return {
			...this.propertyData,
			Order_Loan: convertToNumber(this.propertyData.Order_Loan),
			Order_Mortgage_Amount: convertToNumber(this.propertyData.Order_Mortgage_Amount),
			Order_Source_Type_ID: 6
		};
	}

	/* ---------------------------------- */

	public get docs() {
		return get(this, '_docs', []);
	}

	public get billingData() {
		return this._billingData;
	}

	public get pricingData() {
		return {
			Order_Final_Price: 0,
			Order_Final_Price_Tax: 0,
			...this._pricingData,
		};
	}

	public get propertyData() {
		return this._propertyData;
	}

	public get quoteData() {
		return this._quoteData;
	}

	public get searchMode() {
		return this._searchMode;
	}

	public get searchType() {
		return this._searchType;
	}

	public get searchCriteria() {
		return this._searchCriteria;
	}

	public get searchIsAutofilled() {
		return this._searchIsAutofilled;
	}

	public get stripeCard() {
		return this._stripeCard;
	}

	public get stripeCardId() {
		return this._stripeCardId;
	}

	public get stripeCustomerId() {
		return this._stripeCustomerId;
	}

	public get stripeOrderToken() {
		return this._stripeOrderToken;
	}

	public get stripeIncidentalToken() {
		return this._stripeIncidentalToken;
	}

	/* ---------------------------------- */

	public set docs(docs: any[]) {
		this._docs = docs;
	}

	public set billingData(data: types.Billing) {
		this._billingData = data;
	}

	public set pricingData(data: types.Pricing) {
		this._pricingData = data;
	}

	public set propertyData(data: types.Property) {
		this._propertyData = data;
	}

	public set quoteData(data: any) {
		this._quoteData = data;
	}

	public set searchMode(mode: number) {
		this._searchMode = toNumber(mode);
	}

	public set searchType(type: number) {
		this._searchType = type;
	}

	public set searchCriteria(criteria: number | string) {
		this._searchCriteria = toNumber(criteria);
	}

	public set searchIsAutofilled(data: boolean) {
		this._searchIsAutofilled = data;
	}

	public set stripeCard(card: Dict) {
		this._stripeCard = card;
	}

	public set stripeCardId(id: string) {
		this._stripeCardId = id;
	}

	public set stripeCustomerId(id: string) {
		this._stripeCustomerId = id;
	}

	public set stripeOrderToken(token: string) {
		this._stripeOrderToken = token;
	}

	public set stripeIncidentalToken(token: string) {
		this._stripeIncidentalToken = token;
	}

	/* ---------------------------------- */

	protected get baseURL() {
		return this.config.getBasePath();
	}

	protected get orgId() {
		return this.auth.orgId;
	}

	protected get userId() {
		return this.orgId || this.auth.userId;
	}

	/* ---------------------------------- */

	public reqProductPricing = (
		state: string,
		county: string,
		productId: number | string,
	) =>
		this.http
			.get(
				`${this.baseURL}/orders/product/${productId}/county/${county}/stateabbr/${state}/user/${this.userId}`,
				{
					headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
				},
			)
			.pipe(
				map((response: any) => response),
				catchError((error: { error: string }) => observableThrowError(error.error)),
			);

	public reqStripeCustomer = () =>
		this.http.get<stripe.types.StripeCustomer>(
			`${this.baseURL}/users/client/stripe/customer`,
			{
				headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
			},
		);

	public reqUserPrepaymentStatus = () =>
		this.http
			.get(`${this.baseURL}/users/prepayment/status/${this.auth.userId}/${this.orgId}`, {
				headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
			})
			.pipe(
				map((response: any) => response),
				catchError((error: { error: string }) => observableThrowError(error.error)),
			);

	/* ---------------------------------- */

	public createOrder = () =>
		this.http
			.post(
				`${this.baseURL}/orders/user/${this.auth.userId}/neworder`,
				{
					billingAddress: this.billingData,
					pricing: this.pricingData,
					property: this.propertySerialized,
					secondStripeToken: get(this, 'stripeIncidentalToken'),
					selectedOrderCriteria: this.searchCriteria,
					stripeCardID: this.stripeCardId,
					stripeCustomerID: this.stripeCustomerId,
					stripeToken: get(this, 'stripeOrderToken'),
				},
				{
					headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
				},
			)
			.pipe(
				map((response: any) => {
					console.log('create new order map');
					this.reset('stripeCustomerId', 'stripeOrderToken', 'stripeIncidentalToken');
					return response;
				}),
				catchError((error: { error: string }) => {
					console.log('create order error: ', error);
					return observableThrowError(error.error);
				}),
			);

	public updateOrder = (orderDoc: any[], ordDetails: types.Property) =>
		this.http
			.post(
				`${this.baseURL}/orders/user/${this.auth.userId}/updateorder`,
				{
					billingAddress: this.billingData,
					orderDocs: orderDoc,
					property: ordDetails,
					secondStripeToken: this.stripeIncidentalToken,
					stripeCardID: this.stripeCardId,
					stripeCustomerID: this.stripeCustomerId,
					stripeToken: this.stripeOrderToken,
				},
				{
					headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
				},
			)
			.pipe(
				map((response: any) => {
					this.reset('stripeCustomerId', 'stripeOrderToken', 'stripeIncidentalToken');
					return response;
				}),
				catchError((error: { error: string }) => observableThrowError(error.error)),
			);

	public updateOrderDocument = (orderId: string, propertyId: string, docId: string) =>
		this.http
			.put(
				`${this.baseURL}/orders/orderdoc/${docId}/user/${this.userId}`,
				{
					Order_ID: orderId,
					Property_ID: propertyId,
				},
				{
					headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
					observe: 'response' as 'body',
				},
			)
			.pipe(
				map((response: any) => response),
				catchError((error: { error: string }) => observableThrowError(error.error)),
			);

	public createOrderMessage = (data: { Message_Text: string; Sender_ID: string }) =>
		this.http
			.post(`${this.baseURL}/messages/customorder`, data, {
				headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
				observe: 'response' as 'body',
			})
			.pipe(
				map((response: any) => response),
				catchError((error: { error: string }) => observableThrowError(error.error)),
			);

	/* ---------------------------------- */

	public createQuote = () =>
		this.http
			.post(
				`${this.config.baseUrl}/quotes/user/${this.auth.userId}/create/quote`,
				{
					property: this.quoteData,
				},
				{
					headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
				},
			)
			.pipe(
				map((res: any) => res),
				catchError((error: any) => observableThrowError(error.error)),
			);

	public convertToOrderFromQuotes = (orderDetails, addFee) => {
		let quoteBody = {
			orderDetails:orderDetails,
			billingAddress:this.billingData,
			pricing:this.pricingData,
			property:this.propertySerialized,
			secondStripeToken:get(this, 'stripeIncidentalToken'),
			stripeCardID:this.stripeCardId,
			stripeCustomerID: this.stripeCustomerId,
			stripeToken: get(this, 'stripeOrderToken')
		}
		if(addFee)quoteBody.orderDetails['Qualia_Fee_Add'] = addFee ? 1: 0
		let httpOptions = {
			headers: new HttpHeaders({ Authorization: 'Bearer ' + this.auth.getToken() }),
		};
		let url =
			this.config.getBasePath() + '/orders/user/' + this.auth.getUserId() + '/quotes';
		return this.http.post(url, quoteBody, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError((error: any) => observableThrowError(error.error)),
		);
	};

	/* ---------------------------------- */

	protected reset = (...resets: any[]) => {
		(<Array<types.DataResets>>resets).forEach(reset => {
			switch (reset) {
				case 'searchMode':
				case 'searchType':
				case 'searchCriteria':
					this[`_${reset}`] = 1;
					break;

				case 'docs':
					this.docs = [];
					break;

				default:
					this[`_${reset}`] = null;
					break;
			}
		});
	};

	public resetQuoteData = () => this.reset('quoteData', 'searchType', 'searchCriteria');

	public resetAll = () =>
		this.reset(
			'billingData',
			'searchCriteria',
			'searchMode',
			'docs',
			'pricingData',
			'propertyData',
			'stripeCardId',
			'stripeCustomerId',
			'stripeIncidentToken',
			'stripeOrderToken',
			'stripeCard',
		);

	goToNewOrder = () =>
		this.router.navigate([`/user/${this.auth.userId}/orders/new/step1`]);

	goToRequestQuote = () =>
		this.router.navigate(['/quote/' + this.auth.getUserId() + '/step1']);
}
