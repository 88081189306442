
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../authentication/auth.service';
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'
@Injectable()
export class FileService {

  constructor(    
    private auth:AuthService,
    private config: ConfigService,
    private http:HttpClient
  ) { }

  upload(file,OrderId,PropertyId) {
    let type = 2;
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('User_ID',this.auth.getUserId())
    formData.append('Property_ID',PropertyId)
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    return this.http.put(this.config.getBasePath() + '/orders/order/' + OrderId + '/client/' + type , formData, httpOptions).pipe(
    map((response: any) => {
      return response;
    }),
    catchError((error) => observableThrowError(error.error)),)
  }

  download(orderDocId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe:'response' as 'body'    
    }
    return this.http.get(this.config.getBasePath() + '/orders/download/document/' + orderDocId , httpOptions).pipe(
    map((response :any) => {
      return response;
    }),
    catchError(error => observableThrowError(error.error)),)
  }
  
  delete(orderDocId,userId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe:'response' as 'body'    
    }
    return this.http.delete(this.config.getBasePath() + '/orders/cdeldocument/user/' + userId + '/orderdocument/' + orderDocId ,httpOptions).pipe(
    map((response: any) => {
      return response;
    }),
    catchError(error => observableThrowError(error.error)),)
  }

}
