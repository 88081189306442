import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';

@Injectable()
export class IntercomService {


  constructor(
    private config:ConfigService
  ) { }

  getIntercomData(data){
    var createdScriptElem = this.loadIntercom('../../assets/js/intercom.js');
    this.setDataAttr(createdScriptElem,data);
    var script_tag = document.getElementById('intercomScript');
    (<any>window).Intercom('boot', {
      app_id: this.config.getIntercomAppId(),
      name: script_tag.getAttribute('data-user-name'), // Full name
      email: script_tag.getAttribute('data-user-email'), // Email address
      created_at: script_tag.getAttribute('data-user-creation-date'), // Signup date as a Unix timestamp
      user_id: script_tag.getAttribute('data-user-id'),
      Organization_Name: script_tag.getAttribute('data-user-Organization-Name'),
    });
    return
  }

  loadIntercom(src) {
    let node = document.createElement('script');
    node.type = 'text/javascript';
    node.src = src;
    node.id = "intercomScript";
    document.getElementsByTagName('body')[0].appendChild(node);
    return node;
  }

  setDataAttr(ele: Element, userData) {
    ele.setAttribute('data-user-id', userData.User_ID);
    ele.setAttribute('data-user-email', userData.Email_Address);
    ele.setAttribute('data-user-name', userData.User_First_Name +' '+ userData.User_Last_Name);
    // ele.setAttribute('data-user-lastname', this.userData.User_Last_Name);
    ele.setAttribute('data-user-creation-date', userData.User_Creation_Date);
    ele.setAttribute('data-user-Organization-Name', userData.Organization_Name);
  }

}
