
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class SocketService {

  constructor(private socket: Socket) { }
  
//   emitMessageChange(msg: string){
//       this.socket.emit(msg, null);
//   }

  syncMessage(msg: string) {
      return this.socket
          .fromEvent<any>(msg).pipe(
          map(data => data.msg ));
  }

  unsubscribeMessage(msg: string){
      this.socket.removeAllListeners(msg);
  }
  
  close() {
    //this.socket.disconnect();
  }

}
