import { Component, OnInit } from '@angular/core';
import {
	ProgressBarService,
	NewOrderService,
	AuthService,
	PricingService,
	StaticTextService,
} from 'app/services';
import { Router } from '@angular/router';
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { DialogService } from 'ng2-bootstrap-modal';
import { SuccessComponent } from '../../dialog/success/success.component';

@Component({
	selector: 'app-request-quote3',
	templateUrl: './request-quote3.component.html',
	styleUrls: ['./request-quote3.component.scss'],
})
export class RequestQuote3Component implements OnInit {
	primaryColor = '#0F9D58';
	quoteDetails: any = {};
	completeAddress: string = '';
	selectedState = '';
	selectedCounty = '';
	states: any;
	counties: any;
	error: any;
	constructor(
		private progressServ: ProgressBarService,
		private newOrder: NewOrderService,
		private router: Router,
		private auth: AuthService,
		private pricingService: PricingService,
		private text: StaticTextService,
		private dialogService: DialogService,
	) {}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this.progressServ.setPrimaryColor(this.primaryColor);
		this.progressServ.setHeight(2);
		this.progressServ.setStage(2);
		this.progressServ.setTotalStages(3);
		this.progressServ.setProgressState(1 * 100);
		this.progressServ.setBarType(1);
		if (!this.newOrder.quoteData)
			this.router.navigate(['/quote/' + this.auth.getUserId() + '/step1']);
		else {
			this.quoteDetails = Object.assign({}, this.newOrder.quoteData);
			this.selectedState = this.quoteDetails.Property_State_Abbr
				? this.quoteDetails.Property_State_Abbr
				: '';
			this.selectedCounty = this.quoteDetails.Property_County
				? this.quoteDetails.Property_County.replace(' County', '')
				: '';
			this.buildAddress(this.quoteDetails);
			if (this.quoteDetails.Property_State_Abbr) {
				this.selectedState = this.quoteDetails.Property_State_Abbr;
				this.pricingService.getCountyList(this.selectedState).subscribe(counties => {
					if (counties) this.counties = counties;
				});
			}
			if (!this.quoteDetails.Property_State_Abbr) {
				this.pricingService.getStateList().subscribe(states => {
					if (states) this.states = states;
				});
			}
		}
	}

	buildAddress(model) {
		this.completeAddress = '';
		if (model.Property_Address_1) this.completeAddress += model.Property_Address_1;
		if (model.Property_Address_2) {
			if (this.completeAddress) this.completeAddress += '\n';
			this.completeAddress += model.Property_Address_2;
		}
		if (model.Property_City) {
			if (this.completeAddress) this.completeAddress += '\n';
			this.completeAddress += model.Property_City;
		}
		if (model.Property_State_Abbr) {
			if (this.completeAddress) this.completeAddress += ', ';
			this.completeAddress += model.Property_State_Abbr;
		}
		if (model.Property_ZipCode) {
			if (this.completeAddress) this.completeAddress += ' ';
			this.completeAddress += model.Property_ZipCode;
		}
	}

	getCountyList(event) {
		if (event.length != 0) {
			this.selectedCounty = '';
			this.pricingService.getCountyList(this.selectedState).subscribe(counties => {
				if (counties) this.counties = counties;
			});
		}
	}

	validateFields() {
		if (!this.selectedState || !this.selectedCounty) return false;
		else return true;
	}

	createRequestQuote() {
		this.quoteDetails.selectedCriteria = this.newOrder.searchCriteria;
		if (this.newOrder.searchMode == 2 && this.newOrder.searchCriteria == 1)
			this.quoteDetails.manualEntryFlag = true;
		else this.quoteDetails.manualEntryFlag = false;
		this.quoteDetails.Property_State_Abbr = this.selectedState;
		this.quoteDetails.Property_County = this.selectedCounty;
		this.newOrder.quoteData = this.quoteDetails;
		this.newOrder.quoteData['selectedOrderCriteria'] = this.quoteDetails.selectedCriteria;
		this.createNewRequestQuote();
	}

	createNewRequestQuote() {
		this.newOrder.createQuote().subscribe(
			data => {
				if (this.quoteDetails.orderDocId.length > 0) this.updateOrderDocs(data);
        this.newOrder.resetAll();
        // let successMsg = "Quote " + data.Order_ID + " request successful. We will email you back shortly with your quote results."
        // this.openSuccessDialog('Success', successMsg);
        this.router.navigate(['/quote/' + data.Order_ID + '/view']);
			},
			err => {
				if (err.code == 'PTOR02') this.openSuccessDialog(this.text.get('TXT26'), err.msg);
				if (err.msg) this.error = err.msg;
			},
		);
	}

	goToQuoteStep2() {
		this.router.navigate(['/quote/' + this.auth.getUserId() + '/step2']);
	}

	updateOrderDocs(data) {
		var docUpdate = [];
		this.quoteDetails.orderDocId.forEach(docId => {
			docUpdate.push(
				this.newOrder.updateOrderDocument(
					data.Order_ID,
					data.Property_ID,
					docId.Order_Document_ID,
				),
			);
		});
		observableForkJoin(docUpdate).subscribe(
			result => {},
			err => {
				console.log('Error in Upload: ', err);
			},
		);
	}

	openSuccessDialog(title, msg) {
		let disposable = this.dialogService
			.addDialog(SuccessComponent, {
				title: title,
				message: msg,
			})
			.subscribe(res => {});
	}
}
