import { Component } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent extends DialogComponent<any, any> {
  eulaData : any;
  eulaText;
  isEULATextAvl: boolean = false;
  constructor(dialogService: DialogService) {
    super(dialogService);   
  }

  ngOnInit() { }

  Close() {
    this.result = false;
    this.close()
  }

}
