import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { StaticTextService } from '../../services';
import { Router } from '@angular/router';
import { ConfigService } from '../../services/config/config.service';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent extends DialogComponent<any, any>{
  @ViewChild('okBtn') focusedElement: ElementRef;
  title: any;
  message: any;
  buttonValue: any;
  constructor(dialogService: DialogService, public text: StaticTextService, private router: Router, private config: ConfigService,) {
    super(dialogService);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
    if (this.focusedElement != undefined) {
      this.focusedElement.nativeElement.focus();
    }
  }

  confirm() {
    this.result = true;
    this.close();
  }

  Close() {
    this.result = false;
    this.close()
  }

  goToCancellationPolicy() {
    return 'https://pippintitle.com/cancellationpolicy';
  }
  

}
