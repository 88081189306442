import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	Type,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PricingService, StaticTextService, StripeService } from '@services';
import { getProp } from '@styled';
import * as utils from '@utils';
import {
	addressToObj,
	addressToString,
	ADDRESS_FIELDS,
	compose,
	Dict,
	get,
	isBool,
	isEmptyObj,
	isEmptyString,
	isEq,
	isNil,
	isObj,
	isString,
	merge,
	objFilterEmpty,
	objMap,
	omit,
	ONLY_STRING_REGEX,
	pick,
	__DEV__,
} from '@utils';
import { ErrorHandler } from 'app/error-handler/error.handler';

/* ---------------------------------- */

const { maxLength: maxLen, pattern, required: req } = Validators;

/* ---------------------------------- */

export const RULES = {
	name: [req],
	addressLine1: [req, maxLen(127)],
	addressLine2: [maxLen(127)],
	city: [req, maxLen(127), pattern(ONLY_STRING_REGEX)],
	state: [req],
	county: [req],
	zip: [req, maxLen(11)],
	country: [req],
};

/* ---------------------------------- */

const propToAddress = (val: any) =>
	omit(merge(addressToObj(getProp(val)), pick(getProp(val), ADDRESS_FIELDS)), [
		['country'],
	]);

/* ---------------------------------- */

@Component({
	selector: 'address',
	template: `
		<!-- readOnly -->
		<address-readonly
			#readOnlyRef
			*ngIf="!edit"
			[fields]="value"
			[isLoading]="isLoading"
		></address-readonly>

		<!-- editing mode -->
		<address-form
			#formControlRef
			*ngIf="edit"
			[fc]="fc"
			[initialValue]="initialValue"
			[initialFocus]="initialFocus"
			[isLoading]="isLoading"
			[config]="config"
		></address-form>
	`,
})
export class Address implements OnChanges, OnInit {
	//
	// ────────────────────────────────────────────────────────────────── CONFIGS ─────
	//

	_defaults: Dict = {
		name: {
			value: '',
			placeholder: 'Name',
		},
		addressLine1: {
			value: '',
			placeholder: this.txt.get('LBL90'),
		},
		addressLine2: {
			value: '',
			placeholder: this.txt.get('LBL91'),
		},
		city: {
			value: '',
			placeholder: this.txt.get('LBL25'),
		},
		state: {
			value: '',
			placeholder: 'Search',
		},
		zip: {
			value: '',
			placeholder: this.txt.get('LBL92'),
		},
	};

	//
	// ─────────────────────────────────────────────────────── INTERNAL STATE API ─────
	//

	_isInit: boolean = false;

	_initFocused: boolean = false;

	//
	// ─────────────────────────────────────────────────────── EXTERNAL STATE API ─────
	//

	// The root control of the form.
	fc: FormGroup = this.fb.group([]);

	/**
	 * The formatted aggregate value of all form fields.
	 */
	get value(): Dict {
		return addressToObj(get(this, 'fc.value'), this.format);
	}

	/**
	 * The unformatted aggregate value of all form fields.
	 */
	get rawValue(): Dict {
		return this.fc.value;
	}

	/**
	 * The form controls for each field input.
	 */
	get fields(): Dict {
		return this.fc.controls;
	}

	/**
	 * If the form status is DISABLED.
	 */
	get disabled(): boolean {
		return this.fc.disabled;
	}

	/**
	 * If the user has changed the form value in the UI.
	 */
	get dirty(): boolean {
		return this.fc.dirty;
	}

	/**
	 * If the form status is VALID.
	 */
	get valid(): boolean {
		return this.fc.valid;
	}

	/**
	 * If the form status is `INVALID`.
	 */
	get invalid(): boolean {
		return this.fc.invalid;
	}

	/**
	 * If the `rawValue` is different then the `initialValue`.
	 */
	get changed(): boolean {
		const result = this._isInit && !isEq(this.initialValue, this.rawValue);
		return result;
	}

	/**
	 * The validation status of the form: `VALID` | `INVALID` | `PENDING` | `DISABLED`.
	 */
	get status(): 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED' {
		return get(this, 'fc.status');
	}

	/**
	 * If all inputs have either a null value or an empty string.
	 */
	get empty(): boolean {
		return Object.values(this.rawValue).every(
			(val: string) => isNil(val) || isEmptyString(val),
		);
	}

	/**
	 * An object containing any errors generated by failing validation,
	 * or null if there are no errors.
	 */
	get errors(): Dict {
		const errors = compose(objMap('errors'), objFilterEmpty)(this.fields);
		return isEmptyObj(errors) ? null : errors;
	}

	/**
	 * If any of the form validation has failed.
	 */
	get hasErrors(): boolean {
		return Object.keys(this.fields).some(field => isObj(this.fields[field].errors));
	}

	//
	// ─────────────────────────────────────────────────────────── DATA PROPS API ─────
	//

	/**
	 * The format to convert the address to when returning the value.
	 */
	@Input() format:
		| 'default'
		| 'user'
		| 'billing'
		| 'billingOrder'
		| 'property'
		| 'stripe' = 'default';

	/**
	 * If true fields are rendered as skeletons.
	 */
	@Input()
	get isLoading(): boolean {
		return this._isLoading;
	}
	set isLoading(val: boolean) {
		if (isBool(val)) {
			this._isLoading = val;
			this.cdr.detectChanges();
		}
	}
	_isLoading: boolean = false;

	/**
	 * If true fields are rendered as plain text.
	 */
	@Input() readOnly: boolean = false;

	/**
	 * If true the form fields are rendered.
	 */
	@Input() edit: boolean = true;

	/**
	 * When form validation is run.
	 */
	@Input() updateOn: 'blur' | 'change' | 'submit' = 'change';

	/**
	 * A field to focus once the form has finished loading.
	 */
	@Input() initialFocus: string;

	/**
	 * The value of the form right after loading is complete.
	 */
	@Input()
	get initialValue(): Dict {
		return this._initialValue;
	}
	_initialValue: Dict;

	/**
	 * The default values/placeholders of the form fields.
	 */
	@Input()
	get config(): Dict {
		return this._config ? this._config : this._defaults;
	}
	set config(val: Dict) {
		val = propToAddress(val);

		if (isObj(val) && !this._isInit) {
			this._config = merge(
				this._defaults,
				objMap(val, (k: string, v: any) =>
					isString(v) ? { value: v } : pick(v, ['value', 'placeholder']),
				),
			);
		}
	}
	_config: Dict;

	//
	// ────────────────────────────────────────────────────────── EVENT PROPS API ─────
	//

	/**
	 * Event handler that is called when the form `status` changes.
	 */
	@Output() public onStatus: EventEmitter<any> = new EventEmitter();

	/**
	 * Event handler that is called when the form `value` changes.
	 */
	@Output() public onChange: EventEmitter<any> = new EventEmitter();

	/**
	 * Event handler that is called when the form is `valid`.
	 */
	@Output() public onValid: EventEmitter<any> = new EventEmitter();

	/**
	 * Event handler that is called when the form is `inValid`.
	 */
	@Output() public onInvalid: EventEmitter<any> = new EventEmitter();

	//
	// ──────────────────────────────────────────────────────── CHILDREN REFS API ─────
	//

	/**
	 * Reference to the `<read-only>` element.
	 */
	@ViewChild('readOnlyRef') readOnlyRef: ElementRef;

	@ViewChild('formControlRef') formControlRef: any;

	//
	// ──────────────────────────────────────────────────────── LIFECYCLE METHODS ─────
	//

	constructor(
		protected cdr: ChangeDetectorRef,
		protected host: ElementRef,
		protected err: ErrorHandler,
		protected fb: FormBuilder,
		protected pricing: PricingService,
		protected stripe: StripeService,
		protected txt: StaticTextService,
	) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['address'])) window['address'] = this;
			if (isNil(window['utils'])) window['utils'] = utils;
		}
	}

	ngOnChanges(props: SimpleChanges) {
		for (const prop in props) {
			const prev = getProp(props[prop].previousValue);
			const value = getProp(props[prop].currentValue);
			const changed = !isEq(prev, value);

			if (prop === 'isLoading') {
				this._initInitialValue();
			}
		}
	}

	ngOnInit() {
		// Set root and nested form controls.
		this._initFormControls();

		// Set initial values, finish initialization.
		this._initInitialValue();

		// Subscribe to form value changes.
		this.fc.valueChanges.subscribe((value: Dict) => {
			this.onChange.emit(value);
		});

		// Subscribe to form status changes.
		this.fc.statusChanges.subscribe((value: string) => {
			if (value === 'VALID') this.onValid.emit();
			if (value === 'INVALID') this.onInvalid.emit();
			this.onStatus.emit(value);
		});
	}

	//
	// ───────────────────────────────────────────────────────── INTERNAL METHODS ─────
	//

	_getErr = (field: string) => this.err.getError(this.fc, field);

	_initFormControls = () => {
		Object.entries(this.config).forEach(([key, val]) => {
			this.fc.addControl(
				key,
				new FormControl(get(val, 'value', ''), {
					updateOn: this.updateOn,
					validators: get(RULES, key, []),
				}),
			);
		});
	};

	_initInitialValue = () => {
		if (!this._isInit && !this.isLoading) {
			this._initialValue = this.rawValue;
			this._isInit = true;
		}
	};

	//
	// ───────────────────────────────────────────────────── EXTERNAL METHODS API ─────
	//

	/**
	 * Stringifies the form `value`.
	 */
	toString = (): string => addressToString(this.value);

	/**
	 * Sets all fields to `disabled = true`.
	 */
	disable = () => this.fc.disable();

	/**
	 * Sets all fields to `disabled = false`.
	 */
	enable = () => this.fc.enable();

	/**
	 * Sets all fields to `dirty = true`.
	 */
	markAsDirty = () => this.fc.markAsDirty();

	/**
	 * Sets all fields to `touched = true`.
	 */
	markAsTouched = () => this.fc.markAsTouched();

	/**
	 * Sets all fields to `touched = false`.
	 */
	markAsUntouched = () => this.fc.markAsUntouched();

	/**
	 * Recalculates the value and validation status of the form.
	 */
	update = () => {
		this.fc.updateValueAndValidity();
	};

	/**
	 * Resets the form and the `_initialValue` of the form.
	 */
	reset = (fields?: Dict) => {
		if (isObj(fields)) {
			fields = addressToObj(fields);
			this._initialValue = fields;
		}
		this.fc.reset(fields ? fields : this.initialValue);
		return this.cdr.detectChanges();
	};

	/**
	 * Resets the form `_initialValue` and marks all form fields (and child fields) as pristine.
	 */
	markAsPristine = () => {
		this._initialValue = this.rawValue;
		this.fc.markAsPristine();
		return this.cdr.detectChanges();
	};

	/**
	 * Matches keys to controls in `this.fc` and updates their values.
	 */
	patchValue = (value: Dict) => {
		if (isObj(value)) {
			this.fc.patchValue(addressToObj(value));
			return this.cdr.detectChanges();
		}
	};

	/**
	 * Sets the value of the form.
	 */
	setValue = (value: Dict) => {
		if (isObj(value)) {
			this.fc.setValue(addressToObj(value));
			return this.cdr.detectChanges();
		}
	};
}
