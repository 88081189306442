import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {

  scrollPosOpenOrder:any=null;
  scrollPosCompletedOrder:any=null;
  scrollPosCancelOrder:any=null;
  scrollPosMessages:any=null;
  scrollPosQuoteReq:any=null;
  constructor() { }

  resetAll(){
    this.scrollPosOpenOrder=null;
    this.scrollPosCompletedOrder=null;
    this.scrollPosCancelOrder=null;
    this.scrollPosMessages=null;
    this.scrollPosQuoteReq=null;
  }

  setScrollPosOpendOrd(scrollPos){
    this.scrollPosOpenOrder=scrollPos;
  }

  getScrollPosOpenOrd(){
    if(this.scrollPosOpenOrder)
    return this.scrollPosOpenOrder;
  }

  setScrollPosCompltdOrd(scrollPos){
    this.scrollPosCompletedOrder=scrollPos;
  }

  getScrollPosCompltdOrd(){
    if(this.scrollPosCompletedOrder)
    return this.scrollPosCompletedOrder;
  }

  setScrollPosCancelOrd(scrollPos){
    this.scrollPosCancelOrder=scrollPos;
  }
  
  getScrollPosCancelOrd(){
    if(this.scrollPosCancelOrder)
    return this.scrollPosCancelOrder;
  }

  setScrollPosMessages(scrollPos){
    this.scrollPosMessages=scrollPos;
  }
  
  getScrollPosMessages(){
    if(this.scrollPosMessages)
    return this.scrollPosMessages;
  }

  setScrollPosQuoteReq(scrollPosQuoteReq){
    this.scrollPosQuoteReq = scrollPosQuoteReq;
  }

  getScrollPosQuoteReq(){
    if(this.scrollPosQuoteReq)
    return this.scrollPosQuoteReq;
  }
}
