import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StaticTextService, AboutService, ConfigService, AuthService } from '../../services';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {
  //innerText:String = ''
  timer: any;
  constructor(
    public text:StaticTextService,
    private pages: AboutService,
    private config:ConfigService,
    private auth:AuthService
  ) { }

  ngOnInit() {
    this.auth.loadHubSpotChatToken();
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy(){
    if(this.timer) {
      clearTimeout(this.timer)
    };
  }

}
