import { isNotEmptyString, isObj, isEmptyObj } from './assertion';
import { get, pick } from './object';
import { Dict } from './types';

//
// ──────────────────────────────────────────────────────────────────── TYPES ─────
//

export type AddressField =
	| 'name'
	| 'addressLine1'
	| 'addressLine2'
	| 'city'
	| 'state'
	| 'zip';

export type AddressName = string;
export type AddressLine1 = string;
export type AddressLine2 = string;
export type AddressCity = string;
export type AddressState = string;
export type AddressCounty = string;
export type AddressZip = string;
export type AddressCountry = string;

export interface Address {
	name?: AddressName;
	addressLine1: AddressLine1;
	addressLine2?: AddressLine2;
	city: AddressCity;
	state: AddressState;
	county: AddressCounty;
	zip: AddressZip;
	country?: AddressCountry;
}

export interface BillingAddress {
	Billing_Full_Name?: AddressName;
	Billing_Address_1: AddressLine1;
	Billing_Address_2?: AddressLine2;
	Billing_City: AddressCity;
	Billing_State: AddressState;
	Billing_Zip: AddressZip;
}
export interface OrderBillingAddress {
	Billing_Street_Address_Line_1: AddressLine1;
	Billing_Street_Address_Line_2?: AddressLine2;
	Billing_City: AddressCity;
	Billing_State: AddressState;
	Billing_Zip: AddressZip;
}

export interface PropertyAddress {
	Property_Address_1: AddressLine1;
	Property_Address_2?: AddressLine2;
	Property_City: AddressCity;
	Property_State: AddressState;
	Property_ZipCode: AddressZip;
	Property_Country: AddressCountry;
}

export interface StripeAddress {
	address_line1: AddressLine1;
	address_line2?: AddressLine2;
	address_city: AddressCity;
	address_state: AddressState;
	address_zip: AddressZip;
}

export type AddressTypes = Address | BillingAddress | PropertyAddress | StripeAddress;

//
// ──────────────────────────────────────────────────────────────── CONSTANTS ─────
//

export const ADDRESS_FIELDS = [
	'name',
	'addressLine1',
	'addressLine2',
	'city',
	'state',
	'zip',
];

export const ADDRESS_FORMATS = {
	name: {
		default: 'name',
		billing: 'Billing_Full_Name',
		billingOrder: 'name',
		stripe: 'name',
	},
	addressLine1: {
		default: 'addressLine1',
		user: 'Address_1',
		billing: 'Billing_Address_1',
		billingOrder: 'Billing_Street_Address_Line_1',
		property: 'Property_Address_1',
		stripe: 'address_line1',
	},
	addressLine2: {
		default: 'addressLine2',
		user: 'Address_2',
		billing: 'Billing_Address_2',
		billingOrder: 'Billing_Street_Address_Line_2',
		property: 'Property_Address_2',
		stripe: 'address_line2',
	},
	city: {
		default: 'city',
		user: 'Address_City',
		billing: 'Billing_Address_City',
		billingOrder: 'Billing_City',
		property: 'Property_City',
		stripe: 'address_city',
	},
	state: {
		default: 'state',
		user: 'Address_State',
		billing: 'Billing_Address_State_Abbr',
		billingOrder: 'Billing_State',
		property: 'Property_State_Abbr',
		stripe: 'address_state',
	},
	county: {
		default: 'county',
		property: 'Property_County',
	},
	zip: {
		default: 'zip',
		user: 'Address_ZipCode',
		billing: 'Billing_Address_ZipCode',
		billingOrder: 'Billing_Zip',
		property: 'Property_ZipCode',
		stripe: 'address_zip',
	},
	country: {
		default: 'country',
		property: 'Property_Country',
		stripe: 'address_country',
	},
};

//
// ────────────────────────────────────────────────────────────────── METHODS ─────
//

export const getAddressName = (val: Dict): AddressName =>
	pick(val, Object.values(ADDRESS_FORMATS.name), { asArray: true }).shift();

export const getAddressLine1 = (val: Dict): AddressLine1 =>
	pick(val, Object.values(ADDRESS_FORMATS.addressLine1), { asArray: true }).shift();

export const getAddressLine2 = (val: Dict): AddressLine2 =>
	pick(val, Object.values(ADDRESS_FORMATS.addressLine2), { asArray: true }).shift();

export const getAddressCity = (val: Dict): AddressCity =>
	pick(val, Object.values(ADDRESS_FORMATS.city), { asArray: true }).shift();

export const getAddressState = (val: Dict): AddressState =>
	pick(val, Object.values(ADDRESS_FORMATS.state), { asArray: true }).shift();

export const getAddressCounty = (val: Dict): AddressCounty =>
	pick(val, Object.values(ADDRESS_FORMATS.county), { asArray: true }).shift();

export const getAddressZip = (val: Dict): AddressZip =>
	pick(val, Object.values(ADDRESS_FORMATS.zip), { asArray: true }).shift();

export const getAddressCountry = (val: Dict): AddressCountry =>
	pick(val, Object.values(ADDRESS_FORMATS.country), { asArray: true }).shift();

/* ---------------------------------- */

export const addressToString = (
	val: Dict,
	options?: { asSingleLine: boolean },
): string => {
	// Options
	const asSingleLine = get(options, 'asSingleLine', false);
	const removeEmptyString = get(options, 'removeEmptyString', true);
	// Address
	const name = getAddressName(val) || '';
	const addressLine1 = getAddressLine1(val) || '';
	const addressLine2 = getAddressLine2(val) || '';
	const city = getAddressCity(val) || '';
	const state = getAddressState(val) || '';
	const zip = getAddressZip(val) || '';
	const country = getAddressCountry(val) || '';

	return addressLine1 && city && state && zip
		? asSingleLine
			? [name, addressLine1, addressLine2, city, state, zip, country]
					.filter((field: string) =>
						removeEmptyString ? isNotEmptyString(field) : field,
					)
					.join(', ')
			: `${name}
${addressLine1}
${addressLine2}
${city}, ${state} ${zip}
${country}
`.replace(/(^[ \t]*\n)/gm, '')
		: null;
};

/* ---------------------------------- */

export const addressToObj = <T extends any>(
	val: Dict,
	format: string = 'default',
	options = {},
): T => {
	if (!isObj(val)) return null;
	// Options
	const removeEmptyString = get(options, 'removeEmptyString', true);
	// Fields
	const name = getAddressName(val);
	const addressLine1 = getAddressLine1(val);
	const addressLine2 = getAddressLine2(val);
	const city = getAddressCity(val);
	const state = getAddressState(val);
	const county = getAddressCounty(val);
	const zip = getAddressZip(val);
	const country = getAddressCountry(val);
	// The formatted address object
	const next: any = {};

	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(name))) {
		next[ADDRESS_FORMATS.name[format]] = name;
	}
	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(addressLine1))) {
		next[ADDRESS_FORMATS.addressLine1[format]] = addressLine1;
	}
	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(addressLine2))) {
		next[ADDRESS_FORMATS.addressLine2[format]] = addressLine2;
	}
	else next[ADDRESS_FORMATS.addressLine2[format]] = '';
	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(city))) {
		next[ADDRESS_FORMATS.city[format]] = city;
	}
	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(state))) {
		next[ADDRESS_FORMATS.state[format]] = state;
	}
	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(county))) {
		next[ADDRESS_FORMATS.county[format]] = county;
	}
	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(zip))) {
		next[ADDRESS_FORMATS.zip[format]] = zip;
	}
	if (!removeEmptyString || (removeEmptyString && isNotEmptyString(country))) {
		next[ADDRESS_FORMATS.country[format]] = country;
	}
	return isEmptyObj(next) ? null : next;
};
