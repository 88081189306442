import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { get, isNil, isString, isBool, __DEV__ } from '@utils';

declare var window: any;

type Severity = 'error' | 'warning' | 'info' | 'success';

type Variant = 'filled' | 'outlined' | 'standard';

/* ---------------------------------- */

const SEVERITY_PROPS = ['error', 'warning', 'info', 'success'];

const VARIANT_PROPS = ['filled', 'outlined', 'standard'];

/* ---------------------------------- */

@Component({
	selector: 'alert',
	template: `
		<div
			[ngClass]="class"
			[ngStyle]="style"
			sx
			zIndex="1"
			[position]="isSticky ? 'sticky' : 'relative'"
			[top]="top"
			[bottom]="bottom"
		>
			<collapse
				#collapse
				[isOpen]="isOpen"
				(onClose)="_handleClose()"
				(onClosed)="_handleClosed()"
				(onOpen)="_handleOpen()"
				(onOpened)="_handleOpened()"
			>
				<div
					sx
					d="flex"
					py="2"
					px="4"
					alignItems="center"
					bg="{{ _bg }}"
					color="{{ _color }}"
					fontSize="sm"
					border="{{ variant === 'outlined' ? '1px solid ' + _borderColor : 'none' }}"
					borderRadius="sm"
					variant="p"
				>
					<icon
						*ngIf="icon"
						icon="{{ icon }}"
						colorScheme="{{ _borderColor }}"
						sx
						mr="1"
						maxW="6"
						maxH="6"
					></icon>
					<span sx flex="1"><ng-content></ng-content></span>
					<button (click)="close()" mat-icon-button sx variant="button.icon">
						<icon colorScheme="{{ _color }}" icon="close"></icon>
					</button>
				</div>
			</collapse>
		</div>
	`,
})
export class Alert {
	//
	// ─────────────────────────────────────────────────────── INTERNAL STATE API ─────
	//

	/**
	 * The background color.
	 * @ignore
	 */
	get _bg() {
		return get(
			{
				filled: `${this.colorScheme}.main`,
				outlined: 'whiteAlpha.900',
				standard: `${this.colorScheme}.light`,
			},
			this.variant,
		);
	}

	/**
	 * The text color.
	 * @ignore
	 */
	get _color() {
		return get(
			{
				filled: 'whiteAlpha.900',
				outlined: 'blackAlpha.700',
				standard: 'blackAlpha.700',
			},
			this.variant,
		);
	}

	/**
	 * Color of borders and icons.
	 * @ignore
	 */
	get _borderColor() {
		return get(
			{
				filled: 'whiteAlpha.900',
				outlined: `${this.colorScheme}.main`,
				standard: `${this.colorScheme}.dark`,
			},
			this.variant,
		);
	}

	//
	// ─────────────────────────────────────────────────────── EXTERNAL PROPS API ─────
	//

	@Input() isSticky: boolean = false;

	@Input() top: any;

	@Input() bottom: any;

	@Input() class: any;

	@Input() style: any;

	/**
	 * If the alert is visible or not.
	 */
	@Input()
	get isOpen(): boolean {
		return this._isOpen;
	}
	set isOpen(val: boolean) {
		if (isBool(val)) {
			this._isOpen = val;
			this.cdr.detectChanges();
		}
	}
	_isOpen: boolean = false;

	/**
	 * The icon to use.
	 */
	@Input()
	get icon(): string | boolean {
		return (
			this._icon ||
			get(
				{
					error: 'error_outline',
					info: 'info_outline',
					success: 'check_outline',
					warning: `warning_outline`,
				},
				this.severity,
			)
		);
	}
	set icon(val: string | boolean) {
		this._icon = isString(val) ? val : null;
	}
	_icon: string;

	/**
	 * Derives the text and bg colors, else uses `severity`.
	 */
	@Input()
	get colorScheme(): string {
		return get(this, '_colorScheme', this.severity);
	}
	set colorScheme(val: string) {
		this._colorScheme = val;
	}
	_colorScheme: string;

	/**
	 * The severity of the alert (defines color and icon).
	 */
	@Input()
	get severity(): Severity {
		return this._severity;
	}
	set severity(val: Severity) {
		if (SEVERITY_PROPS.includes(val)) this._severity = val;
	}
	_severity: Severity = 'success';

	/**
	 * The variant to use.
	 */
	@Input()
	get variant(): Variant {
		return this._variant;
	}
	set variant(val: Variant) {
		if (VARIANT_PROPS.includes(val)) this._variant = val;
	}
	_variant: Variant = 'standard';

	//
	// ────────────────────────────────────────────────────── EXTERNAL EVENTS API ─────
	//

	/**
	 * Handler triggered once the alert has started close animation.
	 */
	@Output() onClose: EventEmitter<any> = new EventEmitter();

	/**
	 * Handler triggered once the alert has finished close animation.
	 */
	@Output() onClosed: EventEmitter<any> = new EventEmitter();

	/**
	 * Handler triggered once the alert has started open animation.
	 */
	@Output() onOpen: EventEmitter<any> = new EventEmitter();

	/**
	 * Handler triggered once the alert has finished open animation.
	 */
	@Output() onOpened: EventEmitter<any> = new EventEmitter();

	//
	// ──────────────────────────────────────────────────────── CHILDREN REFS API ─────
	//

	@ViewChild('#collapse') collapse: any;

	//
	// ──────────────────────────────────────────────────────── LIFECYCLE METHODS ─────
	//

	constructor(protected cdr: ChangeDetectorRef) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['alert'])) window['alert'] = this;
		}
	}

	//
	// ───────────────────────────────────────────────────────── INTERNAL METHODS ─────
	//

	/**
	 * Handler triggered once the alert has started close animation.
	 * @ignore
	 */
	_handleClose = () => this.onClose.emit();

	/**
	 * Handler triggered once the alert has finished close animation.
	 * @ignore
	 */
	_handleClosed = () => this.onClosed.emit();

	/**
	 * Handler triggered once the alert has started open animation.
	 * @ignore
	 */
	_handleOpen = () => this.onOpen.emit();

	/**
	 * Handler triggered once the alert has finished open animation.
	 * @ignore
	 */
	_handleOpened = () => this.onOpened.emit();

	//
	// ───────────────────────────────────────────────────── EXTERNAL METHODS API ─────
	//

	/**
	 * Open the alert.
	 */
	open = () => {
		this.isOpen = true;
	};

	/**
	 * Close the alert.
	 */
	close = () => {
		this.isOpen = false;
	};
}
