import { Component, OnInit } from '@angular/core';
import { AuthService, StaticTextService, ConfigService } from '../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-step1',
  templateUrl: './registration-step1.component.html',
  styleUrls: ['./registration-step1.component.scss']
})
export class RegistrationStep1Component implements OnInit {
  // userType: any = {};
  userType = "1";
  timer:any;
  constructor(
    public text: StaticTextService,
    private auth: AuthService,
    private config:ConfigService,
    private router: Router, ) { }

  ngOnInit() {
    this.auth.loadHubSpotChatToken();
    // this.auth.init().subscribe((res) => {});
    //this.userType = 0;
    console.log("userType", this.userType);
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy(){
    if(this.timer)clearTimeout(this.timer)
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  goToRegistrationPage() {
    this.router.navigate(['registration/' + this.userType]);
  }

  // detectTypeChange(event) {
  //   
  //    
  // }


}
