import { AfterContentInit, Component, ElementRef } from '@angular/core';
import { proxyAttr } from '@utils';

//
// ────────────────────────────────────────────────────── CONTAINER COMPONENT ─────
//

@Component({
	selector: 'container',
	template: `
		<div
			sx
			maxW="['700px', null, '1015px']"
			mx="auto"
			py="0"
			px="['0px', '8px', '64px', '0px']"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class Container implements AfterContentInit {
	constructor(protected el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'class');
		proxyAttr(this.el, 'style');
	}
}
