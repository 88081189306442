import { ContentObserver } from '@angular/cdk/observers';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { NavigationEnd, Router } from '@angular/router';
import {
	AuthService,
	ConfigService,
	FileService,
	OrderListingService,
	PricingService,
	ProductService,
	ProgressBarService,
	StaticTextService,
} from '@services';
import * as awsS3 from '@services/aws-s3';
import * as newOrderService from '@services/new-order';
import { theme } from '@styled';
import * as utils from '@utils';
import {
	DATE_REGEX,
	objFilterNil,
	get,
	isNil,
	isNotEmptyObj,
	scrollToFocusElements,
	__DEV__,
} from '@utils';
import { UUID } from 'angular2-uuid';
import { ConfirmComponent } from 'app/dialog/confirm/confirm.component';
import { DialogService } from '../../../module/dialog/bootstrap-modal.module';
import { filter, pairwise } from 'rxjs/operators';
import { CONSTANTS } from '../../../app.constants';
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';
import { MessageComponent } from '../../../dialog/message/message.component';
import { SuccessComponent } from '../../../dialog/success/success.component';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

const { compose, maxLength, required } = Validators;

declare var google: any;

@Component({
	selector: 'app-new-order1',
	styleUrls: ['./new-order1.component.scss'],
	templateUrl: './new-order1.component.html',
})
export class NewOrder1Component implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('docsRef') uploadRef: ElementRef;

	@ViewChild('productsDropDown') productsDropDown: ElementRef;

	@ViewChild('uploadFileInput', { static: true }) uploadFileInput: ElementRef;

	/* ---------------------------------- */
	isRushOrder: boolean = false;  
	todayDate = new Date();
	getDate = this.todayDate.setDate(this.todayDate.getDate() + 1)
	getUSDateString = new Date(this.getDate).toLocaleString("en-US", { timeZone: "America/New_York" });
    minDate = new Date(this.getUSDateString); 
	dueDateEtaDate: any;
	mortgageDate: any;
	requestedDate: any;
	orderReqtime = new UntypedFormControl({ value: '', disabled: true });
	requestedTime: string = "17:00";

	borrower_addressOption:string;
	borrowerAddressOption: string = "Borrower_Address";
	borrowerAdressObject = {}
	public constants: any = CONSTANTS;

	public forms: {
		state: UntypedFormControl;
		order: UntypedFormGroup;
		criteria: {
			fullAddress: UntypedFormGroup;
			borrower: UntypedFormGroup;
			parcel: FormGroup;
		};
	} = {
		state: new UntypedFormControl({ value: null, disabled: false }),
		order: this.formBuilder.group({
			Order_Mortgage_Amount: [null, compose([maxLength(255)])],
			Order_Mortgage_Date: {  disabled: true, value: ''},
			Order_Estimated_Time: { disabled: true, value: '' },
			Order_Requested_Date: { disabled: true, value: '' },
			Order_Seller: [null],
			Order_Type: [1, required],
			Property_County: new UntypedFormControl({ value: null, disabled: false }, required),
			Property_Order_Number: [null, compose([required, maxLength(255)])],
			Property_State_Abbr: new UntypedFormControl({ value: '', disabled: false }, required),
			Property_Order_Notes: [null],
			Order_Instructions: [null],
      Order_Subdivision: [null, compose([maxLength(255)])],
		}),
		criteria: {
			fullAddress: this.formBuilder.group({
				Property_Address_1: [null, compose([required, maxLength(127)])],
				Property_Address_2: [null, maxLength(127)],
				Property_City: [null, compose([required, maxLength(127)])],
				Property_First_Name: [null, maxLength(127)],
				Property_ZipCode: [null, compose([required, maxLength(5), Validators.pattern("^[0-9]*$")])],
				Order_Buyer: [null, compose([maxLength(127)])],
				Order_Co_Buyer: [null, compose([maxLength(127)])],
			}),
			borrower: this.formBuilder.group({
				Order_Buyer: [null, compose([required,maxLength(127)])],
				Order_Co_Buyer: [null, compose([maxLength(127)])],
				Co_Borrower_SSN: [null, Validators.maxLength(4)],
				Borrower_SSN: [null, Validators.maxLength(4)],
				Loan_ID: [null, compose([maxLength(50)])],
				// Borrower_Address:[null, compose([maxLength(127)])],
				// Co_Borrower_Address:[null, compose([maxLength(127)])],
			}),
			parcel: this.formBuilder.group({
				Order_Book: [null, compose([maxLength(255)])],
				Order_Loan: [null, compose([required, maxLength(255)])],
				Order_Page: [null, compose([maxLength(255)])],
				Order_Parcel: [null, compose([required, maxLength(255)])],
				Order_Buyer: [null, compose([maxLength(127)])],
				Order_Co_Buyer: [null, compose([maxLength(127)])],
			}),
		},
	};

	public counties: any[] = [];

	public states: any[] = [];

	public products: any[] = [];

	public productsCustom: any[] = [];

	public productsDefault: any[] = [];

  public productName: string;

  public productDescription: string;

	public productRequirements: any[] = [];

	public paymentIsRequired: boolean = false;

	public criteriaSearch: number | string;

	public availableCounty: any;

	protected googlePlaceState: any;

	protected timer: any;

	protected removeFocusListeners: () => void;

	protected error: String = '';

	public errors: any = {};

	protected fieldError: any = [];

	protected searchError: boolean = false;

	protected primaryColor: string = '#0F9D58';

	showCityInCounty:boolean = false;

	allIndepdentCities : any;

	mortgageInput="";
	loanInput="";
	/* ---------------------------------- */

	constructor(
		private auth: AuthService,
		private awsS3: awsS3.service,
		private config: ConfigService,
		// private dialogService: DialogService,
		private matDialog:MatDialog,
		private fileService: FileService,
		private formBuilder: UntypedFormBuilder,
		private orderListService: OrderListingService,
		private pricingService: PricingService,
		private productService: ProductService,
		private progressBarService: ProgressBarService,
		private router: Router,
		public cdr: ChangeDetectorRef,
		public order: newOrderService.service,
		public staticText: StaticTextService,
    private titleService: Title,
	) {
		if (__DEV__) {
			window['utils'] = window['utils'] || utils;
			window['order'] = { ...window['order'], step1: this };
		}

		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				pairwise(),
			)
			.subscribe(event => this.auth.setPrevUrl(Object.assign({}, event[0])['url']));

		if (Number(this.auth.getUserCode()) == CONSTANTS.userStatus.deActivated)
			this.router.navigate(['/orders/new']);
	}

	ngOnInit() {
		this.titleService.setTitle(`Pippin Title™ - Client - Order (New)`)
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		document.body.style.background = theme.colors.bg.level1;
		this.setProgressBar();
		this.rehydrateState();
		this.getCountyForIndependCity();
		this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
	}

	ngAfterViewInit() {
		this.initPropertyAutoSearch({ shouldFocus: false });
		this.removeFocusListeners = scrollToFocusElements(
			get(this, 'ref.rootNodes.0.parentElement', document.body),
		);
	}

	ngOnDestroy() {
		document.body.style.background = '';
		if (this.timer) clearTimeout(this.timer);
		if (this.removeFocusListeners) this.removeFocusListeners();
	}

	/* -------------------------------------------------------------------------- */

	protected rehydrateState = async () => {
		this.order.reqUserPrepaymentStatus().subscribe(({ prepayment }) => {
			this.paymentIsRequired = prepayment === 1;
			this.setTotalStages();
		});

		this.reqStates().subscribe(states => {
			this.states = states;

			// Patch the order form with the previous state.
			if (this.order.propertyData) {
				this.forms.order.patchValue(objFilterNil(this.order.propertyData));
				// Patch the search criteria form with the previous state.
				if (this.order.searchCriteria === 1) {
					this.forms.criteria.fullAddress.patchValue(
						objFilterNil(this.order.propertyData),
					);
				}
				if (this.order.searchCriteria === 2) {
					this.forms.criteria.borrower.patchValue(objFilterNil(this.order.propertyData));
				}
				if (this.order.searchCriteria === 3) {
					this.forms.criteria.parcel.patchValue(objFilterNil(this.order.propertyData));
				}
				this.isRushOrder = this.order.propertyData.Rush_Order;
				this.requestedTime = this.order.propertyData.Order_Requested_Time;
			}
		});

		this.reqUploadProgress();

		this.reqProducts().subscribe(products => {
			this.products = products;
			this.productsCustom = products.filter(
				({ Product_Default_Flag }) => Product_Default_Flag === 0,
			);
			this.productsDefault = products.filter(
				({ Product_Default_Flag }) => Product_Default_Flag === 1,
			);

			if(this.auth.getUserOrgId()) {			
				this.getOrganizationDefaultProduct().subscribe(prodId => {
					if(prodId) {
						let isDefaultProd = this.products.findIndex((product) => (product.Product_ID == prodId) );
						if(isDefaultProd > -1) {
							this.forms.order.controls.Order_Type.setValue(prodId);
						}
					}

					this.productName = get(
						products.find(
							({ Product_ID }) => Product_ID === this.forms.order.controls.Order_Type.value,
						),
						'Product_Description',
			  		);
		
					this.productDescription = get(
								products.find(
									({ Product_ID }) => Product_ID === this.forms.order.controls.Order_Type.value,
								),
								'Product_Name',
					);
		
					this.reqProductRequirements(this.forms.order.controls.Order_Type.value).subscribe(
						requirements => {
							this.productRequirements = requirements;
							this.cdr.detectChanges();
	
							requestAnimationFrame(() => {
								window.scrollTo(0, 0);
								get(
									Array.from(
										document.querySelectorAll('.mat-radio-button.mat-radio-checked'),
									),
									'0.focus',
								);
							});
						},
					);
				});
			} else {  
				this.productName = get(
					products.find(
						({ Product_ID }) => Product_ID === this.forms.order.controls.Order_Type.value,
					),
					'Product_Description',
		  		);
	
				this.productDescription = get(
							products.find(
								({ Product_ID }) => Product_ID === this.forms.order.controls.Order_Type.value,
							),
							'Product_Name',
				);
		
				this.reqProductRequirements(this.forms.order.controls.Order_Type.value).subscribe(
					requirements => {
						this.productRequirements = requirements;
						this.cdr.detectChanges();

						requestAnimationFrame(() => {
							window.scrollTo(0, 0);
							get(
								Array.from(
									document.querySelectorAll('.mat-radio-button.mat-radio-checked'),
								),
								'0.focus',
							);
						});
					},
				);
		 }
		});
	};

	protected initPropertyAutoSearch = (
		options: { shouldFocus?: boolean } = { shouldFocus: true },
	) => {
		if (this.order.searchMode === 1) {
			if (this.productsDropDown) {
				const autocomplete = new google.maps.places.Autocomplete(
					this.productsDropDown.nativeElement,
					{ types: ['geocode'] },
				);
				autocomplete.addListener('place_changed', () => {
					this.getPlaceDetails(autocomplete.getPlace());
					this.cdr.detectChanges();
				});

				if (options.shouldFocus && this.productsDropDown.nativeElement) {
					this.productsDropDown.nativeElement.focus();
				}
			} else {
				setTimeout(() => this.initPropertyAutoSearch(options), 300);
			}
		}
	};

	/* -------------------------------------------------------------------------- */

	checkForProductDesc() {
		if(this.productDescription && this.productDescription.match(/_/g)) this.productDescription = this.productDescription.replace(/_/g," ");
		return this.productDescription
	}
	
	protected get criteriaForm() {
		if (this.order.searchCriteria === 1) return this.forms.criteria.fullAddress;
		if (this.order.searchCriteria === 2) return this.forms.criteria.borrower;
		if (this.order.searchCriteria === 3) return this.forms.criteria.parcel;
	}

	protected get latitude() {
		return this.order.propertyData.Property_Latitude;
	}
	protected set latitude(value) {
		this.order.propertyData = { ...this.order.propertyData, Property_Latitude: value };
	}

	protected get longitude() {
		return this.order.propertyData.Property_Longitude;
	}
	protected set longitude(value) {
		this.order.propertyData = { ...this.order.propertyData, Property_Longitude: value };
	}

	public get isValid() {
		return (
			this.forms.order.valid &&
			this.criteriaForm.valid &&
			this.order.docs &&
			this.order.docs.every(doc => doc.completed === 100)
		);
	}

	public isCounty = (countyName: string) =>
		countyName.toLowerCase().endsWith(' county') &&
		CONSTANTS.countyArray
			.map(county => county.toLowerCase())
			.includes(countyName.toLowerCase());

	public isSaint = (countyName: string) =>
		countyName.toLowerCase().startsWith('st.') &&
		CONSTANTS.saintArray
			.map(saint => saint.toLowerCase())
			.includes(countyName.toLowerCase());

	public isState = (stateName: string, countryName: string) =>
		countryName === 'US' &&
		this.states.find(
			state => state.State_Short_Name.toLowerCase() === stateName.toLowerCase(),
		);

	public checkForState = (stateName: string) =>
    this.states.find(
        state => state.State_Short_Name.toLowerCase() === stateName.toLowerCase(),
    );

	protected get userId() {
		return this.auth.getUserOrgId() || this.auth.getUserId();
	}

	/* -------------------------------------------------------------------------- */

	protected getPlaceDetails = (place: any) => {
		this.criteriaSearch = '';
		const addrRef = document.getElementById('Property_Address_1').parentElement;
		const cityRef = document.getElementById('Property_City').parentElement;
		const stateRef = document.getElementById('Property_State_Abbr').parentElement;
		const zipCodeRef = document.getElementById('Property_ZipCode').parentElement;
		const searchBoxRef = document.getElementById('search-box').parentElement;

		if (isNil(place.geometry)) {
			this.searchError = true;
			cityRef.classList.add('is-empty');
			stateRef.classList.add('is-empty');
			zipCodeRef.classList.add('is-empty');
			searchBoxRef.classList.add('is-empty');
		} else {
			this.latitude = place.geometry.location.lat();
			this.longitude = place.geometry.location.lng();
			this.searchError = false;
			this.forms.criteria.fullAddress.controls.Property_City.setValue('');
            this.forms.order.controls.Property_County.setValue('');
            this.forms.order.controls.Property_State_Abbr.setValue('');
            this.forms.criteria.fullAddress.controls.Property_ZipCode.setValue('');
            this.forms.criteria.fullAddress.controls.Property_Address_1.setValue('');

			place.address_components.forEach(
				(address: { long_name: string; short_name?: string; types?: string[] }) => {
					const { long_name, short_name, types } = address;

					switch (types[0]) {
						case 'premise':
						case 'street_number':
							addrRef.classList.remove('is-empty');
							searchBoxRef.classList.remove('is-empty');
							this.forms.criteria.fullAddress.controls.Property_Address_1.setValue(
								long_name,
							);
							break;

						case 'locality':
						case 'sublocality':
						case 'sublocality_level_1':
							cityRef.classList.remove('is-empty');
							// if (isNil(this.forms.criteria.fullAddress.controls.Property_City.value))
								this.forms.criteria.fullAddress.controls.Property_City.setValue(
									long_name,
								);
							break;

						case 'administrative_area_level_1':
							if (short_name === 'DC') this.forms.order.controls.Property_County.setValue('WASHINGTON' );
							this.forms.order.controls.Property_State_Abbr.setValue(short_name);
							this.googlePlaceState = short_name;
							break;

						case 'administrative_area_level_2':
							if (this.isSaint(long_name))
								address.long_name = long_name.replace('St.', 'saint');
							if (this.isSaint(long_name) || this.isCounty(long_name))
								address.long_name = long_name;
								if (this.forms.order.controls.Property_County.value != 'DC'){
									this.forms.order.controls.Property_County.setValue( address.long_name.replace(' County', '').toUpperCase(),);
								}
							break;
						case 'postal_code':
							zipCodeRef.classList.remove('is-empty');
							this.forms.criteria.fullAddress.controls.Property_ZipCode.setValue(
								short_name,
							);
							break;

						case 'route':
							searchBoxRef.classList.remove('is-empty');
							this.forms.criteria.fullAddress.controls.Property_Address_1.setValue(
								`${this.forms.criteria.fullAddress.controls.Property_Address_1.value} ${long_name}`,
							);
							break;

						default:
							break;
					}
				},
			);
		}
		if(this.checkForState(this.forms.order.controls.Property_State_Abbr.value)){
            if(!this.forms.order.controls.Property_County.value) {
				this.checkIfCityIsIndependent();
				this.showCityInCounty = true;
			} else this.showCityInCounty = false;
        }
	};

	protected setProgressBar = () => {
		this.progressBarService.setBarType(1);
		this.progressBarService.setHeight(2);
		this.progressBarService.setPrimaryColor(this.primaryColor);
		this.progressBarService.setProgressState(
			(0 / (this.paymentIsRequired ? 3 : 2)) * 100,
		);
		this.progressBarService.setStage(0);
	};

	private setTotalStages = () => {
		this.progressBarService.setTotalStages(this.paymentIsRequired ? 3 : 2);
	};

	/* -------------------------------------------------------------------------- */

	public handleChangeMortgageDate = (event: MatSelectChange) => {
		this.errors = DATE_REGEX.test(event.value)
			? { ...this.errors, Order_Mortgage_Date: true }
			: {};
	};
	public handleChangeRequestedDate = (event: MatSelectChange) => {
		this.errors = DATE_REGEX.test(event.value)
			? { ...this.errors, Order_Requested_Date: true }
			: {};
	};
	public handleChangeDueDate = (event: MatSelectChange) => {
		this.errors = DATE_REGEX.test(event.value)
			? { ...this.errors, Order_Estimated_Time: true }
			: {};
	};

	public handleChangeProduct = async (event: MatRadioChange) => {
		const product = this.products.find(p => p.Product_ID === event.value);
    this.productName = product.Product_Description;
    this.productDescription = product.Product_Name
		this.reqProductRequirements(event.value).subscribe(requirements => {
			this.productRequirements = requirements;
		});
		if(event.value === this.config.getNameSearchProductId()) this.order.searchCriteria = 2;
		else {
			if(this.borrowerAdressObject) this.borrowerAdressObject = {};
		}
	};

	public handleChangeSearchCriteria = () => {
		this.forms.order.controls.Property_County.reset();
		this.forms.order.controls.Property_State_Abbr.reset();
		if (this.order.searchCriteria == 1 || this.order.searchCriteria == 3) {
			if(this.order.searchCriteria == 1) this.handleChangeSearchMode();
			this.forms.criteria.fullAddress.controls.Order_Buyer.reset();
			this.forms.criteria.fullAddress.controls.Order_Buyer.clearValidators();
			this.forms.criteria.fullAddress.controls.Order_Buyer.updateValueAndValidity();
		} else {
			this.forms.criteria.fullAddress.controls.Order_Buyer.setValidators([
				compose([required, maxLength(127)]),
			]);
			this.forms.criteria.fullAddress.controls.Order_Buyer.updateValueAndValidity();
		}
		this.showCityInCounty = false;
	};

	public handleChangeSearchMode = () => {
		this.forms.criteria.fullAddress.controls.Property_Address_1.reset();
		this.forms.criteria.fullAddress.controls.Property_Address_2.reset();
		this.forms.criteria.fullAddress.controls.Property_City.reset();
		this.forms.order.controls.Property_County.reset();
		this.forms.order.controls.Property_State_Abbr.reset();
		this.forms.criteria.fullAddress.controls.Property_ZipCode.reset();

		if (this.order.searchMode === 1) {
			this.initPropertyAutoSearch();
		}
	};

	public handleChangeState = (state: string) => {
		this.forms.order.controls.Property_State_Abbr.setValue(state)
		return state && this.reqCounties(state)
	};

	public handleChangeCounty=(county:string)=>{
		this.forms.order.controls.Property_County.setValue(county)
	}

	public handleClickUploadDocument = (event: MouseEvent) => {
		event.preventDefault();
		this.uploadFileInput.nativeElement.value = null;
		this.uploadFileInput.nativeElement.click();
	};

	// Prevent events from being triggered on action buttons.
	public handleSearchProperty = (event: KeyboardEvent) => {
		if (event.key === 'Enter') event.preventDefault();
	};

	protected getFilename = (filePath: string) => filePath.split('/').pop();

	protected isValidUploadNames = (files: File[]) =>
		files.every(file => !file.name.includes(','));

	protected isValidUploadSize = (files: File[]) =>
		files.every(file => file.size < this.config.getMaxFileSize() * 1024 * 1024);

	protected uploadToS3AndUpdatePath = async (file: File, docId: string) => {
		try {
			const data = await this.awsS3.onManagedUpload(file, '0');
			const path = data.Key;
			const name = this.getFilename(path);

			try {
				const doc = await this.awsS3.updateDocumentPath('0', docId, file, path, name);
				return { ...doc, docId, completed: 100 };
			} catch (error) {
				throw new Error(error);
			}
		} catch (error) {
			this.openDialogError(CONSTANTS.apiErrors.multiupload);
		}
	};

	public handleUploadDocument = async (event: Event) => {
		event.preventDefault();
		const files = Array.from((<HTMLInputElement>event.target).files);

		if (!this.isValidUploadSize(files)) {
			this.openDialogError(CONSTANTS.apiErrors.docuploadsize);
		} else if (!this.isValidUploadNames(files)) {
			this.openDialogError(CONSTANTS.apiErrors.validateFile);
		} else {
			files.forEach(async file => {
				const docId = UUID.UUID();
				this.order.docs = [...this.order.docs, { docId, name: file.name, completed: 0 }];

				try {
					const docIdx = this.order.docs.findIndex(doc => doc.docId === docId);
					const docRes = await this.uploadToS3AndUpdatePath(file, docId);

					if (isNotEmptyObj(docRes)) {
						if (docIdx > -1)
							this.order.docs[docIdx] = { ...this.order.docs[docIdx], ...docRes };
					} else {
						if (docIdx > -1) this.order.docs.splice(docIdx, 1);
					}
				} catch (error) {
					this.openDialogError(CONSTANTS.apiErrors.multiupload);
				}
			});

			this.cdr.detectChanges();
			if (this.uploadRef) {
				const scrollIntoView = get(this, 'uploadRef.nativeElement.scrollIntoView');
				if (scrollIntoView) scrollIntoView({ block: 'center' });
			}
		}
	};

	public handleDeleteFile = (doc: {
		Document_Original_Name: string;
		Order_Document_ID: string;
		docId: string;
	}) =>{

		let dialogRef= this.matDialog.open(ConfirmComponent,{
            data:{
				message: `Are you sure you want to delete ${doc.Document_Original_Name}?`,
				title: 'Delete Document',
            }
            })
          
            dialogRef.afterClosed().subscribe((res)=>res &&
						this.fileService.delete(doc.Order_Document_ID, this.auth.getUserId()).subscribe(
							() =>
								(this.order.docs = this.order.docs.filter(
									({ docId }) => docId !== doc.docId,
								)),
							() => this.openDialogError(CONSTANTS.apiErrors.docdelete),
						),
					)
		
		// this.dialogService
		// 	.addDialog(ConfirmComponent, {
		// 		message: `Are you sure you want to delete ${doc.Document_Original_Name}?`,
		// 		title: 'Delete Document',
		// 	})
		// 	.subscribe(
		// 		res =>
		// 			res &&
		// 			this.fileService.delete(doc.Order_Document_ID, this.auth.getUserId()).subscribe(
		// 				() =>
		// 					(this.order.docs = this.order.docs.filter(
		// 						({ docId }) => docId !== doc.docId,
		// 					)),
		// 				() => this.openDialogError(CONSTANTS.apiErrors.docdelete),
		// 			),
		// 	);
		}

	/* -------------------------------------------------------------------------- */

	protected reqCounties = (state?: string) => {

		this.pricingService
			.getCountyList(state || this.forms.order.controls.Property_State_Abbr.value)
			.subscribe(counties => {
				this.counties = counties;

				if (counties.includes(this.availableCounty)) {
					this.forms.order.controls.Property_County.setValue(this.availableCounty);
				}
				this.initPropertyAutoSearch();
			});
	};

	protected getCountyForIndependCity = (state?: string) => {
		this.pricingService.getIndpendentCities()
		.subscribe((cities) => {
			this.allIndepdentCities = cities;
		})
	};

	protected getOrganizationDefaultProduct = (state?: string) =>
		this.orderListService.getOrganizationDefaultProduct(this.auth.getUserOrgId())

	getAmountFormat(e) {
		
		if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
		if ((this.mortgageInput.length == 0 && e.key == "0")) e.preventDefault();
		else {
			if ((e.keyCode == 8 || e.keyCode == 46) && (this.mortgageInput.length > 0 || this.mortgageInput.length < this.config.getAmountMaxLength())) {
				if (e.target.selectionStart === 0 && e.target.selectionEnd === this.mortgageInput.length) {
					// If the entire input is selected, clear the input
					this.mortgageInput = '';
				} else {
					// Remove the selected portion or the last character
					const start = e.target.selectionStart;
					const end = e.target.selectionEnd;
					if(start==end){
						this.mortgageInput = this.mortgageInput.slice(0, this.mortgageInput.length - 1);
					}else{
						this.mortgageInput = this.mortgageInput.slice(0, start) + this.mortgageInput.slice(end);
					}
					
				
				}
				e.target.value = this.numberFormat(this.mortgageInput);
				this.forms.order.controls.Order_Mortgage_Amount.setValue(e.target.value);
			} 
		  else if (this.mortgageInput.length < this.config.getAmountMaxLength()) {
			var key = this.keyValue(e.keyCode);
			if (key) {
			  this.mortgageInput = this.mortgageInput + key;
			  e.target.value = this.numberFormat(this.mortgageInput);
			this.forms.order.controls.Order_Mortgage_Amount.setValue(e.target.value);
			}
		  }
		
		  return false;
		}
	  }

	  getLoanFormat(e) {
		
		if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
		if ((this.loanInput.length == 0 && e.key == "0")) e.preventDefault();
		else {
			if ((e.keyCode == 8 || e.keyCode == 46) && (this.loanInput.length > 0 || this.loanInput.length < this.config.getAmountMaxLength())) {
				if (e.target.selectionStart === 0 && e.target.selectionEnd === this.loanInput.length) {
					// If the entire input is selected, clear the input
					this.loanInput = '';
				} else {
					// Remove the selected portion or the last character
					const start = e.target.selectionStart;
					const end = e.target.selectionEnd;
					if(start==end){
						this.loanInput = this.loanInput.slice(0, this.loanInput.length - 1);
					}else{
						this.loanInput = this.loanInput.slice(0, start) + this.loanInput.slice(end);
					}
					
				
				}
				e.target.value = this.numberFormat(this.loanInput);
				this.forms.criteria.parcel.controls.Order_Loan.setValue(e.target.value);
			} 
		  else if (this.loanInput.length < this.config.getAmountMaxLength()) {
			var key = this.keyValue(e.keyCode);
			if (key) {
			  this.loanInput = this.loanInput + key;
			  e.target.value = this.numberFormat(this.loanInput);
			this.forms.criteria.parcel.controls.Order_Loan.setValue(e.target.value);
			}
		  }

		
		  return false;
		}
	  }

	  numberFormat(val) {
		if (isNaN(parseFloat(val))) return null;
		var num = parseFloat(val);
		return (num / 100).toFixed(2);
	  }

	  keyValue(keyCode) {
		if (keyCode > 57) keyCode -= 48;
		if (keyCode >= 48 && keyCode <= 57)
		  return String.fromCharCode(keyCode);
	  }

	checkIfCityIsIndependent() {
	let findCityIndex = this.allIndepdentCities.findIndex((city) => {
		if(city) return this.forms.criteria.fullAddress.controls.Property_City.value.toLowerCase() === city.System_Independent_City.toLowerCase()
		else return false;
	})
	if(findCityIndex > -1 ) {
		if(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name) this.forms.order.controls.Property_County.setValue(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name);
		else this.forms.order.controls.Property_County.setValue(this.forms.criteria.fullAddress.controls.Property_City.value);
	}else this.forms.order.controls.Property_County.setValue(this.forms.criteria.fullAddress.controls.Property_City.value);
	}



	private reqUploadProgress = () =>
		this.awsS3.progress.subscribe((res: awsS3.types.ProgressResponse) => {
			const docIdx = this.order.docs.findIndex(doc => doc.name === res.name);

			if (docIdx > -1) {
				this.order.docs[docIdx].completed =
					Math.round((res.progress.loaded / res.progress.total) * 100 * 100) / 100;
				this.order.docs[docIdx].completed = Math.round(this.order.docs[docIdx].completed);
			}
		});

	protected reqProducts = () =>
		this.orderListService.getOrderProductsForClient(this.userId);

	protected reqProductRequirements = (productId: number) =>
		this.productService.getProductRequirements(productId);

	protected reqStates = () => this.pricingService.getAvailableStateList();

	/* -------------------------------------------------------------------------- */

	public prevStep = () => {
		this.router.navigate(['/orders/current']);
		this.order.resetAll();
	};

	public nextStep = (event?: MouseEvent) => {
		this.auth.getOrgDetails(this.auth.getUserOrgId())
		.subscribe((orgDetails) => {
			let clientType = null;
			if(this.auth.getUserOrgId()) {
				if(orgDetails) {
					if(orgDetails.Organization_Type == 1)  clientType = 4;
					else if(orgDetails.Organization_Type == 4) clientType = 3;
					else if(orgDetails.Organization_Type == 8) clientType = 2;
					else clientType = 7;
				  }
			}
			this.auth.checkIfQualaiUser(this.auth.getUserId())
			.subscribe((data) => {
				this.order.propertyData = {
					...this.forms.order.value,
					...this.criteriaForm.value,
					Property_Products: [this.forms.order.value.Order_Type],
					selectedProduct: this.forms.order.value.Order_Type,
					Product_Name:this.productDescription,
					Product_Description: this.productName,
					independent_City:this.showCityInCounty,
					Rush_Order : this.isRushOrder,
					Order_Estimated_Time:this.dueDateEtaDate,
					Order_Requested_Date: this.requestedDate,
					Order_Requested_Time: this.requestedTime,
					Order_Mortgage_Date:this.mortgageDate,
					Borrower_Address:this.borrowerAdressObject['Borrower_Address'],
					Co_Borrower_Address:this.borrowerAdressObject['Co_Borrower_Address'],
					Order_Source_Type_ID: clientType,
					Order_Mode_Type_ID: 2,
					Qualia_Fee_Add: data.status ? 1: 0,
					Organization_ID : this.auth.getUserOrgId()
				};
				this.order.searchType = this.forms.order.value.Order_Type;
				// Navigate to the next order step
				if (this.paymentIsRequired) {
					this.router.navigate([`/user/${this.auth.getUserId()}/orders/new/step2`]);
				} else {
					this.router.navigate([`/user/${this.auth.getUserId()}/orders/new/step3`]);
				}
			})
		})

		if (event) event.preventDefault();
		// Map local state to the order service.
		// this.order.docs = this.docs;
		
	};

	getAddressData(event){
		if(event.hasOwnProperty('Borrower Present')) {
			this.borrowerAdressObject['Borrower_Address'] = event;
		}else this.borrowerAdressObject['Co_Borrower_Address'] = event;
	}

	checkIfNameSearchProd(){
		if(this.config.getNameSearchProductId() === this.forms.order.controls.Order_Type.value){
			this.clearValidatorsNUpdate('Property_County')
			this.clearValidatorsNUpdate('Property_State_Abbr')
			return true
		}else {
			this.setValidatorsNUpdate('Property_County')
			this.setValidatorsNUpdate('Property_State_Abbr')
			return false
		}
	}

	setValidatorsNUpdate(propertyName) {
		this.forms.order.controls[propertyName].clearValidators();
		this.forms.order.controls[propertyName].setValidators(Validators.required)
		this.forms.order.controls[propertyName].updateValueAndValidity();
	}

	clearValidatorsNUpdate(propertyName) {
		this.forms.order.controls[propertyName].clearValidators();
		this.forms.order.controls[propertyName].updateValueAndValidity();
	}

	checkForNameSearchValidation() {
        if(this.config.getNameSearchProductId() === this.forms.order.controls.Order_Type.value) {
            if(this.borrowerAdressObject && !this.borrowerAdressObject['Borrower_Address']) return false;
            if((this.borrowerAdressObject && this.borrowerAdressObject['Borrower_Address'] && this.borrowerAdressObject['Borrower_Address']['isError'] == true ) || (this.borrowerAdressObject && this.borrowerAdressObject['Co_Borrower_Address'] && this.borrowerAdressObject['Co_Borrower_Address']['isError'] == true)) return false
            else return true;
        } else return true;
    }

	clearForms() {
		this.errors = {};
		if (this.requestedDate) this.orderReqtime.enable();
		else this.orderReqtime.disable();
	  }

	/* -------------------------------------------------------------------------- */
	
	



	
	public openDialog = (message: string) =>{
		let dialogRef= this.matDialog.open(MessageComponent,{
            data:{
				message:message,
				title:this.staticText.get('BTN13')
            }
            })
		
			dialogRef.afterClosed().subscribe((message:string)=>message && this.sendMessage(message))

		// this.dialogService
		// 	.addDialog(MessageComponent, { message, title: this.staticText.get('BTN13') })
		// 	.subscribe((message: string) => message && this.sendMessage(message));
	}
	protected openDialogError = (message: string) =>{

		let dialogRef= this.matDialog.open(ErrorDialogComponent,{
            data:{
				message:message
            }
            })
          
		// this.dialogService.addDialog(ErrorDialogComponent, { message });
	}

	protected openDialogSuccess = (options?: { title: string; message: string }) =>{

		let dialogRef= this.matDialog.open(SuccessComponent,{
            data:{
				message: get(
							options,
							'message',
							'We have received your request. We will get back to you as soon as possible.',
						),
						title: get(options, 'title', 'Success'),
            }
            })
          
         

		// this.dialogService.addDialog(SuccessComponent, {
		// 	message: get(
		// 		options,
		// 		'message',
		// 		'We have received your request. We will get back to you as soon as possible.',
		// 	),
		// 	title: get(options, 'title', 'Success'),
		// })


	};

	protected sendMessage = (Message_Text: string) =>
		this.order
			.createOrderMessage({ Message_Text, Sender_ID: this.auth.getUserId() })
			.subscribe(
				() => {
					this.openDialogSuccess();
					this.router.navigate(['/orders/new']);
				},
				() => this.openDialogError(CONSTANTS.apiErrors.sendmessage),
			);

	
}
