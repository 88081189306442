import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { theme } from '@styled';
import { addressToObj, Dict, get, pick } from '@utils';
import { AuthService } from '../authentication/auth.service';
import { ConfigService } from '../config/config.service';
import { NewOrderService } from '../new-order/new-order.service';
import * as types from './types';

@Injectable({
	providedIn: 'root',
})
export class StripeService {
	constructor(
		protected auth: AuthService,
		protected conf: ConfigService,
		protected http: HttpClient,
		protected order: NewOrderService,
	) {}

	/**
	 * Required base style to initiate a card element using Stripe.js.
	 * Although this is a base style, most of the styling is inside the scss files of new-order4 and
	 * order-fly
	 */
	public get baseStyle() {
		return {
			base: {
				color: get(theme, 'colors.text.primary'),
				fontFamily: get(theme, 'fonts.body'),
				fontSize: get(theme, 'fontSizes.lg'),
				fontSmoothing: 'subpixel-antialiased',
				'::placeholder': {
					color: 'rgba(0, 0, 0, 0.54)',
				},
			},
			invalid: {
				color: get(theme, 'colors.error.main'),
				iconColor: get(theme, 'colors.error.main'),
			},
		};
	}

	/**
	 * Removes event listeners of the card and destroys it to be able to re create a new one when
	 * needed.
	 * This method must be used on the 'ngOnDestroy() method of the component that uses a card
	 * element.'
	 *
	 * @param card - Card object created using Stripe.js elements
	 * @param cardHandler - Handler of that card. Usually a 'div' element.
	 */
	public destroyCard = (
		card: { removeEventListener: (arg0: string, arg1: any) => void; destroy: () => void },
		cardHandler: any,
	) => {
		if (card) {
			// We remove event listener here to avoid memory leaks
			card.removeEventListener('change', cardHandler);
			// Only one card per application can be created, so if
			// we do not destroy the card here we can not create any other.
			card.destroy();
		}
	};

	/**
	 * Creates a token for the card and throws error or call onSuccess method.
	 */
	public createStripeTokens = async (card: any, billingAddress?: any) => {
		const billingData = billingAddress
			? billingAddress
			: this.getSerializedBillingAddress();

		if (!billingData) {
			throw new Error('Billing data must be present when creating stripe tokens.');
		}

		try {
			const firstToken = await stripe.createToken(card, billingData);
			const secondToken = await stripe.createToken(card, billingData);

			if (!firstToken.error && !secondToken.error) {
				this.order.stripeOrderToken = get(firstToken, 'token.id');
				this.order.stripeIncidentalToken = get(secondToken, 'token.id');

				return [firstToken.token, secondToken.token];
			} else {
				throw firstToken.error ? firstToken.error : secondToken.error;
			}
		} catch (err) {
			throw err;
		}
	};

	/**
	 * Returns serialized form of billingForm.
	 * Serialized form is being sent when creating tokens and billing address is saved to the charge
	 * automatically later.
	 *
	 * @param billingForm - FormGroup.value object of billingForm
	 */
	public getSerializedBillingAddress = (billingForm?: any): any => {
		const billingAddress = billingForm ? billingForm : this.order.billingData;
		const customerFullName: string = this.auth.getName();

		if (billingAddress) {
			return {
				name: customerFullName,
				address_line1: billingAddress['Billing_Street_Address_Line_1'],
				address_line2: billingAddress['Billing_Street_Address_Line_2']
					? billingAddress['Billing_Street_Address_Line_2']
					: '',
				address_city: billingAddress['Billing_City'],
				address_state: billingAddress['Billing_State'],
				address_zip: billingAddress['Billing_Zip'],
				address_country: 'US',
			};
		} else {
			return null;
		}
	};

	/**
	 * Get the stripe account of a user.
	 */
	public getCustomer = () =>
		this.http.get(`${this.conf.baseUrl}/users/client/stripe/customer`, {
			headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
		});

	/**
	 * Retrieve a list of all user saved stripe cards.
	 */
	public getCards = () =>
		this.http.get(`${this.conf.baseUrl}/users/client/stripe/cards`, {
			headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
		});

	/**
	 * Create a new stripe card and save it to user.
	 *
	 * @param tokenId - The token id for the new card.
	 *
	 * NOTE: The easiest way to create a token for a new card is with stripe
	 * form elements the user fills out. The component 'stripe-card-element' can
	 * be used, it handles styling and mounting of the component.
	 *
	 * @example
	 * ```html
	 * <stripe-card-element #cardRef></stripe-card-element>
	 * <button (click)="handleCardTokens()">...</button>
	 * ```
	 * ```ts
	 * export class SomeComponent {
	 *  @ViewChild('cardRef') cardRef: any
	 *
	 *  public handleCardToken = async () => {
	 *    const token = await this.cardRef.createToken()
	 *    this.stripeService.createCard(token.id).subscribe(card =>
	 *      // card successfully added
	 *    )
	 *  }
	 * ```
	 */
	public createCard = (tokenId: string, options: Dict = {}) =>
		this.http.post(
			`${this.conf.baseUrl}/users/client/stripe/cards`,
			{
				...pick(options, [
					'address_city',
					'address_country',
					'address_line1',
					'address_line2',
					'address_state',
					'address_zip',
					'exp_month',
					'exp_year',
					'name',
				]),
				tokenId,
			},
			{ headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }) },
		);

	/**
	 * Update a card source.
	 */
	public updateCard = (cardId: string, options: Dict = {}) =>
		this.http.put(
			`${this.conf.baseUrl}/users/client/stripe/cards/${cardId}`,
			{
				...pick(options, [
					'address_city',
					'address_country',
					'address_line1',
					'address_line2',
					'address_state',
					'address_zip',
					'exp_month',
					'exp_year',
					'name',
				]),
			},
			{ headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }) },
		);

	/**
	 * Delete a saved stripe card.
	 *
	 * @param cardId - The id of the stripe card to be deleted.
	 */
	public deleteCard = (cardId: string): any =>
		this.http.delete(`${this.conf.baseUrl}/users/client/stripe/cards/${cardId}`, {
			headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.token}` }),
		});

	/**
	 * Create a stripe token for a credit card.
	 *
	 * @param card - the credit card object.
	 * @param billingAddress - the billing address for the card.
	 *
	 * @todo when billingAddress is available as a service, if it is not passed
	 * query the service.
	 */
	public createToken = async (card: types.StripeCard, billingAddress?: object) => {
		try {
			const address = billingAddress;
			const { token, error } = await stripe.createToken(card, addressToObj(address));

			if (error) {
				throw new Error(error);
			}

			return token;
		} catch (error) {
			return error;
		}
	};

	public getCardImage = (brand: string) => this.getCardImageByBrand(brand);
	public getCardImageByBrand = (brand: string) => {
		switch (brand) {
			case 'American Express':
				return 'amex';
			case 'Visa':
				return 'visa';
			case 'Diners Club':
				return 'diners';
			case 'Discover':
				return 'discover';
			case 'JCB':
				return 'jcb';
			case 'MasterCard':
				return 'mastercard';
			case 'UnionPay':
				return 'unionpay';
			default:
				return 'credit-card';
		}
	};

	/**
	 * Takes two tokens and closes the dialog with two tokens as result.
	 *
	 * @param token - Token object from stripe
	 * @param secondToken - Token object from stripe
	 */
	protected onSuccess = (token: string, secondToken: string) => {
		this.order.stripeOrderToken = token;
		this.order.stripeIncidentalToken = secondToken;
	};
}
