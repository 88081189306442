export const props = [
	'sx',
	'variant',
	'css',
	'styles',
	'_hover',
	'_active',
	'_focus',
	'_focusWithin',
	'_focusVisible',
	'_disabled',
	'_readOnly',
	'_before',
	'_after',
	'_empty',
	'_expanded',
	'_checked',
	'_grabbed',
	'_pressed',
	'_invalid',
	'_valid',
	'_loading',
	'_selected',
	'_hidden',
	'_autofill',
	'_even',
	'_odd',
	'_first',
	'_last',
	'_notFirst',
	'_notLast',
	'_visited',
	'_activeLink',
	'_indeterminate',
	'_groupHover',
	'_groupFocus',
	'_groupActive',
	'_groupDisabled',
	'_groupInvalid',
	'_groupChecked',
	'_placeholder',
	'_fullScreen',
	'_selection',
	'alignContent',
	'alignItems',
	'alignSelf',
	'animation',
	'appearance',
	'background',
	'backgroundAttachment',
	'backgroundBlendMode',
	'backgroundClip',
	'backgroundColor',
	'backgroundImage',
	'backgroundPosition',
	'backgroundRepeat',
	'backgroundSize',
	'bg',
	'bgAttachment',
	'bgBlendMode',
	'bgClip',
	'bgColor',
	'bgImage',
	'bgImg',
	'bgPos',
	'bgPosition',
	'bgRepeat',
	'bgSize',
	'blockSize',
	'border',
	'borderBlock',
	'borderBlockEnd',
	'borderBlockEndStyle',
	'borderBlockEndWidth',
	'borderBlockStart',
	'borderBlockStartStyle',
	'borderBlockStartWidth',
	'borderBlockStyle',
	'borderBlockWidth',
	'borderBottom',
	'borderBottomColor',
	'borderBottomLeftRadius',
	'borderBottomRightRadius',
	'borderBottomStyle',
	'borderBottomWidth',
	'borderColor',
	'borderEndEndRadius',
	'borderEndStartRadius',
	'borderInline',
	'borderInlineEnd',
	'borderInlineEndStyle',
	'borderInlineEndWidth',
	'borderInlineStart',
	'borderInlineStartStyle',
	'borderInlineStartWidth',
	'borderInlineStyle',
	'borderInlineWidth',
	'borderLeft',
	'borderLeftColor',
	'borderLeftStyle',
	'borderLeftWidth',
	'borderRadius',
	'borderRight',
	'borderRightColor',
	'borderRightStyle',
	'borderRightWidth',
	'borderStartEndRadius',
	'borderStartStartRadius',
	'borderStyle',
	'borderTop',
	'borderTopColor',
	'borderTopLeftRadius',
	'borderTopRightRadius',
	'borderTopStyle',
	'borderTopWidth',
	'borderWidth',
	'bottom',
	'boxOrient',
	'boxShadow',
	'box',
	'boxSize',
	'boxSizing',
	'caretColor',
	'clip',
	'color',
	'columnGap',
	'columnRuleColor',
	'cursor',
	'd',
	'direction',
	'display',
	'fill',
	'flex',
	'flexBasis',
	'flexDirection',
	'flexGrow',
	'flexShrink',
	'flexWrap',
	'float',
	'font',
	'fontFamily',
	'fontSize',
	'fontStyle',
	'fontWeight',
	'gridArea',
	'gridAutoColumns',
	'gridAutoFlow',
	'gridAutoRows',
	'gridColumn',
	'gridColumnEnd',
	'gridColumnGap',
	'gridColumnStart',
	'gridGap',
	'gridRow',
	'gridRowGap',
	'gridTemplateAreas',
	'gridTemplateColumns',
	'gridTemplateRows',
	'h',
	'height',
	'inlineSize',
	'inset',
	'insetBlock',
	'insetBlockEnd',
	'insetBlockStart',
	'insetInline',
	'insetInlineEnd',
	'insetInlineStart',
	'justify',
	'justifyContent',
	'justifyItems',
	'justifySelf',
	'left',
	'letterSpacing',
	'lineClamp',
	'lineHeight',
	'listStyleImage',
	'listStyleImg',
	'listStylePos',
	'listStylePosition',
	'listStyleType',
	'm',
	'margin',
	'marginBlock',
	'marginBlockEnd',
	'marginBlockStart',
	'marginBottom',
	'marginInline',
	'marginInlineEnd',
	'marginInlineStart',
	'marginLeft',
	'marginRight',
	'marginTop',
	'marginX',
	'marginY',
	'maxBlockSize',
	'maxH',
	'maxHeight',
	'maxInlineSize',
	'maxW',
	'maxWidth',
	'mb',
	'minBlockSize',
	'minH',
	'minHeight',
	'minInlineSize',
	'minW',
	'minWidth',
	'ml',
	'mr',
	'mt',
	'mx',
	'my',
	'objectFit',
	'objectPosition',
	'opacity',
	'order',
	'outline',
	'outlineColor',
	'overflow',
	'overflowWrap',
	'overflowX',
	'overflowY',
	'p',
	'padding',
	'paddingBlock',
	'paddingBlockEnd',
	'paddingBlockStart',
	'paddingBottom',
	'paddingInline',
	'paddingInlineEnd',
	'paddingInlineStart',
	'paddingLeft',
	'paddingRight',
	'paddingTop',
	'paddingX',
	'paddingY',
	'pb',
	'pl',
	'placeItems',
	'pointerEvents',
	'pos',
	'position',
	'pr',
	'pt',
	'px',
	'py',
	'resize',
	'right',
	'rowGap',
	'stroke',
	'textAlign',
	'textDecor',
	'textDecoration',
	'textIndent',
	'textOverflow',
	'textShadow',
	'textTransform',
	'top',
	'transform',
	'transformOrigin',
	'transition',
	'transitionDuration',
	'transitionProperty',
	'transitionTimingFunction',
	'userSelect',
	'verticalAlign',
	'visibility',
	'w',
	'whiteSpace',
	'width',
	'willChange',
	'wordBreak',
	'zIndex',
];
