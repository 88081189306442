import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, StaticTextService } from '../../services';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isMultiCompany = false
	constructor(
		private router: Router,
		public auth: AuthService,
		public text: StaticTextService,
	) {
this.auth.isMultiCompanyUser$
      .subscribe((state) => this.isMultiCompany = state);
  }

	goToBilling = () => {
		this.router.navigate(['/billing']);
	};

	goToChangePassword = () => {
		this.auth.getUserId();
		this.router.navigate(['user/change-password/' + this.auth.getUserId()]);
	};

	goToProfile = () => {
		this.auth.getUserId();
		this.router.navigate(['user/' + this.auth.getUserId() + '/profile']);
	};

	goToSettings = () => {
		this.router.navigate(['/settings']);
	};

	isMultiCliLogin() {
		// return this.auth.isMultiCliLogin();
    return this.isMultiCompany;
	}

	swithAccount = () => {
		this.router.navigate(['/accounts']);
	};

	logout = () => {
		this.auth.logout();
		this.router.navigate(['/']);
	};

	getOrgName() {
		let orgName = this.auth.getOrgName();
		if(orgName && orgName.trim()) orgName = ' ( ' + orgName + ' )';
        return orgName;
	}
}
