
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { CONSTANTS } from '../../app.constants';
import * as _ from 'underscore';

@Injectable()
export class OrderListingService {
  constants = CONSTANTS
  direct: HttpClient;
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private handler: HttpBackend
  ) { 
    this.direct = new HttpClient(this.handler);
  }

  getOpenOrders(pageNum, sortBy, orderBy, searchStr, data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }

    // let url = this.config.getBasePath() + '/orders/user/' + this.auth.getUserId() + '/openorder';
    let queryType = '/orders/organization/' + this.auth.getUserOrgId();
    if (this.auth.isIndividualUser()) queryType = '/orders/user/' + this.auth.getUserId();
    let url = this.config.getBasePath() + queryType + '/openorder';

    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getBackgroundOpenOrders(pageNum, sortBy, orderBy, searchStr, data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }

    // let url = this.config.getBasePath() + '/orders/user/' + this.auth.getUserId() + '/openorder';
    let queryType = '/orders/organization/' + this.auth.getUserOrgId();
    if (this.auth.isIndividualUser()) queryType = '/orders/user/' + this.auth.getUserId();
    let url = this.config.getBasePath() + queryType + '/openorder';

    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getCancelledOrders(pageNum, sortBy, orderBy, searchStr, data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }

    // let url = this.config.getBasePath() + '/orders/user/' + this.auth.getUserId() + '/status/' + this.constants.orderStatus.cancelled;
    let queryType = '/orders/organization/' + this.auth.getUserOrgId();
    if (this.auth.isIndividualUser()) queryType = '/orders/user/' + this.auth.getUserId();
    let url = this.config.getBasePath() + queryType + '/status/' + this.constants.orderStatus.cancelled;

    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderHistory(pageNum, sortBy, orderBy, searchStr, data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }

    // let url = this.config.getBasePath() + '/orders/user/' + this.auth.getUserId() + '/status/' + this.constants.orderStatus.completed;

    let queryType = '/orders/organization/' + this.auth.getUserOrgId();
    if (this.auth.isIndividualUser()) queryType = '/orders/user/' + this.auth.getUserId();
    let url = this.config.getBasePath() + queryType + '/status/' + this.constants.orderStatus.completed;

    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderStatus() {
    return _.invert(CONSTANTS.orderStatus);
  }

  getOrderDetails(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getUserOrderDetails(orderId) {
    var data = {
      User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId;
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrganizationOrderDetails(orderId) {
    var data = {
      Organization_ID: this.auth.getUserOrgId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/org/' + orderId;
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderNotes(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/notes';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  postDocUploadMsg(orderId) {
    const data = {
			Order_ID: orderId,
			Created_By: this.auth.getUserId(),
		};
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/newdocmsg';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderMessages(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/messages/order/' + orderId + '/message/' + CONSTANTS.messageType.orderMessage;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  cancelOrder(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body' //to get full response
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/user/' + this.auth.getUserId() + '/cancelorder';
    return this.http.put(url, null, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  cancelOrderMessage(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body' //to get full response
    }
    let url = this.config.getBasePath() + '/orders/cancelorder';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  sendMessage(orderId, textModel) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/messages/order/' + orderId + '/sender/' + this.auth.getUserId();
    return this.http.post(url, textModel, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  sendDirectClientMessage(textModel) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/messages/client/' + this.auth.getUserId();
    return this.http.post(url, textModel, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }


  clientConfOrder(orderId) {
    var data = {
      Order_Status: CONSTANTS.orderStatus.clientconf
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body' //to get full response
    }
    // let url= this.config.getBasePath() + '/orders/client/accept';    
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/user/' + this.auth.getUserId();
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderProducts() {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderDocuents(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/getdocument/order/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  download(orderDocId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    return this.http.get(this.config.getBasePath() + '/orders/download/document/' + orderDocId, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error:any) => observableThrowError(error.error)),)
  }

  downloadAll(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    return this.direct.get(this.config.getBasePath() + '/orders/downloadAll/document/' + orderId, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error:any) => observableThrowError(error.error)),)
  }

  getCustomerOrderProducts(orderId) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/orderProducts';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getOrderProductsForClient(userId, deleteCopyCharges=true) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products/defaultflag/'+ userId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        if(deleteCopyCharges) {
          for(var i= 0; i < response.length ; i ++ ){
            if(response[i].Product_Status == 4) response.splice(i, 1);// Default Sub Product Status = 4(Sticky Product);
          }
          for(var i= 0; i < response.length ; i ++ ){
            if(response[i].Product_Status == 2) response.splice(i, 1);// Sub Product Status = 2(Sub Product);
          }
        }
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderTrackerFeature(orderId){
    let access_token: string = this.config.getAccessToken();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Basic ' + access_token })
    }
    let url = this.config.getBasePath()+ '/tracker/' + orderId;
    return this.http.post(url,{ access_token: access_token },httpOptions).pipe(
      map((response: any) =>  response),
      catchError((error: any) => observableThrowError(error.error)));
  }


  createXML(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/searchPackage/' + orderId +'/resware/request';
    return this.http.get(url,httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrganizationDefaultProduct(orgId) {
    var data = {
      Organization_ID: this.auth.getUserOrgId()
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/organizations/default/product/' + orgId;
    return this.http.get(url,httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

}
