import { Directive, HostListener, OnDestroy, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { formatMoney, isNil, toNumberKey } from '@utils';
import { ConfigService } from '../../services';

@Directive({
	selector: '[currencyFormatter]',
})
export class CurrencyFormatterDirective implements OnDestroy {
	private formatter: any;
	private value: string;

	constructor(@Self() private ngControl: NgControl, private config: ConfigService) {
		this.formatter = formatMoney;
		this.value = '';
	}

	ngAfterViewInit() {
		this.setValue(this.ngControl.value);
	}

	ngOnDestroy() {
		this.setValue(this.value);
	}

	@HostListener('keydown', ['$event'])
	onKeydown = (event: { preventDefault?: any; key?: any; keyCode?: any }) => {
		const { key, keyCode } = event;
		if (keyCode !== 48) event.preventDefault();
		if (key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab') return true;

		const len = this.value.length;
		const isMin = len <= 0;
		const isMax = len >= this.config.getAmountMaxLength();
		const numKey = keyCode === 48 ? 0 : toNumberKey(keyCode);
		const isNumKey = !isNil(numKey);
		const isDelKey = key === 'Delete' || key === 'Backspace';
		if(keyCode){
			if (isDelKey && !isMin) this.value = this.value.slice(0, -1);
			if (isNumKey && !isMax) this.value = this.value + numKey;
			this.setValue(this.formatter(this.value || 0));
		}
		return false;
	};

	setValue = (value: string = '') =>
		this.ngControl.control.setValue(value, { emitEvent: false });
}
