import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@utils';

@Pipe({
	name: 'isNil',
})
export class IsNilPipe implements PipeTransform {
	transform(value: any) {
		return isNil(value);
	}
}
