import { InjectionToken } from '@angular/core';

export const STRIPE_PUBLISHABLE_KEY = new InjectionToken<string>(
	'Stripe Publishable Key',
);
export const STRIPE_OPTIONS = new InjectionToken<StripeInstanceOptions>('Stripe Options');

export interface Stripe {
	(string: string, options?: StripeInstanceOptions): StripeInstance;
}

export interface StripeInstance {
	elements: (options?: any) => any;
	createToken: (elements: any, options?: StripeInstanceOptions) => any;
	createSource: (elements: any, options?: StripeInstanceOptions) => any;
	retrieveSource: () => any;
	paymentRequest: () => any;
}

export interface StripeInstanceOptions {
	stripeAccount?: string;
}

export interface StripeCustomer {
	address: {
		city: string;
		country: 'US';
		line1: string;
		line2: string;
		postal_code: string;
		state: string;
	};
	balance: number;
	created: number;
	currency: 'usd';
	default_source: any;
	delinquent: boolean;
	description: string;
	discount: any;
	email: string;
	id: string;
	invoice_prefix: string;
	invoice_settings: {
		custom_fields: any;
		default_payment_method: any;
		footer: any;
	};
	livemode: boolean;
	metadata: any;
	name: string;
	next_invoice_sequence: number;
	object: string;
	phone: any;
	preferred_locales: any[];
	shipping: any;
	sources: {
		data: any[];
		has_more: boolean;
		object: 'list';
		url: string;
	};
	subscriptions: {
		data: any[];
		has_more: boolean;
		object: 'list';
		url: string;
	};
	tax_exempt: any;
	tax_ids: {
		data: any[];
		has_more: boolean;
		object: 'list';
		url: string;
	};
}

export interface StripeCard {
	id: string;
	object: 'card' | string;
	name: string;
	address_city: string;
	address_country: string;
	address_line1: string;
	address_line1_check: string;
	address_line2: string;
	address_state: string;
	address_zip: string;
	address_zip_check: string;
	brand: string;
	country: string;
	cvc_check: string;
	dynamic_last4: string;
	exp_month: number;
	exp_year: number;
	funding: string;
	last4: string;
	metadata: any;
	tokenization_method: any;
}

export interface StripeToken {
	id: string;
	object: string;
	card: StripeCard;
	client_ip: string;
	created: number;
	livemode: boolean;
	type: 'card' | string;
	used: boolean;
}

export interface StripeSource {
	id: string;
	object: string;
	card: StripeCard;
	client_ip: string;
	created: number;
	livemode: boolean;
	status: string;
	type: 'card' | string;
	usage: string;
}

export interface StripeStyle {
	color: string;
	fontFamily: string;
	fontSize: string;
	fontSmoothing: string;
	fontStyle: string;
	fontVariant: string;
	iconColor: string;
	lineHeight: string;
	letterSpacing: string;
	textAlign: string;
	textDecoration: string;
	textShadow: string;
	textTransform: string;
}

export interface StripeStyling {
	base: StripeStyle;
	complete: StripeStyle;
	empty: StripeStyle;
	invalid: StripeStyle;
}

export interface StripeClasses {
	base: string;
	complete: string;
	empty: string;
	focus: string;
	invalid: string;
	webkitAutofill: string;
}

export interface StripeCardOptions {
	classes: StripeClasses;
	hidePostalCode: boolean;
	hideIcon: boolean;
	iconStyle: string;
	placeholder: string;
	style: StripeStyling;
}

export interface StripeCardChangeEvent {
	complete: boolean;
	brand: string;
	elementType: string;
	empty: boolean;
	error: object;
	value: { postalCode: string };
}

export interface BillingAddress {
	address_city: string;
	address_country: string;
	address_line1: string;
	address_line2: string;
	address_state: string;
	address_zip: string;
}
