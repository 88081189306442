import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PricingService, StaticTextService, StripeService } from '@services';
import { getProp } from '@styled';
import * as utils from '@utils';
import {
	addressToObj,
	addressToString,
	ADDRESS_FIELDS,
	compose,
	Dict,
	get,
	isBool,
	isEmptyObj,
	isEmptyString,
	isEq,
	isNil,
	isObj,
	isString,
	merge,
	objFilterEmpty,
	objMap,
	omit,
	ONLY_STRING_REGEX,
	pick,
	__DEV__,
} from '@utils';

@Component({
	selector: 'upload',
	template: `
		<div sx p="8" borderRadius="md" bg="rgb(248, 249, 250)" border="1px solid #e4e4e4">
			<ng-content></ng-content>
		</div>
	`,
})
export class Upload {
	public el: ElementRef;
}

@Component({
	selector: 'upload-item',
	template: `
		<div
			sx
			d="flex"
			alignItems="center"
			[opacity]="value < 100 ? 0.3 : 1"
			transition="opacity 250ms"
		>
			<icon icon="description" sx color="text.hint"> </icon>
			<div sx px="4" flex="1">
				{{ name }}
			</div>
			<button
				*ngIf="!disableActions"
				(click)="handleClick($event)"
				[disabled]="value < 100"
				mat-icon-button
				[disabled]="disabled"
				style="outline: none"
			>
				<icon icon="delete" sx color="text.hint"> </icon>
			</button>
		</div>
		<mat-progress-bar
			*ngIf="value < 100"
			sx
			pl="12"
			mode="determinate"
			value="{{ value }}"
		></mat-progress-bar>
		<mat-divider *ngIf="value === 100 && !isLast" sx ml="12"></mat-divider>
	`,
})
export class UploadItem {
	@Input() disableActions: boolean = false;

	@Input() item: any;

	@Input() value: any;

	@Input() disabled: boolean = false;

	@Input() name: string;

	@Input() isLast: boolean = false;

	@Output() onDelete: EventEmitter<any> = new EventEmitter();

	handleClick = (event: any) => {
		this.onDelete.emit(this.item);
	};

	constructor(public el: ElementRef) {}
}
