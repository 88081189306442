import { Component, Input, OnDestroy } from '@angular/core';
import { ProgressBarService, StaticTextService } from '../../services';
import * as momentTimeZone from 'moment-timezone';
import { CONSTANTS } from '../../app.constants';


@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnDestroy {
	constants=CONSTANTS; 
	@Input() quoteFlag: boolean = false;
	@Input() color: string;
	@Input() trackerFlag: boolean = false;


	constructor(private progressServ: ProgressBarService, public text: StaticTextService) {}

	getProgress() {
		return this.progressServ.getProgressState().toString() + '%';
	}

	getPrimaryColor() {
		return this.progressServ.getPrimaryColor();
	}

	ngOnDestroy() {
		this.progressServ.resetAll();
	}

	getStage() {
		return this.progressServ.getStage();
	}

	getHeight() {
		return this.progressServ.getHeight() + 'px';
	}

	getTotalStages() {
		return this.progressServ.getTotalStages();
	}

	getBarType() {
		return this.progressServ.getBarType();
	}

	getPrepaymentStatus() {
		return this.progressServ.getPrepaymentStatus();
	}
	getTimeline(){
		return this.progressServ.getTimeline();
	  }

	  checkDaylight(date) {
		if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
		else return 'EST'
	  }
}
