import { Component, OnInit } from '@angular/core';
import { AuthService, AboutService } from '../../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from "ng2-bootstrap-modal";
import { NpsSuccessComponent } from '../../dialog/nps-success/nps-success.component';

@Component({
  selector: 'app-user-nps',
  templateUrl: './user-nps.component.html',
  styleUrls: ['./user-nps.component.scss']
})
export class UserNpsComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private aboutService: AboutService,
    private _snackBar: MatSnackBar,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
  }

  updateUserNpsRating(rating, isRated) {
    let obj = {};
    obj['User_ID'] = this.auth.getUserId();
    obj['NPS_Rating_Category'] = 0;       // portal - 0; Email - 1
    if (isRated) {
      obj['NPS_Rating_Acceptance'] = 1;
      obj['NPS_Rating_Value'] = rating;    //Acceptanced - 1, Decliend - 0
    } else {
      obj['NPS_Rating_Acceptance'] = 0;
      obj['NPS_Rating_Value'] = null;
    }
    this.aboutService.updateUserRating(obj)
      .subscribe((data) => {
        if ((rating && (rating == 9 || rating == 10))) this.openDialog();
        this._snackBar.dismiss();
      }, (err) => {
        console.log("Error while updated the user's response.", err)
        this._snackBar.dismiss();
      })
  }

  openDialog() {
    let disposable = this.dialogService.addDialog(NpsSuccessComponent, {
      title: 'Success',
      message: ''
    })
      .subscribe((res) => {
      });
  }

}
