export const styles = {
	h1: {
		m: 0,
		font: 'heading',
		fontSize: '8xl',
		lineHeight: '1.167',
		fontWeight: 'light',
		letterSpacing: '-0.01562em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	h2: {
		m: 0,
		font: 'heading',
		fontSize: '6xl',
		lineHeight: '1.2',
		fontWeight: 'light',
		letterSpacing: '-0.00833em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	h3: {
		m: 0,
		font: 'heading',
		fontSize: '5xl',
		lineHeight: '1.167',
		fontWeight: 'normal',
		letterSpacing: '0em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	h4: {
		m: 0,
		font: 'heading',
		fontSize: '4xl',
		lineHeight: '1.235',
		fontWeight: 'normal',
		letterSpacing: '0.00735em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	h5: {
		m: 0,
		font: 'heading',
		fontSize: '3xl',
		lineHeight: '1.334',
		fontWeight: 'normal',
		letterSpacing: '0em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	h6: {
		m: 0,
		font: 'heading',
		fontSize: '3',
		lineHeight: '1.57',
		fontWeight: 'medium',
		letterSpacing: '0.0075em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	p: {
		m: 0,
		font: 'body',
		fontSize: 'md',
		lineHeight: '1.5',
		fontWeight: 'normal',
		letterSpacing: '0.00938em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	title: {
		m: 0,
		font: 'heading',
		fontSize: '4',
		lineHeight: '1.6',
		fontWeight: 'normal',
		letterSpacing: '0.0075em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	subtitle: {
		m: 0,
		font: 'body',
		fontSize: '3',
		lineHeight: '1.57',
		fontWeight: 'normal',
		letterSpacing: '0.00714em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
	caption: {
		m: 0,
		font: 'body',
		fontSize: '0',
		lineHeight: '1.66',
		fontWeight: 'normal',
		letterSpacing: '0.03333em',
		'-webkit-font-smoothing': 'subpixel-antialiased',
	},
};
