
import {pairwise, filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AuthService, StaticTextService, ConfigService, RoutingStateService} from './services';
import { Router, NavigationEnd, ActivatedRoute, } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app works!';
  constructor(
    private staticText: StaticTextService,
    private config: ConfigService,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private routingState: RoutingStateService
    // private activate:CanActivate,

  ) {
    this.routingState.loadRouting();
  }

  ngOnInit() {
    $.material.init();
    $.material.ripples();
    this.staticText.setApplicationText()
      .subscribe(() => { }, (err) => {
        this.router.navigate(['/408']);
      })
    this.auth.loadEnvData();
    this.auth.isMultiCliLogin();
    this.auth.isOrgSelected();
    // this.auth.fetchVersionDetails();
  }

  loggedoutRoutes = [];
  isSidebarActive() {
    this.loggedoutRoutes = ["/aboutus","/tracker/:orderId", "/contact", "/services", "/login", '/registration/step1', '/registration/1', '/registration/2', '/forget', '/maintenance', '**']
    if (this.router.url.match('/user/new') || this.router.url.match('/termsandconditions/')) {
      return this.config.getSideBarNode();
    }
    if (this.router.url != '/') {
      if(!this.auth.isLoggedIn() && this.router.url.match('tracker')) return;
      if (!this.auth.isLoggedIn() && this.loggedoutRoutes.indexOf(this.router.url) == -1) this.router.navigate(['login']);
      if (this.auth.isLoggedIn() && this.loggedoutRoutes.indexOf(this.router.url) > -1) this.router.navigate(['home']);
      return this.config.getSideBarNode();
    }
  }

}
