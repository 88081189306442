import { Component, OnInit } from '@angular/core';
import { HttpService, AuthService, StaticTextService } from '../../services';
import { ConfigService } from '../../services/config/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  //ftxt: string = '';
  year : any;
  constructor(
    public text: StaticTextService,
    public auth: AuthService,
    private config: ConfigService,
  ) { }

  ngOnInit() {
    //this.ftxt = this.auth.getVersionDetails();
this.year = new Date().getFullYear() 
  }

  getVersion(){
    return this.auth.getVersionDetails();
  }

  getCancelPolicyLink() {
     return 'https://pippintitle.com/cancellationpolicy';
  }

}
