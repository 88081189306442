import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';
import { theme } from '@styled';
import { proxyAttr } from '@utils';

//
// ─────────────────────────────────────────────────────────── CARD COMPONENT ─────
//

@Component({
	selector: 'card',
	template: `
		<div
			pos="relative"
			bg="bg.paper"
			border="{{
				variant === 'elevated' ? '1px solid divider.light' : '1px solid divider.main'
			}}"
			borderRadius="md"
			boxShadow="{{ variant === 'elevated' ? 'xs' : 'none' }}"
			transition="{{ transition }}"
			sx="{
				'& > [card-content]': { 'borderRadius': 'md' }
			}"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class Card implements AfterContentInit {
	/**
	 * The style variation of the `Card`.
	 */
	@Input()
	public get variant(): 'elevated' | 'outlined' {
		return this._variant;
	}
	public set variant(val: 'elevated' | 'outlined') {
		if (['elevated', 'outlined'].includes(val)) {
			this._variant = val;
		}
	}
	protected _variant: 'elevated' | 'outlined' = 'elevated';

	/* ---------------------------------- */

	// Card css animation
	public transition: string = theme.transition('box-shadow');

	/* ---------------------------------- */

	constructor(protected el: ElementRef) {}

	ngAfterContentInit() {
		// proxyAttr(this.el, 'className');
		// proxyAttr(this.el, 'style');
	}
}

//
// ──────────────────────────────────────────────────── CARD HEADER COMPONENT ─────
//

@Component({
	selector: 'card-header',
	template: `
		<div
			sx
			variant="{{ hasDivider ? 'card.header card.hasDivider.header' : 'card.header' }}"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class CardHeader implements AfterContentInit {
	/**
	 * If true the `card-header` will have a light bottom border.
	 */
	@Input() public hasDivider: boolean = false;

	/* ---------------------------------- */

	constructor(protected el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
		proxyAttr(this.el, 'style');
	}
}

//
// ─────────────────────────────────────────────────── CARD CONTENT COMPONENT ─────
//

@Component({
	selector: 'card-content',
	template: `
		<div sx variant="card.content">
			<ng-content></ng-content>
		</div>
	`,
})
export class CardContent implements AfterContentInit {
	constructor(protected el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
		proxyAttr(this.el, 'style');
	}
}

//
// ─────────────────────────────────────────────────── CARD ACTIONS COMPONENT ─────
//

@Component({
	selector: 'card-actions',
	template: `
		<div
			sx
			alignItems="{{ align }}"
			justifyContent="{{ justify }}"
			variant="{{ hasDivider ? 'card.actions card.hasDivider.actions' : 'card.actions' }}"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class CardActions implements AfterContentInit {
	/**
	 * Shorthand flex prop for `alignItems`.
	 */
	@Input() public align: string = 'center';

	/**
	 * Shorthand flex prop for `justifyContent`.
	 */
	@Input() public justify: string;

	/**
	 * If true the `card-header` will have a light top border.
	 */
	@Input() public hasDivider: boolean = false;

	/* ---------------------------------- */

	constructor(protected el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
		proxyAttr(this.el, 'style');
	}
}
