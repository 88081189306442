import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {
	StaticTextService,
	PricingService,
	NewOrderService,
	ConfigService,
	ProgressBarService,
	AuthService,
} from 'app/services';
import { CONSTANTS } from '../../app.constants';
import { Router, ActivatedRoute } from '@angular/router';

declare var google: any;

@Component({
	selector: 'app-request-quote1',
	templateUrl: './request-quote1.component.html',
	styleUrls: ['./request-quote1.component.scss'],
})
export class RequestQuote1Component implements OnInit {
	quoteForm: FormGroup;
	selectedCriteria: any;
	quoteModel: any = {};
	selectedOption: number;
	checkAvlCounty: any;
	errors: any = {};
	fieldError = [];
	states = [];
	counties = [];
	selectedStates = [];
	googlePlaceState = '';
	latitude: any;
	longitude: any;
	timer: any;
	searchError: boolean = false;
	primaryColor = '#0F9D58';
	resetScreenData = true;
	input = '';
	showCityInCounty:boolean = false;
	allIndepdentCities : any;

	constructor(
		private frmBuilder: FormBuilder,
		public text: StaticTextService,
		private pricingService: PricingService,
		private newOrder: NewOrderService,
		private config: ConfigService,
		private ref: ChangeDetectorRef,
		private progressServ: ProgressBarService,
		private router: Router,
		private auth: AuthService,
		private route: ActivatedRoute,
	) {
		this.quoteForm = frmBuilder.group({
			Order_Co_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
			Order_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
			Property_Address_1: [
				null,
				Validators.compose([Validators.required, Validators.maxLength(127)]),
			],
			Property_Address_2: [null, Validators.maxLength(127)],
			Property_City: [
				null,
				Validators.compose([Validators.required, Validators.maxLength(127)]),
			],
			Property_State_Abbr: new FormControl(
				{ value: null, disabled: false },
				Validators.required,
			),
			Property_County: new FormControl(
				{ value: null, disabled: false },
				Validators.required,
			),
			Property_ZipCode: [
				null,
				Validators.compose([Validators.required, Validators.maxLength(11)]),
			],
			Property_First_Name: [null, Validators.maxLength(127)],
			Property_Order_Number: [null, Validators.maxLength(255)],
			searchValue: [null],
			radioBtn: [null],
			Order_Type: [null, Validators.required],
			Order_Parcel: [null, Validators.compose([Validators.maxLength(255)])],
			Order_Loan: [null, Validators.compose([Validators.maxLength(255)])],
			Order_Book: [null, Validators.compose([Validators.maxLength(255)])],
			Order_Page: [null, Validators.compose([Validators.maxLength(255)])],
		});
	}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this.resetQuoteData();
		this.getCountyForIndependCity();
		this.progressServ.setPrimaryColor(this.primaryColor);
		this.progressServ.setHeight(2);
		this.progressServ.setStage(0);
		this.progressServ.setTotalStages(3);
		this.progressServ.setProgressState((0 / 3) * 100);
		this.progressServ.setBarType(1);
		this.pricingService.getStateList().subscribe(states => {
			if (states) {
				var tempStates = [];
				states.forEach(state =>
					tempStates.push({ State_Short_Name: state[0].toUpperCase() }),
				);
				this.states = tempStates;
				this.selectedStates = tempStates;
			}
		});

		this.quoteModel.Property_State_Abbr = '';
		this.quoteModel.orderDocs = [];
		this.quoteModel.orderDocId = [];

		if (this.newOrder.searchType) {
			this.selectedOption = this.newOrder.searchMode;
		} else {
			this.selectedOption = 1;
		}

		this.selectedCriteria = this.newOrder.searchCriteria;

		if (this.newOrder.quoteData) {
			this.quoteModel = Object.assign({}, this.newOrder.quoteData);
			console.log('==============>quoteModel', this.quoteModel);
			this.resetScreenData = false;
			this.latitude = this.quoteModel.Property_Latitude;
			this.longitude = this.quoteModel.Property_Longitude;
			this.ref.detectChanges();
		}

		this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
	}

	resetQuoteData() {
		if (!this.route.snapshot.params['back']) this.newOrder.resetQuoteData();
	}

	clearErrors() {
		this.errors = {};
		this.fieldError = [];
	}

	changeCriteria(){
		if(this.selectedCriteria == 1 || this.selectedCriteria == 3 ){
		  this.quoteForm.controls['Order_Buyer'].clearValidators();
		  this.quoteForm.controls['Order_Buyer'].setValidators([Validators.maxLength(127)])
		  this.quoteForm.controls['Order_Buyer'].updateValueAndValidity();
		}else{
		  this.quoteForm.controls['Order_Buyer'].setValidators([Validators.required, Validators.maxLength(127)])
		  this.quoteForm.controls['Order_Buyer'].updateValueAndValidity();
		}
		this.showCityInCounty = false;
	  }
	
	detectTypeChange(event) {
		this.counties = [];
		this.checkAvlCounty = null;
		this.quoteForm.controls['searchValue'].reset();
		this.quoteForm.controls['Property_Address_1'].reset();
		this.quoteForm.controls['Property_Address_2'].reset();
		this.quoteForm.controls['Property_City'].reset();
		this.quoteForm.controls['Property_State_Abbr'].reset();
		this.quoteForm.controls['Property_County'].reset();
		this.quoteForm.controls['Property_ZipCode'].reset();
	}

	clearValid(control) {
		control.markAsUntouched();
	}

	ngAfterViewInit() {
		let searchBox: any = document.getElementById('search-box');
		let options = {
			types: [
				// return only geocoding results, rather than business results.
				'geocode',
			],
		};
		var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
		console.log('searchBox', searchBox);
		// Add listener to the place changed event
		autocomplete.addListener('place_changed', () => {
			this.getPlaceDetails(autocomplete.getPlace());
		});
	}

	getPlaceDetails(place: any) {
		this.quoteModel.searchValue = '';
		this.clearErrors();

		if (place.geometry == undefined) {
			this.quoteModel.Property_City = '';
			this.quoteModel.Property_State_Abbr = '';
			this.quoteModel.Property_ZipCode = '';
			this.quoteModel.Property_County = '';
			this.checkAvlCounty = '';
			this.searchError = true;
			return;
		} else {
			this.searchError = false;
			this.latitude = place.geometry.location.lat();
			this.longitude = place.geometry.location.lng();
			this.quoteModel.Property_Address_1 = '';
			this.quoteModel.Property_City = '';
			this.quoteModel.Property_State_Abbr = '';
			this.quoteModel.Property_ZipCode = '';
			this.quoteModel.Property_County = '';
			this.checkAvlCounty = '';

			for (let i = place.address_components.length - 1; i >= 0; i--) {
				switch (place.address_components[i].types[0]) {
					case 'premise':
						this.quoteModel.Property_Address_1 =
							place.address_components[i].long_name +
							' ' +
							this.quoteModel.Property_Address_1;
						document
							.getElementById('Property_Address_1')
							.parentElement.classList.remove('is-empty');
						break;
					case 'street_number':
						this.quoteModel.Property_Address_1 =
							place.address_components[i].long_name +
							' ' +
							this.quoteModel.Property_Address_1;
						document
							.getElementById('Property_Address_1')
							.parentElement.classList.remove('is-empty');
						break;
					case 'route':
						this.quoteModel.Property_Address_1 = place.address_components[i].long_name;
						document
							.getElementById('Property_Address_1')
							.parentElement.classList.remove('is-empty');
						break;
					case 'sublocality_level_1':
						if (this.quoteModel.Property_City.length == 0)
							this.quoteModel.Property_City = place.address_components[i].long_name;
						document
							.getElementById('Property_City')
							.parentElement.classList.remove('is-empty');
						break;
					case 'sublocality':
						if (this.quoteModel.Property_City.length == 0)
							this.quoteModel.Property_City = place.address_components[i].long_name;
						document
							.getElementById('Property_City')
							.parentElement.classList.remove('is-empty');
						break;
					case 'locality':
						if (this.quoteModel.Property_City.length == 0)
							this.quoteModel.Property_City = place.address_components[i].long_name;
						document
							.getElementById('Property_City')
							.parentElement.classList.remove('is-empty');
						break;
					case 'administrative_area_level_2': {
						if (this.checkSaint(place.address_components[i].long_name)) this.checkCountyNReplace(place, i)
						else this.checkCountyNReplace(place, i)
						this.checkAvlCounty = place.address_components[i].long_name.toUpperCase();
						break;
					}
					case 'administrative_area_level_1': {
						this.googlePlaceState = place.address_components[i].short_name;
						this.quoteForm.controls['Property_State_Abbr'].setValue(
							place.address_components[i].short_name,
						);
						if (place.address_components[i].short_name == 'DC')
							this.checkAvlCounty = 'WASHINGTON';
						break;
					}
					case 'postal_code':
						this.quoteModel.Property_ZipCode = place.address_components[i].short_name;
						break;
					case 'country':
						this.quoteModel.Country = place.address_components[i].short_name;
						break;
					default:
						break;
				}
			}
			if (this.checkIfState(this.quoteModel.Property_State_Abbr, this.quoteModel.Country)){
                if(!this.checkAvlCounty){
					this.checkIfCityIsIndependent()
                    this.showCityInCounty = true;
                  } else this.showCityInCounty = false;
            }
            else {
                this.quoteModel.Property_State_Abbr = '';
                this.quoteModel.Property_County = '';
                this.quoteModel.Country = '';
                this.errors['State'] = true;
                this.errors['County'] = true;
            }
			this.ref.detectChanges();
		}
	}
	
	checkCountyNReplace(place, i) {
		place.address_components[i].long_name = place.address_components[i].long_name.replace(' County', '');
	}

	checkSaint(county) {
		//console.log("saint check",county);
		var lowecaseCounties = CONSTANTS.saintArray.map(value => value.toLowerCase());
		if (
			county.toLowerCase().startsWith('st.') &&
			lowecaseCounties.indexOf(county.toLowerCase()) == -1
		)
			return true;
		else return false;
	}

	checkIfState(stateName, country) {
		//var states = _.flatten(this.states);
		if (
			country == 'US' &&
			this.states.findIndex(
				state => state.State_Short_Name.toLowerCase() == stateName.toLowerCase(),
			) > -1
		)
			return true;
		else return false;
	}

	clearFormsEvent(event) {
		if (event) this.getCountyList(event);
		if (this.quoteModel.Property_State_Abbr) delete this.errors.State;
	}

	getCountyList(event) {
		if (event) {
			if (this.checkAvlCounty || this.checkAvlCounty == '') {
				if (this.quoteModel.Property_County) this.quoteModel.Property_County = null;
			}
			this.pricingService
				.getCountyList(this.quoteModel.Property_State_Abbr)
				.subscribe(counties => {
					this.counties = counties;
					let avlCountyName = [];
					this.counties.forEach(county =>
						avlCountyName.push(county.County_Name.toUpperCase()),
					);
					if(this.showCityInCounty) this.quoteModel.Property_County = this.checkAvlCounty
                    else {
                        if (avlCountyName.indexOf(this.checkAvlCounty) > -1)
                        this.quoteModel.Property_County = this.checkAvlCounty;
                    }
					this.ngAfterViewInit();
					this.ref.detectChanges();
				});
		}
	}

	clearCountyError() {
		if (this.quoteModel.Property_County) delete this.errors.County;
	}

	detectManualChange() {
		this.quoteModel.Property_County = null;
	}

	getIfErrors() {
		if (Object.keys(this.errors).length) return true;
		else return false;
	}

	validateForm() {
		this.errors = {};
		if (!this.quoteModel.Property_State_Abbr) this.errors['State'] = true;
		if (!this.quoteModel.Property_County) this.errors['County'] = true;
		if (this.selectedCriteria == 1) {
			if (!this.quoteModel.Property_Address_1) this.errors['Property Address'] = true;
			if (!this.quoteModel.Property_City) this.errors['City'] = true;
			if (!this.quoteModel.Property_ZipCode) this.errors['ZipCode'] = true;
		} else if (this.selectedCriteria == 2) {
			if (!this.quoteModel.Order_Buyer) this.errors['Buyer'] = true;
		} else if (this.selectedCriteria == 3) {
			if (!this.quoteModel.Order_Parcel) this.errors['Parcel'] = true;
			if (!this.quoteModel.Order_Loan) this.errors['Loan'] = true;
		}

		if (Object.keys(this.errors).length == 0) this.goToRequestQuoteStep2();
		else {
			this.fieldError = Object.keys(this.errors);
		}
		console.log('errors', this.errors);
	}

	getFormGrpvalidation() {
		var flag = false;
		switch (this.selectedCriteria) {
			case 1:
				if (this.quoteForm.controls['Property_Address_1'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_Address_2'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_City'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_ZipCode'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_State_Abbr'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_County'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_First_Name'].invalid) flag = true;
				break;
			case 2:
				if (this.quoteForm.controls['Order_Buyer'].invalid) flag = true;
				else if (this.quoteForm.controls['Order_Co_Buyer'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_State_Abbr'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_County'].invalid) flag = true;
				break;
			case 3:
				if (this.quoteForm.controls['Order_Book'].invalid) flag = true;
				else if (this.quoteForm.controls['Order_Page'].invalid) flag = true;
				else if (this.quoteForm.controls['Order_Parcel'].invalid) flag = true;
				else if (this.quoteForm.controls['Order_Loan'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_State_Abbr'].invalid) flag = true;
				else if (this.quoteForm.controls['Property_County'].invalid) flag = true;
				break;
			default:
				flag = false;
				break;
		}
		return flag;
	}

	goToRequestQuoteStep2() {
		this.quoteModel.Organization_ID = this.auth.getUserOrgId();
		this.quoteModel.Property_Latitude = this.latitude;
		this.quoteModel.Property_Longitude = this.longitude;
		this.quoteModel.independent_City = this.showCityInCounty;
		this.newOrder.quoteData = this.quoteModel;
		this.newOrder.searchCriteria = this.selectedCriteria;
		this.newOrder.searchMode = this.selectedOption;
		this.router.navigate(['/quote/' + this.auth.getUserId() + '/step2']);
	}

	goBack() {
		this.router.navigate(['/orders/current']);
	}

	clearForms() {
		if (this.resetScreenData) {
			this.errors = {};
			this.counties = [];
			this.checkAvlCounty = null;
			this.quoteForm.controls['Property_First_Name'].reset();
			this.quoteForm.controls['Property_Address_1'].reset();
			this.quoteForm.controls['Property_Address_2'].reset();
			this.quoteForm.controls['Property_City'].reset();
			this.quoteForm.controls['Property_ZipCode'].reset();
			this.quoteForm.controls['Property_State_Abbr'].reset();
			this.quoteForm.controls['Property_County'].reset();
			this.quoteForm.controls['Order_Buyer'].reset();
			this.quoteForm.controls['Order_Co_Buyer'].reset();
			this.quoteForm.controls['Order_Parcel'].reset();
			this.quoteForm.controls['Order_Loan'].reset();
			this.quoteForm.controls['Order_Book'].reset();
			this.quoteForm.controls['Order_Page'].reset();

			// this.newOrderModel.Property_Address_1 = '';
			// this.newOrderModel.Property_State_Abbr = '';
			// this.newOrderModel.Property_County = '';
			// this.newOrderModel.Order_Buyer = '';
			// this.newOrderModel.Order_Co_Buyer = '';
			// this.newOrderModel.Order_Parcel = '';
			// this.newOrderModel.Order_Loan = '';
			// this.newOrderModel.Order_Book = '';
			// this.newOrderModel.Order_Page = '';
			this.fieldError = [];
		} else this.resetScreenData = true;
	}

	getLoanFormat(e, newOrder) {
		if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
		if (this.input.length == 0 && e.key == '0') e.preventDefault();
		else {
			if (
				(e.keyCode == 8 || e.keyCode == 46) &&
				(this.input.length > 0 || this.input.length < this.config.getAmountMaxLength())
			) {
				this.input = this.input.slice(0, this.input.length - 1);
				e.target.value = this.numberFormat(this.input);
				newOrder.Order_Loan = e.target.value;
			} else if (this.input.length < this.config.getAmountMaxLength()) {
				var key = this.keyValue(e.keyCode);
				if (key) {
					this.input = this.input + key;
					e.target.value = this.numberFormat(this.input);
					newOrder.Order_Loan = e.target.value;
				}
			}
			return false;
		}
	}

	numberFormat(val) {
		if (isNaN(parseFloat(val))) return null;
		var num = parseFloat(val);
		return (num / 100).toFixed(2);
	}

	keyValue(keyCode) {
		if (keyCode > 57) keyCode -= 48;
		if (keyCode >= 48 && keyCode <= 57) return String.fromCharCode(keyCode);
	}

	getCountyForIndependCity = (state?: string) => {
		this.pricingService.getIndpendentCities()
		.subscribe((cities) => {
			this.allIndepdentCities = cities;
		})
	};

	checkIfCityIsIndependent() {
	let findCityIndex = this.allIndepdentCities.findIndex((city) => {
		if(city) return this.quoteModel.Property_City.toLowerCase() === city.System_Independent_City.toLowerCase()
		else return false;
	})
	if(findCityIndex > -1 ) {
		if(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name) this.checkAvlCounty = this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name.toUpperCase();
		else this.checkAvlCounty = this.quoteModel.Property_City.toUpperCase();
	}else this.checkAvlCounty = this.quoteModel.Property_City.toUpperCase();
	}
}
