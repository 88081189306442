import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";

@Component({
  selector: 'app-nps-success',
  templateUrl: './nps-success.component.html',
  styleUrls: ['./nps-success.component.scss']
})
export class NpsSuccessComponent extends DialogComponent<any, any>{
  @ViewChild('okBtn') focusedElement: ElementRef;
  title: any;
  message: any;
  timer: any;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
  }

  confirm() {
    this.result = true;
    this.close();
  }

  Close() {
    this.result = false;
    this.close()
  }

}
