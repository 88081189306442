import { Location } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TermsConditionsComponent } from '@dialog/terms-conditions/terms-conditions.component';
import * as newOrderService from '@services/new-order';
import { theme } from '@styled';
import * as utils from '@utils';
import { addressToString, createError, get, __DEV__ } from '@utils';
import { CONSTANTS } from 'app/app.constants';
import { SuccessComponent } from 'app/dialog/success/success.component';
import {
	AboutService,
	AuthService,
	OrderListingService,
	ProductService,
	ProgressBarService,
	StaticTextService,
} from 'app/services';
import { DialogService } from 'ng2-bootstrap-modal';
import { forkJoin } from 'rxjs';
import * as stripe from 'app/services/stripe';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { environment } from 'environments/environment';


@Component({
	selector: 'app-new-order3',
	templateUrl: './new-order3.component.html',
	styleUrls: ['./new-order3.component.scss'],
})
export class NewOrder3Component implements OnInit, AfterViewInit, OnDestroy {
	public ETA: string;

	public paymentIsRequired: boolean = false;
	public depositAmount: number = 0;

	public billingAddress: string;
	public propertyAddress: string;
	public cardDetails: any;
	public mortgageDate: string;

	public searchType: string;
	public productDescription: string;
	public searchCriteria: string;
	public searchRequirements: any;

	public termsAccepted: boolean = false;

	private primaryColor: string = '#0F9D58';

	public borrowerAddress: string;
	public coBorrowerAddress: string;
	errorMsg: string;

	constructor(
		public auth: AuthService,
		public cdr: ChangeDetectorRef,
		public dialog: MatDialog,
		public dialogService: DialogService,
		public location: Location,
		public order: newOrderService.service,
		public orderListService: OrderListingService,
		public productService: ProductService,
		public progressService: ProgressBarService,
		public router: Router,
		public staticText: StaticTextService,
		public aboutService: AboutService,
		public stripeService: stripe.service
	) {
		if (__DEV__) {
			window['utils'] = window['utils'] || utils;
			window['order'] = { ...window['order'], step3: this };
		}
	}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		document.body.style.background = theme.colors.bg.level1;
		this.setProgressBar();
		this.rehydrateState();
	}

	ngAfterViewInit() {
		setTimeout(() => window.scrollTo(0, 0), 300);
	}

	ngOnDestroy() {
		document.body.style.background = '';
	}

	/* -------------------------------------------------------------------------- */

	private rehydrateState = () => {
		if (this.order.propertyData) {
			this.order.reqUserPrepaymentStatus().subscribe(({ prepayment }) => {
				this.paymentIsRequired = prepayment === 1;
				if (prepayment === 1) this.depositAmount = 100;
				this.setTotalStages();
			});
			this.billingAddress = addressToString(this.order.billingData);
			this.cardDetails = this.order.stripeCard;
			if (this.order.searchCriteria === 1) {
				this.propertyAddress = addressToString(this.order.propertyData);
			}
			this.reqProducts().subscribe(products => {
				this.searchType = get(
					products.find(p => p.Product_ID === this.order.searchType),
					'Product_Description',
				);
				this.productDescription = get(
					products.find(p => p.Product_ID === this.order.searchType),
					'Product_Name',
				);
				this.searchCriteria = get(
					products.find(p => p.Product_ID === this.order.searchCriteria),
					'Product_Description',
				);
			});

			this.reqProductRequirements(this.order.searchType).subscribe(requirements => {
				this.searchRequirements = requirements;
			});

			this.reqProductETA(this.order.searchType).subscribe(({ Estimated_Time }) => {
				this.ETA = Estimated_Time;
			});

			this.reqProductPricing().subscribe(pricing => {
				this.order.pricingData = {
					Order_Max_Tat: get(pricing, 'Cost_Max_Tat'),
					Order_Min_Tat: get(pricing, 'Cost_Min_Tat'),
					Order_Final_Price: get(pricing, 'Cost_Amount', 0),
					Order_Final_Price_Tax: get(pricing, 'Cost_Tax_Amount', 0),
				};
			});
		} else {
			this.router.navigate([`/user/${this.auth.getUserId()}/orders/new/step1`]);
		}
	};

	/* -------------------------------------------------------------------------- */

	private get userId() {
		return this.auth.getUserOrgId() || this.auth.getUserId();
	}

	checkForProductDesc() {
		if(this.productDescription && this.productDescription.match(/_/g)) this.productDescription = this.productDescription.replace(/_/g," ");
		return this.productDescription
	}

	private setProgressBar() {
		this.progressService.setPrimaryColor(this.primaryColor);
		this.progressService.setHeight(2);
		this.progressService.setProgressState(100);
		this.progressService.setBarType(1);
	}

	private setTotalStages() {
		if (this.paymentIsRequired) {
			this.progressService.setStage(2);
			this.progressService.setTotalStages(3);
		} else {
			this.progressService.setStage(3);
			this.progressService.setTotalStages(2);
		}
	}

	/* -------------------------------------------------------------------------- */

	private reqProducts = () =>
		this.orderListService.getOrderProductsForClient(this.userId);

	private reqProductETA = (productId: number) =>
		this.productService.getProductEstimatedTime(`${productId}`);

	reqProductPricing(){
		let borrowerAddress = this.order.propertyData.Borrower_Address;
		if(borrowerAddress) {
			this.order.propertyData.Property_State_Abbr = borrowerAddress['Property_State_Abbr'];
			this.order.propertyData.Property_County = borrowerAddress['Property_County'];
		}
		return this.order.reqProductPricing(this.order.propertyData.Property_State_Abbr, this.order.propertyData.Property_County, this.order.searchType)
	}

	private reqProductRequirements = (productId: number) =>
		this.productService.getProductRequirements(productId);

	/* -------------------------------------------------------------------------- */

	public checkout = async () => {
if(this.order.propertyData.Order_Estimated_Time){
	this.order.propertyData.Order_Estimated_Time = moment(this.order.propertyData.Order_Estimated_Time).format('MMM DD, YYYY')
}
if(this.order.propertyData.Order_Requested_Date && this.order.propertyData.Order_Requested_Time){
			var order_Requested_Date =  this.setOrderRequestDate(this.order.propertyData.Order_Requested_Date, this.order.propertyData.Order_Requested_Time)
			var estShift = momentTimeZone.tz(order_Requested_Date, 'America/New_York').isDST() ? 4 : 5;
			var etaInEstTimezone = moment(order_Requested_Date).utcOffset(moment(order_Requested_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
			this.order.propertyData.Order_Requested_Date = etaInEstTimezone;
			}
		this.order.pricingData = {
			...this.order.pricingData,
			Order_Custom_Price: null,
			Order_Status: CONSTANTS.orderStatus.received,
		};
		try {

			const order = await this.order.createOrder().toPromise();

			if (this.order.docs) {
				this.updateOrderDocuments(order);
			}
			this.nextStep(order.Order_ID);
			this.order.resetAll();
		} catch (error) {
			if (error.msg) this.errorMsg = error.msg;
			if (error.code === 'PTOR02') {
				this.openDialog(this.staticText.get('TXT26'), error.msg);
			}
			if (this.order.propertyData.Order_Mortgage_Date) {
				this.order.propertyData.Order_Mortgage_Date = new Date(
					this.order.propertyData.Order_Mortgage_Date,
				);
			}
		}
	};

	private updateOrderDocuments = (data: { Order_ID: string; Property_ID: string }) => {
		forkJoin(
			this.order.docs.map(doc =>
				this.order.updateOrderDocument(
					data.Order_ID,
					data.Property_ID,
					doc.Order_Document_ID,
				),
			),
		).subscribe(
			data => console.log('Updated order documents: ', data),
			error =>
				createError({
					condition: true,
					message: `Error updating order documents: ${error}`,
				}),
		);
	};

	/* -------------------------------------------------------------------------- */

	public prevStep = () => {
		this.router.navigate([`/user/${this.auth.getUserId()}/orders/new/step1`]);
	};

	public nextStep = (orderId: string) => {
		this.router.navigate([`/orders/${orderId}/view`]);
	};

	/* -------------------------------------------------------------------------- */

	public openDialog = (title: string, message: string) =>
		this.dialogService
			.addDialog(SuccessComponent, { message, title })
			.subscribe(() => this.router.navigate(['/']));

	public showTermsPopup = () => {
		this.aboutService.getActiveEULA().subscribe(eula => {
			this.dialogService
				.addDialog(TermsConditionsComponent, { eulaData: eula })
				.subscribe(() => {});
		});
	};

	public getCardImageByBrand(brand: string) {
		return this.stripeService.getCardImageByBrand(brand);
	}

	public setOrderRequestDate(RequestDate,requested_Time){
		let getOrderEtaHours = requested_Time.split(":")[0];
		let getOrderEtaMinutes = requested_Time.split(":")[1];
		return RequestDate.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
	  }
}
