import { Component, Input } from '@angular/core';
import { Address, addressToObj, Dict, isEq } from '@utils';

@Component({
	selector: 'address-readonly',
	template: `
		<!-- loading -->
		<skeleton-text *ngIf="isLoading" noOfLines="4" sx w="40"></skeleton-text>

		<!-- loaded ‣ undefined fields -->
		<flex
			*ngIf="!isLoading && !fields"
			align="center"
			justify="center"
			direction="column"
		>
			<icon colorScheme="grey.200" icon="not_listed_location" size="4rem"></icon>
			<p sx variant="subtitle" color="text.hint">No address has been entered.</p>
		</flex>

		<!-- loaded -->
		<v-stack *ngIf="!isLoading && fields" sx spacing="0" color="text.secondary">
			<p *ngIf="fields?.name" sx variant="h6">
				{{ fields.name }}
			</p>
			<p *ngIf="fields?.addressLine1" sx variant="p">
				{{ fields.addressLine1 }}
			</p>
			<p *ngIf="fields?.addressLine2" sx variant="p">
				{{ fields.addressLine2 }}
			</p>
			<p *ngIf="fields?.city && fields?.state && fields?.zip" sx variant="p">
				{{ fields.city }}, {{ fields.state }} {{ fields.zip }}
			</p>
			<p *ngIf="fields | isNotEmpty" sx variant="p">United States</p>
		</v-stack>
	`,
})
export class AddressReadonly {
	/**
	 * If true render the content skeleton.
	 */
	@Input() isLoading: boolean = false;

	/**
	 * The address fields to render.
	 */
	@Input()
	get fields(): Address | Dict {
		return this._fields;
	}
	set fields(value: Address | Dict) {
		const fields = addressToObj(value);
		if (!isEq(fields, this._fields)) this._fields = fields;
	}
	_fields: Address | Dict = {};

	/* ---------------------------------- */

	constructor() {}
}
