import {
	AfterContentInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
} from '@angular/core';
import { getProp } from '@styled';
import { proxyAttr, toArray } from '@utils';

//
// ──────────────────────────────────────────────────────────────────── TYPES ─────
//

type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse';

type Directions = Direction | Direction[];

//
// ────────────────────────────────────────────────────────────────── HELPERS ─────
//

const isDir = (dir: Direction) =>
	['row', 'column', 'row-reverse', 'column-reverse', null].includes(dir);

const mapSpace = (spacing: any, isLast: boolean = false) => (dir: Direction) => {
	switch (dir) {
		case 'column':
			return { mb: isLast ? 0 : spacing };
		case 'row':
			return { mr: isLast ? 0 : spacing };
		case 'column-reverse':
			return { mt: isLast ? 0 : spacing };
		case 'row-reverse':
			return { ml: isLast ? 0 : spacing };
		default:
			return null;
	}
};

//
// ────────────────────────────────────────────────────────── STACK COMPONENT ─────
//

@Component({
	selector: 'stack',
	template: `
		<div
			sx
			d="{{ isInline ? 'inline-flex' : 'flex' }}"
			alignItems="{{ align }}"
			flexDirection="{{ direction }}"
			flexWrap="{{ wrap }}"
			justifyContent="{{ justify }}"
			[css]="styles"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class Stack implements AfterContentInit {
	/**
	 * Shorthand prop for `display: inline-flex`.
	 */
	@Input() public isInline: boolean = false;

	/**
	 * Shorthand flex prop for `alignItems`.
	 */
	@Input() public align: any;

	/**
	 * Shorthand flex prop for `justifyContent`.
	 */
	@Input() public justify: any;

	/**
	 * Shorthand flex prop for `flexWrap`.
	 */
	@Input() public wrap: any;

	/**
	 * Shorthand flex prop for `flexDirection`.
	 */
	@Input()
	public get direction(): Directions {
		return this._direction;
	}
	public set direction(val: Directions) {
		if (toArray(getProp(val)).every(isDir)) this._direction = val;
	}
	private _direction: Directions = 'column';

	/**
	 * Vertical or horizontal (depending on direction) space
	 * between child elements.
	 */
	@Input()
	public get spacing() {
		return this._spacing ? this._spacing : 2;
	}
	public set spacing(val: any) {
		this._spacing = val;
		this.cdr.detectChanges();
	}
	private _spacing: any;

	/* ---------------------------------- */

	// Styles for spacing based on `direction`.
	public get styles() {
		return JSON.stringify({
			'& > *:not(:last-child)': toArray(getProp(this.direction)).map(
				mapSpace(getProp(this.spacing)),
			),
		});
	}

	/* ---------------------------------- */

	constructor(protected cdr: ChangeDetectorRef, protected el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
		proxyAttr(this.el, 'style');
	}
}

//
// ───────────────────────────────────────────────────────── H-STACK COMPONENT ────
//

@Component({
	selector: 'h-stack',
	template: `
		<div
			sx
			d="{{ isInline ? 'inline-flex' : 'flex' }}"
			alignItems="{{ align }}"
			flexDirection="{{ direction }}"
			flexWrap="{{ wrap }}"
			justifyContent="{{ justify }}"
			[css]="styles"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class HStack extends Stack {
	/**
	 * Hardcoded `direction` prop to ensure horizontal stacking.
	 */
	public get direction(): Directions {
		return 'row';
	}
}

//
// ───────────────────────────────────────────────────────── V-STACK COMPONENT ────
//

@Component({
	selector: 'v-stack',
	template: `
		<div
			sx
			d="{{ isInline ? 'inline-flex' : 'flex' }}"
			alignItems="{{ align }}"
			flexDirection="{{ direction }}"
			flexWrap="{{ wrap }}"
			justifyContent="{{ justify }}"
			[css]="styles"
		>
			<ng-content></ng-content>
		</div>
	`,
})
export class VStack extends Stack {
	/**
	 * Hardcoded `direction` prop to ensure vertical stacking.
	 */
	public get direction(): Directions {
		return 'column';
	}
}
