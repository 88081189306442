import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services';


@Component({
  selector: 'app-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.scss']
})
export class CancellationPolicyComponent implements OnInit {
  timer: any;

  constructor(
    private config: ConfigService,
  ) { }

  ngOnInit() {
    this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

}
