const baseStyle = {
	outline: 'none !important',
	'& > *': {
		d: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
	'mat-spinner': {
		my: '6px',
		circle: {
			stroke: 'currentColor',
		},
	},
};

export const button = {
	contained: { ...baseStyle },
	outlined: { ...baseStyle },
	text: { ...baseStyle },
	icon: {
		outline: 'none !important',
	},
	contentIsSpaced: {
		'& > *': {
			d: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			'& > *:not(:last-child)': {
				mr: 2,
			},
		},
	},
	contrast: {
		spinner: {
			circle: {
				stroke: 'text.contrast.primary',
			},
		},
	},
	primary: {
		spinner: {
			circle: {
				stroke: 'primary.main',
			},
		},
	},
	secondary: {
		spinner: {
			circle: {
				stroke: 'secondary.main',
			},
		},
	},
};
