import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';
import { css } from '@styled';
import { isNum, proxyAttr, toNum } from '@utils';
import { keyframes } from 'emotion';

//
// ─────────────────────────────────────────────────────── SKELETON COMPONENT ─────
//

@Component({
	selector: 'skeleton',
	template: `
		<div
			sx
			boxSizing="content-box"
			h="8"
			d="flex"
			[_before]="{
				content: ' ',
				pos: 'relative',
				h: '100%',
				w: '100%',
				color: 'transparent',
				bgClip: 'padding-box',
				boxShadow: 'none',
				borderRadius: 'sm',
				animation: pulse,
				userSelect: 'none',
				overflow: 'hidden'
			}"
		></div>
	`,
})
export class Skeleton implements AfterContentInit {
	/**
	 * The color of the start of the animation.
	 */
	@Input() public colorStart: string = '#f5f5f5';

	/**
	 * The color of the end of the animation.
	 */
	@Input() public colorEnd: string = '#dedede';

	/* ---------------------------------- */

	// The pulse css animation
	public get pulse() {
		return `0.9s linear infinite alternate ${keyframes({
			from: css({ bg: this.colorStart }),
			to: css({ bg: this.colorEnd }),
		})}`;
	}

	/* ---------------------------------- */

	constructor(private el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
		proxyAttr(this.el, 'style');
	}
}

//
// ──────────────────────────────────────────────── SKELETON CIRCLE COMPONENT ─────
//

@Component({
	selector: 'skeleton-circle',
	template: `
		<div
			sx
			[h]="size"
			[w]="size"
			color="transparent"
			bgClip="padding-box"
			boxShadow="none"
			[borderRadius]="borderRadius"
			[animation]="pulse"
			userSelect="none"
			overflow="hidden"
		></div>
	`,
})
export class SkeletonCircle implements AfterContentInit {
	/**
	 * Component `borderRadius`.
	 */
	@Input() public borderRadius: string = '50%';
	/**
	 * Component `width` and `height`.
	 */
	@Input() public size: string = '10';
	/**
	 * The color of the start of the animation.
	 */
	@Input() public colorStart: string = '#f5f5f5';

	/**
	 * The color of the end of the animation.
	 */
	@Input() public colorEnd: string = '#dedede';

	/* ---------------------------------- */

	// The pulse css animation
	public get pulse() {
		return `0.9s linear infinite alternate ${keyframes({
			from: css({ bg: this.colorStart }),
			to: css({ bg: this.colorEnd }),
		})}`;
	}

	/* ---------------------------------- */

	constructor(private el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
		proxyAttr(this.el, 'style');
	}
}

//
// ────────────────────────────────────────────────── SKELETON TEXT COMPONENT ─────
//

@Component({
	selector: 'skeleton-text',
	template: `
		<v-stack [spacing]="spacing">
			<div
				*ngFor="let line of noOfLines; last as isLast"
				sx
				h="3"
				w="{{ isLast ? '80%' : '100%' }}"
				color="transparent"
				bgClip="padding-box"
				boxShadow="none"
				borderRadius="sm"
				[animation]="pulse"
				userSelect="none"
				overflow="hidden"
			></div>
		</v-stack>
	`,
})
export class SkeletonText implements AfterContentInit {
	/**
	 * The color of the start of the animation.
	 */
	@Input() public colorStart: string = '#f5f5f5';

	/**
	 * The color of the end of the animation.
	 */
	@Input() public colorEnd: string = '#dedede';

	/**
	 * The space between each line.
	 */
	@Input() public spacing: number = 4;

	/**
	 * The number of lines in the skeleton.
	 */
	@Input()
	public get noOfLines() {
		return this._noOfLines;
	}
	public set noOfLines(value) {
		const noOfLines = toNum(value);
		if (isNum(noOfLines)) {
			this._noOfLines = [...Array(noOfLines).keys()];
		}
	}
	protected _noOfLines: number[] = [0, 1, 2];

	/* ---------------------------------- */

	// The pulse css animation
	public get pulse() {
		return `0.9s linear infinite alternate ${keyframes({
			from: css({ bg: this.colorStart }),
			to: css({ bg: this.colorEnd }),
		})}`;
	}

	/* ---------------------------------- */

	constructor(private el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
		proxyAttr(this.el, 'style');
	}
}
