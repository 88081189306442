import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthService, RoutingStateService,NewOrderService} from '../services';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  constructor(
    private router      : Router, 
		private orderService: NewOrderService,
    private route       : ActivatedRoute,
    private auth        : AuthService,
    private cookies     :CookieService,
    private routingState: RoutingStateService
  ) { }

  ngOnInit() {   
    this.auth.serverHealthCheck()
    .subscribe((res)=>{
      if(res.statusText == 'OK'){
        this.route.params.subscribe((params) => {
          if(params['cookie']){
            var cookieObj=JSON.parse(params['cookie']);
            cookieObj['user']['User_Code'] = cookieObj['user']['User_Status'];
            delete cookieObj['user']['User_Status'];
            this.cookies.put('pippinTitleUsr', JSON.stringify(cookieObj));  
            this.auth.isMultiCliLogin(); 
            this.auth.removeOrg();
            if(this.auth.getRedirectURL() && this.auth.getRedirectURL().length > 0) {          
              this.router.navigateByUrl(this.auth.getRedirectURL());
              this.auth.resetRedirectURL();
            }   
            else this.router.navigate(['orders/current']);
            // else {
            //   if(this.auth.isMultiCliLogin()) this.router.navigate(['accounts']);
            //   else this.router.navigate(['orders/current']);
            // }
          }
          else if(this.auth.isLoggedIn()) {
            if(this.auth.getRedirectURL() && this.auth.getRedirectURL().length > 0) {          
              this.router.navigateByUrl(this.auth.getRedirectURL());
              this.auth.resetRedirectURL();
            }   
            else this.router.navigate(['orders/current']);
            this.orderService.resetAll();
            this.routingState.clearHistory();
          }
          else this.router.navigate(['/login']);
        }); 
      }
      
    })
      
  }

  ngAfterViewInit() {
    if(this.auth.isLoggedIn()) this.auth.init().subscribe((res) => {});
  }

}
