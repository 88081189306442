import set from 'clean-set';
import merge from 'lodash.merge';
import { memoize } from './function';
import { isEmpty, isFn, isNil } from './assertion';
import { Dict, StringOrNumber } from './types';
import { toArray } from './array';

/* ---------------------------------- */

export { merge, set };

/* ---------------------------------- */

/**
 * The base implementation of `get`.
 *
 * @param obj - The target object.
 * @param path - The property path.
 * @param def - A default value to used if the path is null.
 */
const baseGet = (
	obj: object | string[] | number[] | object[],
	path: string | number | StringOrNumber[],
	def?: any,
) => {
	const key = path && path['split'] ? path['split']('.') : [path];

	for (let p = 0; p < key.length; p++) {
		obj = obj
			? typeof obj[key[p]] === 'function'
				? obj[key[p]]()
				: obj[key[p]]
			: undefined;
	}

	return obj === undefined ? def : obj;
};

/**
 * Safely get the value at a dot-notated or array-notated path within a collection of nested arrays
 * or objects.  If the full key path doesn’t exist or the value is undefined the defaultValue will
 * be returned if passed, otherwise returns null.
 */
export const get = (...args: any) =>
	args.length > 1
		? baseGet(args[0], args[1], args[2])
		: (obj: object | string[] | number[] | object[]) => baseGet(obj, args[0]);

/* ---------------------------------- */

/**
 * A memoized version of the get method.
 */
export const memoizedGet = memoize(get);

/* ---------------------------------- */

/**
 * The base implementation of `omit`.
 *
 * @param object - The object to iterate over.
 * @param keys - The key or array of keys to leave out of the new object.
 * @param options - Config options.
 * @params options.asArray - Will return an array of values with out keys.
 */
const baseOmit = (
	object: Dict,
	keys: string | string[],
	options?: {
		asArray: boolean;
	},
) => {
	const asArray = get(options, 'asArray', false);
	const next: any = asArray ? [] : {};

	keys = Array.isArray(keys) ? keys : [keys];

	Object.keys(object).forEach(key => {
		if (keys.includes(key)) return;
		if (asArray) next.push(object[key]);
		if (!asArray) next[key] = object[key];
	});

	return next;
};

/**
 * Creates a new object without key/value pairs in the keys arg.
 */
export const omit = (...args: any) =>
	args.length === 1
		? (object: Dict) => baseOmit(object, args[0])
		: baseOmit(args[0], args[1], args[2]);

/* ---------------------------------- */

/**
 * The base implementation of `pick`.
 *
 * @param object - The object to iterate over.
 * @param keys - The key or array of keys to map to the new object.
 * @param options - Config options.
 * @params options.asArray - Will return an array of values with out keys.
 */
const basePick = (object: Dict, keys: string | string[], options?: Dict) => {
	const asArray = get(options, 'asArray', false);
	let next: any = asArray ? [] : {};

	toArray(keys).forEach((key: string) => {
		const val = get(object, key);

		if (val) {
			if (asArray) next.push(val);
			if (!asArray) next = set(next, key, val);
		}
	});

	return next;
};

/**
 * Creates a new object with only the key/value pairs in the keys arg.
 */
export const pick = (...args: any) =>
	args.length === 1
		? (object: Dict, options: Dict) => basePick(object, args[0], options)
		: basePick(args[0], args[1], args[2]); /**

/* ---------------------------------- */

/**
 * The base implementation of `objMap`.
 *
 * @param object - The object to iterate over.
 * @param fnOrPath - Either a function to handle mapping or a path to map.
 */
export const baseObjMap = (object: any, fnOrPath: any) => {
	const fn = isFn(fnOrPath) ? fnOrPath : (key, val: any) => get(val, fnOrPath);
	const next = {};

	Object.entries(object).forEach(([key, val]) => {
		next[key] = fn(key, val, object);
	});

	return next;
};

/**
 * Maps the key/value pairs of an object.
 */
export const objMap = (...args: any) =>
	args.length === 1
		? (object: Dict) => baseObjMap(object, args[0])
		: baseObjMap(args[0], args[1]);

/* ---------------------------------- */

/**
 * The base implementation of `objFilter`.
 *
 * @param object - The object to iterate over.
 * @param fn - The function used to evaluate each key/value pair.
 */
export const baseObjFilter = (object: any, fn: any) => {
	const next = {};

	Object.entries(object).forEach(([key, val]) => {
		if (fn(key, val, object)) {
			next[key] = val;
		}
	});

	return next;
};

/**
 * Filters the key/value pairs of an object.
 */
export const objFilter = (...args: any) =>
	args.length === 1
		? (object: Dict) => baseObjFilter(object, args[0])
		: baseObjFilter(args[0], args[1]);

/* ---------------------------------- */

export const objFilterNil = (object: Dict) =>
	objFilter(object, (key: string, val: any) => !isNil(val));

/* ---------------------------------- */

export const objFilterEmpty = (object: Dict) =>
	objFilter(object, (key: string, val: any) => !isEmpty(val));

/* ---------------------------------- */
