import { Component, OnDestroy, OnInit } from '@angular/core';
import {AuthService, AboutService, ConfigService} from '../../services';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multi-account',
  templateUrl: './multi-account.component.html',
  styleUrls: ['./multi-account.component.scss']
})
export class MultiAccountComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  private isMultiCompany: any = true;
  selectedAcc: any;
  timer:any;

  accountsList = [];
  constructor(
    private aboutService: AboutService,
    public auth: AuthService,
    private router: Router,
    private cookies: CookieService,
    private config:ConfigService,
  ) { }

  ngOnInit() {
    this.auth.isMultiCompany$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state) => this.isMultiCompany = state);
    this.aboutService.getAllCompanyAccountsByUserId(this.auth.getUserId())
    .subscribe((accounts)=> {
      if(accounts.length > 1) {
        this.accountsList = accounts;
        this.selectCurrentLoginAcc();
      } else this.router.navigate(['/orders/current']);
    }, (err) => {
      console.log("Error while geting the user's company accounts===", err);
    })
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);   
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    if(this.timer) clearTimeout(this.timer);
  }

  selectedAccount(acc) {
    this.selectedAcc = acc;
  }

  backToHomePage() {
    // this.auth.setMultiAccountStatus(false);
    if(this.isMultiCompany) this.auth.logout();
    else this.router.navigate(['/orders/current']);
  }

  switchAccount() {
    if (this.selectedAcc) {
      var cookieObj = JSON.parse(this.cookies.get('pippinTitleUsr'));
      if (cookieObj && cookieObj.token) {
        cookieObj['user']['User_Code'] = cookieObj['user']['User_Status'];
        if(this.selectedAcc.Organization_ID) cookieObj['user']['Organization_ID'] = this.selectedAcc.Organization_ID; //set org id based on selected
        cookieObj['user']['Org_Name'] = this.selectedAcc.Organization_Name;
        delete cookieObj['user']['User_Status'];
        // create a cookies for newuser
        this.cookies.put('pippinTitleUsr', JSON.stringify(cookieObj));
      }
      this.auth.setMultiAccountStatus(false);
      this.router.navigate(['/orders/current']);
    }
  }

  selectCurrentLoginAcc() {
    this.selectedAcc = this.accountsList.find(obj => obj['Organization_ID'] === this.auth.getUserOrgId());
  }
}
