import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { FormGroup, FormsModule, FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material'
import * as _ from 'underscore';
import { CONSTANTS } from '../../app.constants';

@Component({
  selector: 'app-mat-single-search',
  templateUrl: './mat-single-search.component.html',
  styleUrls: ['./mat-single-search.component.scss']
})
export class MatSingleSearchComponent implements OnInit {
  constants = CONSTANTS;
  selectedOption: any;
  protected _onDestroy = new Subject<void>();

  filteredEntityList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  entityFilterCtrl = new FormControl();

  @Input() entityList: Array<any>;

  @Input() matSearchCtrl = new FormControl();
  @Input() selectedId: string;
  @Input() placeHolderSel: any;
  @Input() matSelPlaceHolder: string;
  @Input() entityName: string = 'Entity';
  @Input() selectKey: string;
  @Input() filterKey: string;
  @Input() initializeData: any = null;
  @Input() selected: string = "";
  @Input() disabled: boolean = false;
  @Input() hiddenComp: boolean = false;
  @Input() selectAllValue: any = 'all';
  @Input() isSelectAllEnabled: boolean;
  @Input() requiredBool: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() selectAll: boolean = true;
  @Output() selectedChange = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() manualChange = new EventEmitter<any>();
  @ViewChild('matSelSearch') matSelSearch: MatSelect
  @ViewChild('myInput') myInput: ElementRef

  constructor(
    private ref: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.entityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEntity();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let prop in changes) {
      if (prop == 'selected') {
        this.selectedOption = this.selected;

      }
      if (prop == 'entityList' && this.filteredEntityList) {
        if (_.isArray(this.entityList)) {
          this.filteredEntityList.next(this.entityList.slice())
        }
        if (this.initializeData) {
          this.selectedOption = this.initializeData;
        };
      }

    }
  }

  protected filterEntity() {
    if (!this.entityList) {
      return;
    }
    let search = this.entityFilterCtrl.value;
    if (this.selectAll) {
      if (!search) {
        this.filteredEntityList.next(this.entityList.slice());
        this.isSelectAllEnabled = true;
      } else {
        search = search.toLowerCase();
        this.isSelectAllEnabled = false;
      }
    }
    else{
      if (!search) this.filteredEntityList.next(this.entityList.slice()); 
      else search = search.toLowerCase();
    }
    this.filteredEntityList.next(
      this.entityList.filter(entityData => entityData[this.filterKey].toLowerCase().indexOf(search) > -1));
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  emitSelected(event) {
    if (event) {
      this.selectedChange.emit(this.selectedOption);
      this.selectionChange.emit(this.selectedOption);
    }
  }
  emitManualSelectionChange(event) {
    this.manualChange.emit(event);
  }
}
