import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	MatAutocompleteModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatIconModule,
	MatInputModule,
	MatMenuModule,
	MatNativeDateModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatTooltipModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';

import {
	Address,
	AddressForm,
	AddressReadonly,
	E404Component,
	FooterComponent,
	HeaderComponent,
	MatSingleSearchComponent,
	NewStripeCard,
	ProgressBarComponent,
	SidebarComponent,
	StripeInput,
	TopNavigationComponent,
	Upload,
	UploadItem,
} from '@common';

import {
	Alert,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Collapse,
	Container,
	Flex,
	Grid,
	HStack,
	Icon,
	Skeleton,
	SkeletonCircle,
	SkeletonText,
	Stack,
	VStack,
} from '@ui';

import {
	AutogrowDirective,
	CurrencyFormatterDirective,
	CustomAutofocusDirective,
	LimitToDirective,
	NoDecimalDirective,
	NoWhitespaceDirective,
	NumberOnlyDirective,
	ScrollTopDirective,
	StyledDirective,
	WhitespaceDirective,
} from '@directives';

import {
	CapitalizePipe,
	IsNotEmptyPipe,
	IsNilPipe,
	IsNotNilPipe,
	PhonenumberPipe,
	TruncatePipe,
} from '@pipes';

import {
	AboutService,
	AuthGuardService,
	AuthService,
	AwsS3Service,
	ConfigService,
	EmailValidator,
	EqualValidator,
	FileService,
	HttpService,
	IntercomService,
	InvoiceService,
	MessagesService,
	NewOrderService,
	OrderListingService,
	PagerService,
	PasswordMultiValidator,
	PasswordValidator,
	PhoneValidator,
	PreloaderComponent,
	PreloaderService,
	PricingService,
	ProductService,
	ProgressBarService,
	QuoteService,
	RoutingStateService,
	ScrollService,
	SearchLoaderComponent,
	SettingsService,
	SocketService,
	StateService,
	StaticTextService,
	ThemeService,
} from '@services';

import {
	Billing,
	AccountInformationComponent,
	AddressComponent,
	PhoneComponent,
	CompanyComponent,
	EmailComponent,
	InfoComponent,
	SettingComponent,
} from 'app/profile';

import {
	BillingAddress,
	PaymentMethodAdd,
	PaymentMethodDelete,
	PaymentMethodSelect,
	PaymentMethodEdit,
	PaymentMethodCardSkeleton,
	PaymentMethodCard,
	PaymentMethods,
} from 'app/profile/billing';

import { QuoteDetailsComponent } from 'app/request-quote/quote-details/quote-details.component';
import { QuotesComponent } from 'app/request-quote/quotes/quotes.component';
import { RequestQuote1Component } from 'app/request-quote/request-quote1/request-quote1.component';
import { RequestQuote2Component } from 'app/request-quote/request-quote2/request-quote2.component';
import { RequestQuote3Component } from 'app/request-quote/request-quote3/request-quote3.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { CookieModule, CookieService } from 'ngx-cookie';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { environment } from '../environments/environment';
import { AboutComponent } from './about/about/about.component';
import { ContactComponent } from './about/contact/contact.component';
import { PrivacyComponent } from './about/privacy/privacy.component';
import { ServiceComponent } from './about/service/service.component';
import { TermsComponent } from './about/terms/terms.component';
import { AppComponent } from './app.component';
import { ChangeComponent } from './authentication/change/change.component';
import { CreatePasswordComponent } from './authentication/create-password/create-password.component';
import { ForgetComponent } from './authentication/forget/forget.component';
import { LoginComponent } from './authentication/login/login.component';
import { RegistrationStep1Component } from './authentication/registration-step1/registration-step1.component';
import { RegistrationComponent } from './authentication/registration/registration.component';
import { BaseComponent } from './base/base.component';
import { CancelDialogComponent } from './dialog/cancel-dialog/cancel-dialog.component';
import { ConfirmComponent } from './dialog/confirm/confirm.component';
import { ConvertOrderComponent } from './dialog/convert-order/convert-order.component';
import { DisplaymessageComponent } from './dialog/displaymessage/displaymessage.component';
import { ErrorDialogComponent } from './dialog/error-dialog/error-dialog.component';
import { ErrorComponent } from './dialog/error/error.component';
import { MessageComponent } from './dialog/message/message.component';
import { StripeAchPaymentComponent } from './dialog/stripe-ach-payment/stripe-ach-payment.component';
import { StripePaymentComponent } from './dialog/stripe-payment/stripe-payment.component';
import { SuccessComponent } from './dialog/success/success.component';
import { ErrorHandler } from './error-handler/error.handler';
import { CancelledOrdersComponent } from './orders/cancelled-orders/cancelled-orders.component';
import { HomeComponent } from './orders/home/home.component';
import { InvoiceComponent } from './orders/invoice/invoice.component';
import { MessagesComponent } from './orders/messages/messages.component';
import { NewOrder1Component } from './orders/new-order/new-order1/new-order1.component';
import { NewOrder2Component } from './orders/new-order/new-order2/new-order2.component';
import { NewOrder3Component } from './orders/new-order/new-order3/new-order3.component';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { OrderHistoryComponent } from './orders/order-history/order-history.component';
import { OrderFlyComponent } from './orders/orderFly/order-fly.component';
import { PricingComponent } from './pricing/pricing.component';
import { TermsConditionsComponent } from './dialog/terms-conditions/terms-conditions.component';
import { QuotePaymentComponent } from './request-quote/quote-payment/quote-payment.component';
import { QuoteReviewComponent } from './request-quote/quote-review/quote-review.component';
import { NpsSuccessComponent } from './dialog/nps-success/nps-success.component';
import { UserNpsComponent } from './common/user-nps/user-nps.component';
import { TrackerComponent } from './tracker/tracker.component';
import { AddressCompComponent } from './common/address-comp/address-comp.component';
import { CancellationPolicyComponent } from './about/cancellation-policy/cancellation-policy.component';
import { MultiAccountComponent } from './authentication/multi-account/multi-account.component';

const configSocket: SocketIoConfig = { url: environment.basePath, options: {} };

export const appRoutes: Routes = [
	{ path: 'login/:success', component: LoginComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'accounts', component: MultiAccountComponent },
	{ path: 'base', component: BaseComponent },
	{ path: 'base/:cookie', component: BaseComponent },
	{ path: 'registration/step1', component: RegistrationStep1Component },
	{ path: 'registration/:id', component: RegistrationComponent },
	{ path: 'forget', component: ForgetComponent },
	{ path: 'maintenance', component: E404Component },
	{ path: 'user/new/:verificationId', component: CreatePasswordComponent },
	{ path: 'tracker/:orderId', component: TrackerComponent },

	{
		path: 'user/change-password/:userID',
		component: ChangeComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userId/orders/new/step1',
		component: NewOrder1Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userId/orders/new/step1/:back',
		component: NewOrder1Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userId/orders/new/step2',
		component: NewOrder2Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userId/orders/new/step3',
		component: NewOrder3Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userId/orders/new/checkout',
		component: StripePaymentComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile',
		component: AccountInformationComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile/email',
		component: EmailComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile/firstname',
		component: InfoComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile/lastname',
		component: InfoComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile/address',
		component: AddressComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile/phone',
		component: PhoneComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile/fax',
		component: PhoneComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'user/:userID/profile/company',
		component: CompanyComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'billing',
		component: Billing,
		canActivate: [AuthGuardService],
	},
	{
		path: 'settings',
		component: SettingComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'orders/current',
		component: HomeComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'orders/history',
		component: OrderHistoryComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'orders/cancelled',
		component: CancelledOrdersComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'orders/messages',
		component: MessagesComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'orders/pricing',
		component: PricingComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'orders/invoice',
		component: InvoiceComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'orders/:orderId/view',
		component: OrderDetailsComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'update/:orderId/view',
		component: OrderFlyComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'privacypolicy',
		component: PrivacyComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'cancellationpolicy',
		component: CancellationPolicyComponent,
		canActivate: [AuthGuardService],
	},

	{
		path: 'quote/:userId/step1',
		component: RequestQuote1Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'quote/:userId/step1/:back',
		component: RequestQuote1Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'quote/:userId/step2',
		component: RequestQuote2Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'quote/:userId/step3',
		component: RequestQuote3Component,
		canActivate: [AuthGuardService],
	},
	{
		path: 'quote/:quoteId/view',
		component: QuoteDetailsComponent,
		canActivate: [AuthGuardService],
	},
	{
		path: 'quotes',
		component: QuotesComponent,
		canActivate: [AuthGuardService],
	},
	{
		path:'convert/:userId/:orderId/step2',
		component:QuotePaymentComponent,
		canActivate: [AuthGuardService],
	},
	{
		path:'convert/:userId/:orderId/step3',
		component:QuoteReviewComponent,
		canActivate: [AuthGuardService],
	},
	{ path: 'termsandconditions/:userId', component: TermsComponent },
	{ path: 'aboutus', component: AboutComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'services', component: ServiceComponent },
	{ path: '**', redirectTo: '/base' },
];
const routing = RouterModule.forRoot(appRoutes);

@NgModule({
	declarations: [
		AppComponent,
		PreloaderComponent,
		LoginComponent,
		RegistrationComponent,
		ForgetComponent,
		ChangeComponent,
		HomeComponent,
		HeaderComponent,
		TopNavigationComponent,
		EmailValidator,
		PasswordValidator,
		PasswordMultiValidator,
		EqualValidator,
		PhoneValidator,
		PhonenumberPipe,
		EqualValidator,
		EmailComponent,
		AccountInformationComponent,
		AddressComponent,
		SidebarComponent,
		FooterComponent,
		LimitToDirective,
		NumberOnlyDirective,
		SettingComponent,
		NumberOnlyDirective,
		ProgressBarComponent,
		OrderHistoryComponent,
		CancelledOrdersComponent,
		MessagesComponent,
		PricingComponent,
		SuccessComponent,
		DisplaymessageComponent,
		CreatePasswordComponent,
		BaseComponent,
		NewOrder1Component,
		OrderDetailsComponent,
		InfoComponent,
		AboutComponent,
		ContactComponent,
		ServiceComponent,
		PrivacyComponent,
		TermsComponent,
		MessageComponent,
		ErrorComponent,
		ConfirmComponent,
		AutogrowDirective,
		E404Component,
		TruncatePipe,
		CapitalizePipe,
		IsNotEmptyPipe,
		IsNotNilPipe,
		IsNilPipe,
		SearchLoaderComponent,
		PhoneComponent,
		CompanyComponent,
		CancelDialogComponent,
		InvoiceComponent,
		RegistrationStep1Component,
		ErrorDialogComponent,
		NewOrder2Component,
		CustomAutofocusDirective,
		NoWhitespaceDirective,
		NoDecimalDirective,
		ScrollTopDirective,
		WhitespaceDirective,
		OrderFlyComponent,
		MatSingleSearchComponent,
		StripePaymentComponent,
		StripeAchPaymentComponent,
		NewOrder3Component,
		CurrencyFormatterDirective,
		StyledDirective,
		Card,
		CardActions,
		CardContent,
		CardHeader,
		Container,
		Flex,
		Grid,
		HStack,
		Icon,
		NewStripeCard,
		PaymentMethodAdd,
		PaymentMethodDelete,
		PaymentMethodEdit,
		PaymentMethodCardSkeleton,
		PaymentMethodCard,
		PaymentMethodSelect,
		PaymentMethods,
		Skeleton,
		SkeletonCircle,
		SkeletonText,
		Stack,
		StripeInput,
		VStack,
		BillingAddress,
		Billing,
		RequestQuote1Component,
		RequestQuote2Component,
		RequestQuote3Component,
		QuotesComponent,
		ConvertOrderComponent,
		QuoteDetailsComponent,
		TermsConditionsComponent,
		Alert,
		Collapse,
		Address,
		AddressForm,
		AddressReadonly,
		Upload,
		UploadItem,
		QuotePaymentComponent,
		QuoteReviewComponent,
		NpsSuccessComponent,
		UserNpsComponent,
		TrackerComponent,
		AddressCompComponent,
		CancellationPolicyComponent,
		MultiAccountComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		routing,
		RouterModule,
		NoopAnimationsModule,
		SocketIoModule.forRoot(configSocket),
		MatSlideToggleModule,
		BootstrapModalModule.forRoot({ container: document.body }),
		MatMenuModule,
		MatAutocompleteModule,
		MatDialogModule,
		MatDividerModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatInputModule,
		MatButtonModule,
		MatSelectModule,
		MatRadioModule,
		MatIconModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		InfiniteScrollModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		NgxMatSelectSearchModule,
		CookieModule.forRoot(),
		MatPasswordStrengthModule.forRoot(),
	],
	entryComponents: [
		SuccessComponent,
		ErrorComponent,
		MessageComponent,
		ConfirmComponent,
		DisplaymessageComponent,
		CancelDialogComponent,
		ErrorDialogComponent,
		StripePaymentComponent,
		StripeAchPaymentComponent,
		ConvertOrderComponent,
		TermsConditionsComponent,
		PaymentMethodAdd,
		PaymentMethodDelete,
		PaymentMethodEdit,
		NpsSuccessComponent,
		UserNpsComponent
	],
	providers: [
		AuthService,
		CookieService,
		ConfigService,
		AuthGuardService,
		PreloaderService,
		EmailValidator,
		PasswordValidator,
		PasswordMultiValidator,
		PhoneValidator,
		StaticTextService,
		EqualValidator,
		ProgressBarService,
		NewOrderService,
		OrderListingService,
		PagerService,
		AboutService,
		FileService,
		SettingsService,
		PricingService,
		MessagesService,
		SocketService,
		StateService,
		InvoiceService,
		ProductService,
		AwsS3Service,
		ScrollService,
		IntercomService,
		QuoteService,
		RoutingStateService,
		ErrorHandler,
		ThemeService,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
