import {
	OnInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { StripeService, ThemeService } from '@services';
import { get, isNotEmptyString, isNil, __DEV__, isBool, Dict } from '@utils';

@Component({
	selector: 'stripe-input',
	template: `
		<!-- loading -->
		<skeleton *ngIf="isLoading" sx py="2"></skeleton>

		<!-- loaded -->
		<div
			[hidden]="isLoading"
			sx
			pos="relative"
			h="32.8px !important"
			[_before]="{
				content: '',
				pos: 'absolute',
				left: '0',
				bottom: '0',
				w: '100%',
				h: '1px',
				bg: 'rgba(0, 0, 0, 0.42)',
				transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
			}"
			[_after]="{
				content: '',
				pos: 'absolute',
				left: '0',
				bottom: '0',
				w: '100%',
				h: '2px',
				bg: 'secondary.main',
				transform: 'scaleX(0)',
				transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
			}"
			_disabled="{
        'pointerEvents': 'none',
        '& > *': {
          'opacity': '0.38'
        },
       '_before': {
        'bgImg': 'linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%)',
        'bgSize': '4px 100%',
        'bgRepeat': 'repeat-x',
        'bgPosition': '0',
        'bgColor': 'transparent'
       }
      }"
			_focus="{
        '_before': {
          'h': '1px'
        },
        '_after': {
          'transform': 'scaleX(1)'
        }
      }"
			_invalid="{
        '_after': {
          'bg': 'error.main',
          'transform': 'scaleX(1)'
        }
      }"
		>
			<!-- stripe element mount -->
			<div #inputRef sx py="2" px="3"></div>
		</div>
		<mat-error *ngIf="hasError" sx textAlign="left">
			<small>{{ error ? error : stripeError }}</small>
		</mat-error>
	`,
})
export class StripeInput implements OnInit, OnDestroy {
	//
	// ─────────────────────────────────────────────────────── EXTERNAL STATE API ─────
	//

	/**
	 * Reference to the stripe element.
	 */
	element: Dict;

	/**
	 * The credit card brand.
	 */
	brand: string;

	complete: boolean = false;

	empty: boolean = true;

	valid: boolean = false;

	invalid: boolean = true;

	focused: boolean = false;

	ready: boolean = false;

	/**
	 * The root component element.
	 */
	get ref() {
		return this.inputRef.nativeElement.parentElement;
	}

	/**
	 * True if either the error prop or the stripe error are not null.
	 */
	get hasError(): boolean {
		return this.error || this.stripeError;
	}

	/**
	 * Errors thrown from stripe validation.
	 */
	get stripeError(): any {
		return this._stripeError;
	}
	set stripeError(val: any) {
		this._stripeError = get(val, 'message', val);
		this.cdr.detectChanges();
	}
	_stripeError: any;

	//
	// ──────────────────────────────────────────────────────── CHILDREN REFS API ─────
	//

	@ViewChild('inputRef') inputRef: ElementRef;

	//
	// ─────────────────────────────────────────────────────────── DATA PROPS API ─────
	//

	/**
	 * The type of stripe element the input should mount.
	 */
	@Input() type: string = 'card';

	/**
	 * If true the input will receive focus on the ready event.
	 */
	@Input() autofocus: boolean = false;

	/**
	 * Controlled error state.
	 */
	@Input()
	get error(): string {
		return this._error;
	}
	set error(val: string) {
		if (isNotEmptyString(val)) {
			this._error = val;
			this.cdr.detectChanges();
		}
	}
	_error: string;

	/**
	 * If true the input skeleton will be rendered.
	 */
	@Input()
	get isLoading(): boolean {
		return this._isLoading;
	}
	set isLoading(val: boolean) {
		if (isBool(val)) {
			this._isLoading = val;
			this.cdr.detectChanges();
		}
	}
	_isLoading: boolean = false;

	//
	// ────────────────────────────────────────────────────────── EVENT PROPS API ─────
	//

	/**
	 * Callback function that is called when the input value changes.
	 */
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	/**
	 * Callback function that is called when the input is complete.
	 */
	@Output() onComplete: EventEmitter<any> = new EventEmitter();

	/**
	 * Callback function that is called when the user has changed the input value in the UI.
	 */
	@Output() onDirty: EventEmitter<any> = new EventEmitter();

	/**
	 * Callback function that is called when the input is valid.
	 */
	@Output() onValid: EventEmitter<any> = new EventEmitter();

	/**
	 * Callback function that is called when the input is invalid.
	 */
	@Output() onInvalid: EventEmitter<any> = new EventEmitter();

	/**
	 * Callback function that is called when a stripe token for the element has been created.
	 */
	@Output() onToken: EventEmitter<any> = new EventEmitter();

	/**
	 * Callback function that is called when the stripe element is mounted and ready.
	 */
	@Output() onReady: EventEmitter<any> = new EventEmitter();

	//
	// ──────────────────────────────────────────────────────── LIFECYCLE METHODS ─────
	//

	constructor(protected cdr: ChangeDetectorRef, protected _stripe: StripeService) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['stripeInput'])) window['stripeInput'] = this;
		}
	}

	ngOnInit() {
		// After the view has been initialized by angular, create the stripe element.
		this.element = elements.create(this.type, {
			hidePostalCode: true,
			style: this._stripe.baseStyle,
		});

		// Mount the stripe element in the DOM.
		this.element.mount(this.inputRef.nativeElement);

		// Initialize event listeners for the mounted stripe element.
		this.element.addEventListener('change', this._handleChange);
		this.element.addEventListener('focus', this._handleFocus);
		this.element.addEventListener('blur', this._handleBlur);

		// When the stripe element is ready & autofocus, trigger focus on stripe element.
		this.element.on('ready', () => {
			requestAnimationFrame(() => {
				setTimeout(() => {
					this.onReady.emit();
					this.ready = true;
					if (this.autofocus) {
						this.focus();
					}
				}, 50);
			});
		});
	}

	ngOnDestroy() {
		if (this.element && this.element.removeEventListener && this.element.destroy) {
			this.element.removeEventListener('change', this._handleChange);
			this.element.removeEventListener('focus', this._handleFocus);
			this.element.removeEventListener('blur', this._handleBlur);
			this.element.destroy();
		}
	}

	//
	// ───────────────────────────────────────────────────────── INTERNAL METHODS ─────
	//

	/**
	 * Set the state of the `parentElement` via a data attribute.
	 * @ignore
	 */
	_addDOMAttr = (stateAttr: string) =>
		requestAnimationFrame(() => {
			this.ref.setAttribute(`data-${stateAttr}`, true);
		});

	/**
	 * Set the state of the `parentElement` to null via a data attribute by removing it.
	 * @ignore
	 */
	_removeDOMAttr = (stateAttr: string) =>
		requestAnimationFrame(() => {
			this.ref.removeAttribute(`data-${stateAttr}`);
		});

	/**
	 * Clear all errors.
	 * @ignore
	 */
	_clearErrors = () => {
		this.error = null;
		this.stripeError = null;
		this.cdr.detectChanges();
	};

	/**
	 * Event handler for the element focus events.
	 * @ignore
	 */
	_handleFocus = () => {
		this.focused = true;
		this._addDOMAttr('focus');
	};

	/**
	 * Event handler for the element blur events.
	 * @ignore
	 */
	_handleBlur = () => {
		this.focused = false;
		this._removeDOMAttr('focus');
	};

	/**
	 * Event handler for the element change events.
	 * @ignore
	 */
	_handleChange = (event: any) => {
		// Clear errors
		this._clearErrors();

		this.onChange.emit(event);

		// If the element is valid, else invalid.
		if (event.complete && !event.error && !this.error) {
			this.valid = true;
			this.onValid.emit();
		} else if (!event.complete || event.error || this.error) {
			this.valid = false;
		}

		// If the element has an error, else no error.
		if (event.error || this.error) {
			this._addDOMAttr('invalid');
			if (event.error) {
				this.stripeError = event.error;
				this.onInvalid.emit(event.error);
			}
			if (this.error) {
				this.onInvalid.emit(this.error);
			}
		} else {
			this._clearErrors();
			this._removeDOMAttr('invalid');
		}

		// If the element is complete, else not complete.
		if (event.complete) {
			this.complete = true;
			this.onComplete.emit();
		} else {
			this.complete = false;
		}

		// Trigger the element value `onChange` event callback.
		//this.onChange.emit(event);
		// // Force the component to re-render any state changes.
		// this.cdr.detectChanges();
	};

	//
	// ───────────────────────────────────────────────────── EXTERNAL METHODS API ─────
	//

	/**
	 * Clear the stripe element.
	 */
	clear = () => this.element.clear();

	/**
	 * Destroy the stripe element.
	 */
	destroy = () => this.element.destroy();

	/**
	 * Focus the stripe element.
	 */
	focus = () => this.element.focus();

	/**
	 * Blur the stripe element.
	 */
	blur = () => this.element.blur();

	/**
	 * Disable the stripe element.
	 */
	disable = () => this._addDOMAttr('disabled');

	/**
	 * Enable the stripe element.
	 */
	enable = () => this._removeDOMAttr('disabled');
}
