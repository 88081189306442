export const dialog = {
	header: {
		mb: 2,
		pb: 2,
		pt: 6,
		px: 10,
	},
	content: {
		py: 2,
		px: 10,
	},
	actions: {
		mt: 4,
		pt: 4,
		pb: 4,
		px: 10,
		d: 'flex',
		justifyContent: 'flex-end',
		'& > *:not(:last-child)': {
			mr: 2,
		},
	},
	hasDivider: {
		header: {
			borderBottom: '1px solid divider.main',
		},
		actions: {
			borderTop: '1px solid divider.main',
		},
	},
	spacingIsDisabled: {
		header: {
			mb: 0,
			pb: 0,
		},
		actions: {
			mt: 0,
			pt: 0,
		},
	},
};
