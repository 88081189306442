import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StripeService } from '@services';
import { Dict, get, to, getError } from '@utils';

@Component({
	selector: 'payment-method-delete',
	template: `
		<!-- alert ‣ overall changes or errors -->
		<alert #alertRef [isOpen]="error | isNotNil" severity="error" variant="filled">
			{{ error }}
		</alert>

		<!-- title -->
		<div sx variant="dialog.header">
			<h1 sx variant="title">Remove card?</h1>
		</div>

		<div sx variant="dialog.content">
			<div sx [opacity]="isUpdating ? '0.5' : '1'">
				<payment-method-card
					actionsAreDisabled="true"
					[card]="data.card"
				></payment-method-card>
			</div>
		</div>

		<!-- actions -->
		<div sx variant="dialog.actions">
			<button (click)="close()" [disabled]="isUpdating" class="btn btn-default">
				Cancel
			</button>
			<button
				(click)="handleSubmit(data?.card?.id)"
				[disabled]="isUpdating"
				class="btn btn-primary"
				sx
				variant="button.contained"
				cdkFocusInitial
			>
				<span *ngIf="!isUpdating">Remove</span>
				<mat-spinner *ngIf="isUpdating" diameter="24"></mat-spinner>
			</button>
		</div>
	`,
})
export class PaymentMethodDelete {
	error: string;

	isUpdating: boolean = false;

	/* ---------------------------------- */

	@ViewChild('alertRef') alertRef: any;

	/* ---------------------------------- */

	constructor(
		protected cdr: ChangeDetectorRef,
		protected stripe: StripeService,
		public dialogRef: MatDialogRef<PaymentMethodDelete>,
		@Inject(MAT_DIALOG_DATA) public data: { card: any },
	) {}

	_clearErrors = () => {
		this.error = null;
		return this.cdr.detectChanges();
	};

	/* ---------------------------------- */

	close = (card?: Dict): void => {
		this.dialogRef.close(card);
	};

	handleSubmit = async (id: string) => {
		this._clearErrors();
		this.isUpdating = true;

		const [err, card] = await to(this.stripe.deleteCard(id));

		this.isUpdating = false;
		if (err) {
			this.error = getError(err);
			this.alertRef.open();
		} else {
			this.close(card);
		}
		return this.cdr.detectChanges();
	};
}
