import { get } from '@utils';

const base = {
	white: '#fff',
	black: '#000',

	whiteAlpha: {
		50: 'rgba(255, 255, 255, 0.04)',
		100: 'rgba(255, 255, 255, 0.06)',
		200: 'rgba(255, 255, 255, 0.08)',
		300: 'rgba(255, 255, 255, 0.16)',
		400: 'rgba(255, 255, 255, 0.24)',
		500: 'rgba(255, 255, 255, 0.36)',
		600: 'rgba(255, 255, 255, 0.48)',
		700: 'rgba(255, 255, 255, 0.64)',
		800: 'rgba(255, 255, 255, 0.80)',
		900: 'rgba(255, 255, 255, 0.92)',
	},

	blackAlpha: {
		50: 'rgba(0, 0, 0, 0.04)',
		100: 'rgba(0, 0, 0, 0.06)',
		200: 'rgba(0, 0, 0, 0.08)',
		300: 'rgba(0, 0, 0, 0.16)',
		400: 'rgba(0, 0, 0, 0.24)',
		500: 'rgba(0, 0, 0, 0.36)',
		600: 'rgba(0, 0, 0, 0.48)',
		700: 'rgba(0, 0, 0, 0.64)',
		800: 'rgba(0, 0, 0, 0.80)',
		900: 'rgba(0, 0, 0, 0.92)',
	},

	grey: {
		50: '#fafafa',
		100: '#f5f5f5',
		200: '#eeeeee',
		300: '#e0e0e0',
		400: '#bdbdbd',
		500: '#9e9e9e',
		600: '#757575',
		700: '#616161',
		800: '#424242',
		900: '#212121',
		A100: '#d5d5d5',
		A200: '#aaaaaa',
		A400: '#303030',
		A700: '#616161',
	},

	blueGrey: {
		'50': '#eceff1',
		'100': '#cfd8dc',
		'200': '#b0bec5',
		'300': '#90a4ae',
		'400': '#78919c',
		'500': '#607d8b',
		'600': '#587583',
		'700': '#4e6a78',
		'800': '#44606e',
		'900': '#334d5b',
		A100: '#abe1ff',
		A200: '#78ceff',
		A400: '#45bcff',
		A700: '#2bb3ff',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	red: {
		'50': '#fee8e7',
		'100': '#fcc7c3',
		'200': '#faa19b',
		'300': '#f77b72',
		'400': '#f65f54',
		'500': '#f44336',
		'600': '#f33d30',
		'700': '#f13429',
		'800': '#ef2c22',
		'900': '#ec1e16',
		A100: '#ffffff',
		A200: '#ffe9e9',
		A400: '#ffb8b6',
		A700: '#ff9f9c',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	orange: {
		'50': '#fff3e0',
		'100': '#ffe0b3',
		'200': '#ffcc80',
		'300': '#ffb74d',
		'400': '#ffa726',
		'500': '#ff9800',
		'600': '#ff9000',
		'700': '#ff8500',
		'800': '#ff7b00',
		'900': '#ff6a00',
		A100: '#ffffff',
		A200: '#fff7f2',
		A400: '#ffd6bf',
		A700: '#ffc5a6',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': 'rgba(0, 0, 0, 0.87)',
			'600': 'rgba(0, 0, 0, 0.87)',
			'700': 'rgba(0, 0, 0, 0.87)',
			'800': 'rgba(0, 0, 0, 0.87)',
			'900': 'rgba(0, 0, 0, 0.87)',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	yellow: {
		'50': '#fffde7',
		'100': '#fff9c4',
		'200': '#fff59d',
		'300': '#fff176',
		'400': '#ffee58',
		'500': '#ffeb3b',
		'600': '#ffe935',
		'700': '#ffe52d',
		'800': '#ffe226',
		'900': '#ffdd19',
		A100: '#ffffff',
		A200: '#fffffd',
		A400: '#fff7ca',
		A700: '#fff2b1',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': 'rgba(0, 0, 0, 0.87)',
			'600': 'rgba(0, 0, 0, 0.87)',
			'700': 'rgba(0, 0, 0, 0.87)',
			'800': 'rgba(0, 0, 0, 0.87)',
			'900': 'rgba(0, 0, 0, 0.87)',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	green: {
		'50': '#e2f3eb',
		'100': '#b7e2cd',
		'200': '#87ceac',
		'300': '#57ba8a',
		'400': '#33ac71',
		'500': '#0f9d58',
		'600': '#0d9550',
		'700': '#0b8b47',
		'800': '#08813d',
		'900': '#046f2d',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
		A100: '#9effbc',
		A200: '#6bff99',
		A400: '#38ff76',
		A700: '#1fff65',
	},

	teal: {
		'50': '#e0f2f1',
		'100': '#b3e0db',
		'200': '#80cbc4',
		'300': '#4db6ac',
		'400': '#26a69a',
		'500': '#009688',
		'600': '#008e80',
		'700': '#008375',
		'800': '#00796b',
		'900': '#006858',
		A100: '#97ffec',
		A200: '#64ffe3',
		A400: '#31ffda',
		A700: '#18ffd5',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': '#ffffff',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	blue: {
		'50': '#e4f2fe',
		'100': '#bce0fb',
		'200': '#90cbf9',
		'300': '#64b6f7',
		'400': '#42a6f5',
		'500': '#448aff',
		'600': '#1d8ef1',
		'700': '#1883ef',
		'800': '#1479ed',
		'900': '#0b68ea',
		A100: '#ffffff',
		A200: '#e1ecff',
		A400: '#aeccff',
		A700: '#95bcff',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	cyan: {
		'50': '#e0f7fa',
		'100': '#b3ebf2',
		'200': '#80deea',
		'300': '#4dd0e1',
		'400': '#26c6da',
		'500': '#00bcd4',
		'600': '#00b6cf',
		'700': '#00adc9',
		'800': '#00a5c3',
		'900': '#0097b9',
		A100: '#e2f9ff',
		A200: '#afeeff',
		A400: '#7ce3ff',
		A700: '#63ddff',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': 'rgba(0, 0, 0, 0.87)',
			'600': 'rgba(0, 0, 0, 0.87)',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	purple: {
		'50': '#f3e5f6',
		'100': '#e1bee7',
		'200': '#ce93d8',
		'300': '#ba68c8',
		'400': '#ab47bc',
		'500': '#9c27b0',
		'600': '#9423a9',
		'700': '#8a1da0',
		'800': '#801797',
		'900': '#6e0e87',
		A100: '#efb8ff',
		A200: '#e485ff',
		A400: '#d852ff',
		A700: '#d238ff',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': '#ffffff',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: '#ffffff',
		},
	},

	pink: {
		'50': '#fce4ec',
		'100': '#f8bcd0',
		'200': '#f48fb1',
		'300': '#f06292',
		'400': '#ec407a',
		'500': '#e91e63',
		'600': '#e61a5b',
		'700': '#e31651',
		'800': '#df1247',
		'900': '#d90a35',
		A100: '#ffffff',
		A200: '#ffd0d8',
		A400: '#ff9dae',
		A700: '#ff8498',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': '#ffffff',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	linkedin: {
		'50': '#e0f4fb',
		'100': '#b3e3f5',
		'200': '#80d0ee',
		'300': '#4dbde7',
		'400': '#26aee1',
		'500': '#00a0dc',
		'600': '#0098d8',
		'700': '#008ed3',
		'800': '#0084ce',
		'900': '#0073c5',
		A100: '#edf7ff',
		A200: '#badfff',
		A400: '#87c7ff',
		A700: '#6ebbff',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},

	facebook: {
		'50': '#e7ebf3',
		'100': '#c3cde0',
		'200': '#9caccc',
		'300': '#748ab7',
		'400': '#5671a7',
		'500': '#385898',
		'600': '#325090',
		'700': '#2b4785',
		'800': '#243d7b',
		'900': '#172d6a',
		A100: '#a3b8ff',
		A200: '#7091ff',
		A400: '#3d6aff',
		A700: '#2456ff',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': '#ffffff',
			'500': '#ffffff',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: '#ffffff',
			A700: '#ffffff',
		},
	},

	twitter: {
		'50': '#e4f4fd',
		'100': '#bbe3fb',
		'200': '#8ed0f9',
		'300': '#61bdf6',
		'400': '#3faff4',
		'500': '#1da1f2',
		'600': '#1a99f0',
		'700': '#158fee',
		'800': '#1185ec',
		'900': '#0a74e8',
		A100: '#ffffff',
		A200: '#dfedff',
		A400: '#acd0ff',
		A700: '#92c1ff',
		contrast: {
			'50': 'rgba(0, 0, 0, 0.87)',
			'100': 'rgba(0, 0, 0, 0.87)',
			'200': 'rgba(0, 0, 0, 0.87)',
			'300': 'rgba(0, 0, 0, 0.87)',
			'400': 'rgba(0, 0, 0, 0.87)',
			'500': 'rgba(0, 0, 0, 0.87)',
			'600': '#ffffff',
			'700': '#ffffff',
			'800': '#ffffff',
			'900': '#ffffff',
			A100: 'rgba(0, 0, 0, 0.87)',
			A200: 'rgba(0, 0, 0, 0.87)',
			A400: 'rgba(0, 0, 0, 0.87)',
			A700: 'rgba(0, 0, 0, 0.87)',
		},
	},
};

const createPalette = (color: string) => ({
	main: get(base, `${color}.500`),
	dark: get(base, `${color}.800`),
	light: get(base, `${color}.200`),
	contrast: {
		main: get(base, `${color}.contrast.500`),
		dark: get(base, `${color}.contrast.800`),
		light: get(base, `${color}.contrast.200`),
	},
});

export const colors = {
	...base,
	primary: createPalette('green'),

	secondary: createPalette('blue'),

	warning: createPalette('orange'),

	error: createPalette('red'),

	success: {
		contrast: {
			dark: 'rgba(0, 0, 0, 0.87)',
			light: 'rgba(0, 0, 0, 0.87)',
			main: 'rgba(0, 0, 0, 0.87)',
		},
		dark: '#388e3c',
		light: '#81c784',
		main: '#4caf50',
	},

	info: {
		contrast: {
			dark: '#fff',
			light: 'rgba(0, 0, 0, 0.87)',
			main: 'rgba(0, 0, 0, 0.87)',
		},
		dark: '#1976d2',
		light: '#64b5f6',
		main: '#2196f3',
	},

	bg: {
		default: '#fff',
		level1: '#f8f9fa',
		level2: '#f5f5f5',
		paper: '#fff',
	},

	divider: {
		dark: '#c1c1c1',
		light: '#f4f4f4',
		main: '#e4e4e4',
	},

	action: {
		activatedOpacity: 0.12,
		active: 'rgba(0, 0, 0, 0.54)',
		disabled: 'rgba(0, 0, 0, 0.26)',
		disabledBackground: 'rgba(0, 0, 0, 0.12)',
		disabledOpacity: 0.38,
		focus: 'rgba(0, 0, 0, 0.12)',
		focusOpacity: 0.12,
		hover: 'rgba(0, 0, 0, 0.04)',
		hoverOpacity: 0.04,
		selected: 'rgba(0, 0, 0, 0.08)',
		selectedOpacity: 0.08,
	},

	text: {
		disabled: 'rgba(0, 0, 0, 0.38)',
		hint: 'rgba(0, 0, 0, 0.38)',
		primary: 'rgba(0, 0, 0, 0.87)',
		secondary: 'rgba(0, 0, 0, 0.54)',
		contrast: {
			disabled: 'rgba(255, 255, 255, 0.38)',
			hint: 'rgba(255, 255, 255, 0.38)',
			primary: 'rgba(255, 255, 255, 0.87)',
			secondary: 'rgba(255, 255, 255, 0.54)',
		},
	},
};
