import { isString } from "./assertion";
import { get } from "./object";

export const getError = (err: any) => {
	let error = get(err, 'message', get(err, 'msg', err));

	if (isString(error)) return error;
	if (!navigator.onLine) return "You're currently offline, try again later.";
	return 'Oops!  Something went wrong!';
};
