import { AfterContentInit, Component, ElementRef, Input } from '@angular/core';
import { proxyAttr } from '@utils';

//
// ─────────────────────────────────────────────────────────── ICON COMPONENT ─────
//

@Component({
	exportAs: 'Icon',
	selector: 'icon',
	template: `
		<i
			class="material-icons"
			sx
			h="{{ size }}"
			d="flex"
			alignItems="center"
			justifyContent="center"
			color="{{ colorScheme }}"
			fontSize="{{ size }} !important"
			lineHeight="{{ size }} !important"
		>
			{{ icon }}
		</i>
	`,
})
export class Icon implements AfterContentInit {
	/**
	 * The name of the icon.
	 */
	@Input() icon: string = 'info';

	/**
	 * The color of the icon.
	 */
	@Input() colorScheme: string = 'text.secondary';

	/**
	 * The size of the icon.
	 */
	@Input()
	public set size(val: any) {
		this._size = val;
	}
	public get size(): any {
		switch (this._size) {
			case 'xs':
				return '1rem';
			case 'sm':
				return '1.25rem';
			case 'md':
				return '1.5rem';
			case 'lg':
				return '2rem';
			case 'xl':
				return '2.5rem';
			default:
				return this._size;
		}
	}
	protected _size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';

	/* ---------------------------------- */

	constructor(private el: ElementRef) {}

	ngAfterContentInit() {
		proxyAttr(this.el, 'className');
	}
}
