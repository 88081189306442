import { Component, OnInit } from '@angular/core';
import { OrderListingService,AuthService, StaticTextService,ProgressBarService,SocketService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from '../app.constants';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss']
})
export class TrackerComponent implements OnInit {

  trackerOrderObj : any;
  constants = CONSTANTS;
  primaryColor = '#03a9f4';
  errMsg : any;
  allOrderStatus:any;
  partiallyDeliveredFlag : Boolean;
  awaitingClarificationFlag : Boolean;
   typingIndex : 0;
   qualityIndex:0;
   completedIndex:0;
   partiallyIndex:0;
   clarificationIndex:0;
   
  constructor(
    private orderList: OrderListingService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    public text: StaticTextService,
    private socket: SocketService,
    private progressServ: ProgressBarService,
  ) { }

  ngOnInit() {
   
    this.progressServ.setPrimaryColor(this.primaryColor);
		this.progressServ.setHeight(2);
		this.progressServ.setStage(0);
		this.progressServ.setProgressState((0 / 5) * 100);
		this.progressServ.setBarType(2);
		this.progressServ.setTotalStages(5);
    this.getTrackerOrder(this.route.snapshot.params['orderId']);
    this.socket.syncMessage("tracker").subscribe((result) => {
      this.getTrackerOrder(this.route.snapshot.params['orderId']);
    })
  }

  setProgressBarStatus(status, tagStatus) {
		var stage = this.getStage(status, tagStatus);
		this.progressServ.setStage(stage);
		this.progressServ.setProgressState(((stage - 1) / 4) * 100);
	}

  getStage(status,tagStatus) {
       this.typingIndex= tagStatus.findIndex((tagdata)=> tagdata === CONSTANTS.tagStatus.typingTag)
       this.qualityIndex= tagStatus.findIndex((tagdata)=> tagdata === CONSTANTS.tagStatus.qualityCheck)
       this.completedIndex= tagStatus.findIndex((tagdata)=> tagdata === CONSTANTS.tagStatus.completedTag)
       this.partiallyIndex= tagStatus.findIndex((tagdata)=> tagdata === CONSTANTS.tagStatus.partiallyDelivered)
       this.clarificationIndex= tagStatus.findIndex((tagdata)=> tagdata === CONSTANTS.tagStatus.awaitingClarification)

      if ((status == CONSTANTS.orderStatus.received ||status == CONSTANTS.orderStatus.assigned) && tagStatus[this.qualityIndex] != CONSTANTS.tagStatus.qualityCheck && tagStatus[this.completedIndex] != CONSTANTS.tagStatus.completedTag && tagStatus[this.typingIndex] != CONSTANTS.tagStatus.typingTag && tagStatus[this.partiallyIndex] != CONSTANTS.tagStatus.partiallyDelivered) {
        return 1;
      }
      else if ((status == CONSTANTS.orderStatus.confirmed || status == CONSTANTS.orderStatus.processing) && tagStatus[this.qualityIndex] != CONSTANTS.tagStatus.qualityCheck && tagStatus[this.completedIndex] != CONSTANTS.tagStatus.completedTag && tagStatus[this.typingIndex] != CONSTANTS.tagStatus.typingTag && tagStatus[this.partiallyIndex] != CONSTANTS.tagStatus.partiallyDelivered){
        return 2;
      } 
      else if (tagStatus[this.typingIndex] == CONSTANTS.tagStatus.typingTag && tagStatus[this.qualityIndex] != CONSTANTS.tagStatus.qualityCheck && tagStatus[this.completedIndex] != CONSTANTS.tagStatus.completedTag){
        return 3;
      }
      else if (tagStatus[this.qualityIndex] == CONSTANTS.tagStatus.qualityCheck && tagStatus[this.completedIndex] != CONSTANTS.tagStatus.completedTag){
        return 4;
      }
      else if(status == CONSTANTS.orderStatus.completed || tagStatus[this.completedIndex] == CONSTANTS.tagStatus.completedTag || tagStatus[this.partiallyIndex] == CONSTANTS.tagStatus.partiallyDelivered){
        return 5;
      } 
      
	}

  getTrackerOrder(orderId) {
		this.orderList.getOrderTrackerFeature(orderId)
    .subscribe((orderData)=>{
      this.errMsg = orderData.errMsg;
      this.trackerOrderObj = orderData;
      let tagArray = [];
    if(this.trackerOrderObj.Tag_Names)this.trackerOrderObj.Tag_Names.forEach((tagStatus)=> tagArray.push(tagStatus.Tag_Name.toLowerCase()))
      this.setProgressBarStatus(this.trackerOrderObj.Order_Status, tagArray);
      },
    err => {
      console.log(err);
    })
  }
  
}

