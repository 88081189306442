import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '@utils';

@Pipe({
	name: 'isNotEmpty',
})
export class IsNotEmptyPipe implements PipeTransform {
	transform(value: any) {
		return !isEmpty(value);
	}
}
