import { Component, OnInit } from '@angular/core';
import { PreloaderService } from '../preloader/preloader.service';
@Component({
  selector: 'app-search-loader',
  templateUrl: './search-loader.component.html',
  styleUrls: ['./search-loader.component.scss']
})
export class SearchLoaderComponent implements OnInit {

  constructor(
    public preloaderService: PreloaderService
  ) { }

  ngOnInit() {
    
  }

}
