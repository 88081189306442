import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as awsS3Service from '@services/aws-s3';
import { UUID } from 'angular2-uuid';
import * as S3 from 'aws-sdk/clients/s3';
import * as AWS from 'aws-sdk/global';
import { AuthService } from '../authentication/auth.service';
import { ConfigService } from '../config/config.service';

@Injectable()
export class AwsS3Service {
	// Adds the S3 service, make sure the api version and bucket are correct.
	private direct: HttpClient;
	private s3: S3;

	public progress: EventEmitter<any> = new EventEmitter();

	constructor(
		private auth: AuthService,
		private config: ConfigService,
		private handler: HttpBackend,
		private http: HttpClient
	) {
		this.direct = new HttpClient(this.handler);

		// Configures the AWS service and initial authorization.
		AWS.config.update({
			region: this.config.getS3Region(),
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: this.config.getS3Identity(),
			}),
		});

		this.s3 = new S3({
			apiVersion: this.config.getS3ApiVersion(),
			params: { Bucket: this.config.getS3Bucket() },
		});
	}

	private _httpUploadProgress = (filename: any, progress: any): void => {
		const toBeEmitted = {
			progress: progress,
			name: filename,
		};
		this.progress.emit(toBeEmitted);
	};

	private _send = (err: AWS.AWSError, data: S3.ManagedUpload.SendData): void => {};

	private generatePath = (orderId: string) =>
		new Date().getFullYear().toString() +
		'/' +
		('0' + (new Date().getMonth() + 1)).slice(-2) +
		'/' +
		new Date().getDate() +
		'/' +
		orderId.toString() +
		'/';

	private getFileExt = (fileName: string) => fileName.split('.').pop();

	public updateDocumentPath = async function (
		orderId: string,
		propertyId: string,
		file: { name: any },
		path: any,
		name: any,
	): Promise<awsS3Service.types.UpdateDocumentPathResponse> {
		const data = {
			Property_ID: propertyId,
			Order_ID: orderId,
			File: {
				Original_Name: file.name,
				Path: path,
				Name: name,
				Type: 2,
			},
			Created_By: this.auth.getUserId(),
		};
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + this.auth.getToken(),
			}),
		};
		const url = this.config.getBasePath() + '/orders/' + orderId + '/documentpath';

		try {
			const response = await this.http.post(url, data, httpOptions).toPromise();
			return response;
		} catch (error) {
			throw error.error;
		}
	};

	public onManagedUpload = async function (
		file: File,
		orderId: string,
	): Promise<awsS3Service.types.ManageUploadResponse> {
		const filename = UUID.UUID();
		const params: S3.Types.PutObjectRequest = {
			Key: this.generatePath(orderId) + filename + '.' + this.getFileExt(file.name),
			Bucket: this.config.getS3Bucket(),
			Body: file,
		};
		const options: S3.ManagedUpload.ManagedUploadOptions = {
			params: params,
			partSize: 10 * 1024 * 1024,
		};
		const handler: S3.ManagedUpload = new S3.ManagedUpload(options);

		handler.on('httpUploadProgress', this._httpUploadProgress.bind(this, file.name));
		handler.send(this._send.bind(this));

		const resposne = await handler.promise();
		return resposne;
	};
}
