import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation} from '@angular/core';
import { FormGroup, FormsModule, FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, StaticTextService, ConfigService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { CONSTANTS } from '../../app.constants';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangeComponent implements OnInit {

  changeFrm: FormGroup;
  changeModel: any = {};
  userData: any = {};
  error: string = '';
  errorType: string = '';
  errorMsg: any = {};
  wrongPwd: string = '';
  constants = CONSTANTS;
  @ViewChild('myfocus') myfocus:any;
  timer: any;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private config:ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    public text: StaticTextService,
    private dialogService: DialogService
  ) {
    this.changeFrm = fb.group({
      'Cur_Password':[null, Validators.compose([
        Validators.required,Validators.minLength(8), 
        Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),        
        ])],
      'password': [null, Validators.compose([
        Validators.required,Validators.minLength(8), 
        Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/),
        Validators.maxLength(255),       
        ])],
      'confirmpassword': [null, Validators.required],
    })
    this.changeFrm.valueChanges.subscribe(data => this.error = '');
  }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
    this.auth.loadHubSpotChatToken();
    this.timer=setTimeout(() => this.config.setSideBarNode(0), 0);
    this.myfocus.nativeElement.focus(); 
  }

  ngOnDestroy(){
    if(this.timer)clearTimeout(this.timer)
  }
  setPassword() {
    this.errorMsg.msg = '';
    // this.wrongPwd = '';
    this.userData['User_ID'] = this.auth.getUserId();
    this.userData['Cur_Password'] = this.changeModel.password;// user new password
    this.userData['User_Password'] = this.changeModel.Cur_Password;// user current password
    // if (this.changeModel.password != this.changeModel.confirmpassword) {

    //   this.wrongPwd = "Password do not match.";
    // } else {
    if(this.changeFrm.valid){
      this.auth.changePassword(this.userData)
        .subscribe((res) => {
          this.openDialog();
          this.auth.logout();
          this.router.navigate(['login']);
        }, (error) => {
          this.errorMsg = error;
          this.errorType = error.type;
        });
    } else {
      this.errorType = 'general';
      this.errorMsg.msg = 'Please enter correct details in all required fields';
    }
  }

  goToHome() {
    this.router.navigate(['orders/current'])
  }

  goToAccountInfo(){
    this.router.navigate(['user/'+this.auth.getUserId()+'/profile']);
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  openDialog() {
    let disposable = this.dialogService.addDialog(SuccessComponent, {
      title: this.text.get('LBL15'),
      message: this.text.get('TXT36')
    })
      .subscribe((res) => {
      });
  }
}
