import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ProgressBarService, AuthService, StaticTextService, StripeService, NewOrderService } from '@services';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Subject } from 'rxjs';
import {
	addressToObj,
	Dict,
	get,
	scrollToFocusElements,
	to,
	__DEV__,
	isObj,
} from '@utils';
import * as newOrderService from '@services/new-order';

@Component({
  selector: 'app-quote-payment',
  templateUrl: './quote-payment.component.html',
  styleUrls: ['./quote-payment.component.scss']
})
export class QuotePaymentComponent implements OnInit {

	@ViewChild('addressRef') addressRef: any;

	@ViewChild('nextStepBtn') nextStepRef: ElementRef

	@ViewChild('paymentMethod') paymentMethod: any;

	/* ---------------------------------- */

	error: any;

	isValid: boolean = false;

	isLoading: boolean = true;

	timer: any;

	states: any[];

	primaryColor: string = '#0F9D58';

  unsubscribe = new Subject();

  quoteDetails: any;

  quoteId: any; 
  constructor(private progressServ: ProgressBarService, private auth: AuthService,
    private cdr: ChangeDetectorRef, public staticText: StaticTextService,public order: newOrderService.service,
		protected stripe: StripeService, private router: Router, private route:ActivatedRoute ) { }

  ngOnInit() {
	this.auth.getHubSpotTokenDetails();
	this.auth.loadHubSpotChatToken();
    this.quoteId = this.route.snapshot.params['orderId'];
    if(!this.order.propertyData) this.router.navigate(['/quote/'+ this.quoteId+'/view']);
    this.progressServ.setPrimaryColor(this.primaryColor);
		this.progressServ.setHeight(2);
		this.progressServ.setStage(1);
		this.progressServ.setTotalStages(3);
		this.progressServ.setProgressState((1.5 / 3) * 100);
		this.progressServ.setBarType(1);
  }

  public handleCardChange = (card: Dict) => {
		this.isValid = this.addressRef.valid && get(this, 'paymentMethod.isValid');
		this.cdr.detectChanges();
		this.isValid && this.nextStepRef['focus']();
	};

  public handleCardLoaded = async (card: Dict) => {
		if (isObj(card)) {
			this.addressRef.patchValue(addressToObj(card));
		} else {
			const [err, address] = await to(this.auth.getDefaultAddress());
			if (!err) this.addressRef.patchValue(address);
		}
		this.isLoading = false;
		return this.cdr.detectChanges();
  };
  
  public handleCardSelect = (card: Dict) => {
		this.addressRef.patchValue(addressToObj(card));
		this.isValid = this.addressRef.valid && get(this, 'paymentMethod.isValid');
		this.cdr.detectChanges();		
		console.log("ref", this.nextStepRef)
		this.isValid && this.nextStepRef['focus']();
  };

  public handleValid = () => {
		if (this.paymentMethod.isValid && this.addressRef.valid) {
			this.isValid = true;
			this.cdr.detectChanges();
		}
  };

	public nextStep = async () => {
    this.order.billingData = this.addressRef.value;
		if (get(this, 'paymentMethod.cardId')) {
			this.order.stripeCard = get(this, 'paymentMethod.card');
			this.order.stripeCardId = get(this, 'paymentMethod.card.id');
			if(this.paymentMethod && this.paymentMethod.stripeUser) this.order.stripeCustomerId = get(this, 'paymentMethod.stripeUser.id');
			else this.order.stripeCustomerId = get(this, 'paymentMethod.card.customer');
		} else {
			try {
				const card = get(this, 'paymentMethod.newCard.card');
				const address = this.order.billingData;
				const orderToken = await this.stripe.createToken(card, address);
				const incidentalToken = await this.stripe.createToken(card, address);

				this.order.stripeCardId = null;
				this.order.stripeOrderToken = get(orderToken, 'id');
				this.order.stripeIncidentalToken = get(incidentalToken, 'id');
			} catch (error) {
				throw new Error(error);
			}
		}

		this.router.navigate([`/convert/${this.auth.getUserId()}/${this.order.quoteData.Order_ID}/step3`]);
  };
  
  goBack() {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          showPopup:true,
          quoteDetails:this.order.quoteData
        },
      };
      this.router.navigate(['/quote/'+ this.quoteId+'/view'], navigationExtras)
  }

}
