import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../authentication/auth.service';
import { ConfigService } from '../config/config.service';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { CONSTANTS } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  getQuoteReqData(pageNum, sortBy, searchStr, orderBy, data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let queryType 
    if (this.auth.isIndividualUser()) queryType = '/quotes/user/' + this.auth.getUserId();
    else queryType = '/quotes/organization/' + this.auth.getUserOrgId();
    let url = this.config.getBasePath() + queryType;
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
    }
  
    getQuoteDetails(quoteId) {
      let httpOptions = {
        headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/quotes/' + quoteId;
      return this.http.get(url, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)));
    }

    cancelQuoteRequest(quoteId) {
      let cancelQuoteObj = {
        Order_ID:quoteId,
        Order_Status:CONSTANTS.orderStatus.received
      }
      let httpOptions = {
        headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/quotes/client/' + this.auth.getUserId() + '/cancel';
      return this.http.put(url, cancelQuoteObj, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)));
    }
}
