import { isNil } from './assertion';
import { get } from './object';
import { Dict } from './types';

export function to(cb: any, options: Dict = {}) {
	const fallback = get(options, 'def');
	const parser = get(options, 'parser', 'array');
	const throwErrors = get(options, 'throw', false);

	if (Array.isArray(cb)) cb = Promise.all(cb);
	if (cb && cb.toPromise) cb = cb.toPromise();

	return cb
		.then((data: any) => {
			data = isNil(data) && fallback ? fallback : data;

			return throwErrors
				? data
				: parser === 'object'
				? { err: null, data }
				: [null, data];
		})
		.catch((error: any) => {
			if (throwErrors) {
				throw get(error, 'error', error);
			}
			return parser === 'object'
				? { err: get(error, 'error', error), data: null }
				: [get(error, 'error', error), null];
		});
}

to.obj = function (cb: any, options: Dict = {}) {
	return to(cb, { ...options, parser: 'object' });
};
