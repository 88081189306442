import {Observable, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../authentication/auth.service';
import {ConfigService} from '../config/config.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class ProductService {

  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient
  ) { }

  getProductRequirements(id) {
    let httpOptions = {
      headers: new HttpHeaders({'Authorization': 'Bearer ' + this.auth.getToken()})
    };
    let url = this.config.getBasePath() + '/products/' + id + '/client/requirements';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getProductEstimatedTime(id: string): Observable<{ Estimated_Time: string }> {
    const httpOptions = {headers: new HttpHeaders({'Authorization': 'Bearer ' + this.auth.getToken()})};
    const url = `${this.config.getBasePath()}/products/estimated/time`;

    return this.http.post<{ Estimated_Time: string }>(url, {Product_ID: id}, httpOptions);
  }

}

