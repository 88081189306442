import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { StaticTextService } from '../../services';
export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {
  @ViewChild('okBtn') focusedElement: ElementRef;
  // title: string;
  // message: string;
  title: any;
  message: any;
  timer: any;
  constructor(dialogService: DialogService, public text: StaticTextService) {
    super(dialogService);
  }
  confirm() {
    // we set dialog result as true on click on confirm button, 
    // then we can get dialog result from caller code 
    this.result = true;
    this.close();
  }
  
  ngOnInit() {
   this.timer=setTimeout(() => { this.focusedElement.nativeElement.focus() }, 0)
  }
  
  ngOnDestroy(){
    if(this.timer)clearTimeout(this.timer);
  }
}
