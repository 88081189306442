import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AuthService, StaticTextService } from '../../services';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../app.constants'
import * as momentTimeZone from 'moment-timezone';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-displaymessage',
  templateUrl: './displaymessage.component.html',
  styleUrls: ['./displaymessage.component.scss']
})
export class DisplaymessageComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {
  constants=CONSTANTS
  // title: string;
  // message: string;
  title: any;
  message: any;

  constructor(dialogService: DialogService, public text: StaticTextService, private router: Router, public auth: AuthService,) {
    super(dialogService);
  }
  confirm() {
    // we set dialog result as true on click on confirm button, 
    // then we can get dialog result from caller code 
    this.result = true;
    this.close();
  }

  Close() {
    this.result = false;
    this.close()
  }

  showOrderDetails(orderId) {
    
    this.close();
    this.router.navigate(['orders/' + orderId + '/view']);
  }

  reply(message) {
    this.result = message;
    
    this.close();
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

}
