import { Component, OnInit } from '@angular/core';
import { AuthService, InvoiceService, StaticTextService, ConfigService } from '../../services';
import * as moment from 'moment'
import { Router} from '@angular/router';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { CONSTANTS } from '../../app.constants'
import * as momentTimeZone from 'moment-timezone';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  filterBy = {};
  errors = {};
  orders = [];
  address = '';
  total = 0;
  availabledates = [];
  pdfFilter:any={};
  selectedPeriod: any;  
  maxFromDate:Date;
  maxToDate:Date;
  clientAddress: any = {};
  timer:any;
  constructor(
    private auth: AuthService,
    private invoiceService: InvoiceService,
    public text: StaticTextService,
    private router:Router,
    private dialogService:DialogService,
    private config:ConfigService
  ) { }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.filterBy['Customer_ID'] = this.auth.getUserId();    
    this.getClientAddress();    
    this.getCompletedOrderSpan();      
    this.timer=setTimeout(() => this.config.setSideBarNode(6), 0);
  }
  ngOnDestroy(){
    if(this.timer) clearTimeout(this.timer);
  }

  validateDateRange() {
    if (this.filterBy['fromDate'] && this.filterBy['toDate'] && (this.filterBy['fromDate'] > this.filterBy['toDate'])) {
      return false;
    } else {
      return true;
    }
  }

  setEvalperiod(event){    
    this.orders=[];
    if(event != -1){         
      this.filterBy['fromDate'] = moment().year(event.year).month(event.month).startOf('month').toDate();
      this.filterBy['toDate'] = moment().year(event.year).month(event.month).endOf('month').toDate();      
    }
    this.getInvoiceOrders();
  }

  getlastComplMonth() {
    var last = moment().subtract(1, 'months');    
    return {
      moment: last,
      month: last.format('MMMM'),
      year: last.year()
    }
  }

  validateDateFn(event) {
    var formattedDate=moment(event).format('L');
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (formattedDate && !DATE_REGEXP.test(formattedDate)) this.errors['date'] = true;
    else delete this.errors['date'];
  }

  getInvoiceOrders() {    
    if (!this.validateDateRange()) {      
      this.errors['Date_Range'] = true;
      this.orders = [];
    }
    else {      
      delete this.errors['Date_Range'];
      if (this.filterBy['fromDate'] && this.filterBy['toDate'] && !this.errors['date']) { 
        this.pdfFilter = Object.assign({},this.filterBy);       
        this.invoiceService.getInvoiceOrders(this.filterBy)
          .subscribe((res) => {            
            this.orders = res;
            this.orders.forEach((order, index) => {
              this.getOrderProducts(order.Order_ID, index)
            }, this)
            this.calculateTotal(this.orders)
          }, (err) => {
            
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
            this.auth.logout();
            this.router.navigate(['/login']);
          });
      }
    }
  }

  getOrderProducts(orderId, index) {
    this.invoiceService.getOrderProducts(orderId)
      .subscribe((res) => {        
        this.orders[index].Order_Products = res;
      }, (err) => {
        
      });
  }

  getClientAddress() {
    this.auth.getProfile()
      .subscribe((data) => {
        this.clientAddress = data;        
        this.buildAddress(data)
      }, (err) => {
        
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        this.auth.logout();
        this.router.navigate(['/login']);
      })
  }

  buildAddress(model) {
    this.address = '';
    if (model.Address_1) this.address += model.Address_1
    if (model.Address_2) {
      if (this.address) this.address += ' '
      this.address += model.Address_2
    }
    if (model.Address_City) {
      if (this.address) this.address += ', '
      this.address += model.Address_City
    }
    if (model.Address_State_Abbr) {
      if (this.address) this.address += ', '
      this.address += model.Address_State_Abbr
    }
    if (model.Address_ZipCode) {
      if (this.address) this.address += ' '
      this.address += model.Address_ZipCode
    }
  }

  calculateTotal(orders) {
    this.total = 0;
    orders.forEach((order) => {
      this.total += Number(order.Order_Custom_Price);
      this.total += Number(order.Order_Custom_Price_Tax)
    })
  }

  openErrorPopup(msg) {
    let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
      message: msg
    }).subscribe((res) => { });
  }

  getCompletedOrderSpan() {
    this.invoiceService.getInvoiceSpan()
      .subscribe((res) => {
        this.generateDateSpan(res, this.getlastComplMonth())
      });
  }

  generateDateSpan(start, end) {     
    if(start){
      var startMoment= moment(start);
      var endMoment=end.moment;  
      // while (endMoment.month() >= startMoment.month()) {
      while (endMoment.isSameOrAfter(startMoment,'month')) {  
        this.availabledates.push({ moment: endMoment, month: endMoment.format('MMMM'), year: endMoment.year() });
        endMoment.subtract(1, 'month');            
      }  
      if (!this.availabledates.length) this.availabledates.push(end);
    }     
    else this.availabledates.push(end);    
    this.selectedPeriod = this.availabledates[0];     
    this.filterBy['fromDate'] = moment().year(this.selectedPeriod.year).month(this.selectedPeriod.month).startOf('month').toDate();
    this.filterBy['toDate'] = moment().year(this.selectedPeriod.year).month(this.selectedPeriod.month).endOf('month').toDate();
    this.getInvoiceOrders();  
  }

  savePDF(){
    this.invoiceService.generatePDF(this.pdfFilter)
    .subscribe((userFile) => {
      var blob = new Blob(this.getByteArray(userFile), { type: 'application/octet-stream' });
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.download = 'invoice.pdf';
      link.href = window.URL.createObjectURL(blob);
      link.click();
      document.body.removeChild(link);
    });
  }

  getByteArray(file) {
    // var byteCharacters = atob(file);
    // var returnArray = [];
    // var byteNumbers = new Array(byteCharacters.length);
    // for (var i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // returnArray.push(new Uint8Array(byteNumbers));
    // return returnArray;
    var sliceSize = 512;

    var byteCharacters = atob(file);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  }
  validateAndresetToDate(event){
    this.validateDateFn(event)
    this.filterBy['toDate']=null;
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }
}
