
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit, HostListener } from '@angular/core';
import {
  AuthService, StaticTextService, MessagesService, NewOrderService, PagerService, ConfigService, PreloaderService,
  SocketService, OrderListingService, StateService, ScrollService
} from '../../services';
import { CONSTANTS } from '../../app.constants'
import { DialogService } from "ng2-bootstrap-modal";
import { DisplaymessageComponent } from '../../dialog/displaymessage/displaymessage.component';
import { MessageComponent } from '../../dialog/message/message.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import * as momentTimeZone from 'moment-timezone';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  messages = [];
  constants = CONSTANTS;
  pageNum: number;
  pager: any = {};
  searchString: string;
  searchInProgress: boolean = false;
  sortField: string;
  orderBy: string;
  totalCount: number;
  messagesToDisplay: any;
  curMsg = 0;
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  searchQuery = new Subject<string>();
  timer:any=[];
    constructor(
    private auth: AuthService,
    public text: StaticTextService,
    private msgService: MessagesService,
    private dialogService: DialogService,
    private newOrder: NewOrderService,
    private pagerService: PagerService,
    private preloaderService: PreloaderService,
    private config: ConfigService,
    private orderList: OrderListingService,
    private router: Router,
    private route: ActivatedRoute,
    private socket: SocketService,
    private stateService: StateService,
    private scrollService: ScrollService
  ) {
    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
    this.orderBy = 'DESC';
    this.searchString = '';
    this.sortField = 'Message_Creation_Date';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    if (this.stateService.getmessages()) {
      this.initializeComponentData(this.stateService.getmessages());
    }
    else this.getInitialMessages();
    this.socket.syncMessage("message").subscribe((result) => {
      
      this.getInitialMessagesBg();
    });
    this.timer.push(setTimeout(() => this.config.setSideBarNode(4), 0));
  }

  sendMsgToAdmin(msg,type){
    let b=this.auth.getUserId();
    // alert("This Is id==>"+b);
    let disposable = this.dialogService.addDialog(MessageComponent, {
      title: 'Direct Message',
    })
    .subscribe((res)=>{
      if(res) this.sendMessage(res);
    })
  }

  sendMessage(msg){
    var data={
      Message_Text:msg,
      Sender_ID: this.auth.getUserId()
    }
    this.msgService.sendMessage(data)
    .subscribe((res) => {
      this.openSuccessDialog();
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
    })
  }
  openSuccessDialog() {
    let disposable = this.dialogService.addDialog(SuccessComponent, {
      title: 'Success',
      message: 'Message Sent Successfully.'
    })
      .subscribe((res) => {
      });
  }

  goToNewOrder() {
    this.newOrder.goToNewOrder()
  }

  getUnreadCount() {
    this.msgService.getAllUnreadCount()
      .subscribe((data) => {
      }, (err) => {
        
      })
  }

  showMsg(msg, msgIdx) {
    this.curMsg = msgIdx;
    if(msg.Client_MsgRead_Status != CONSTANTS.messageStatus.read){
    this.msgService.updateMsgToRead(msg)
      .subscribe((data) => {
        this.getInitialMessagesBg();
        this.getUnreadCount();
      }, (err) => {
        
      })
    }
    if (msg.Parent_Message_ID) {
      this.msgService.getAllMsgsByParentId(msg.Parent_Message_ID)
        .subscribe((allMsgs) => {
          msg.allMsg = allMsgs;
          this.showAllMsgs(msg);
        }, (err) => {
          
        })
    } else {
      this.msgService.getMsgData(msg.Message_ID)
        .subscribe((data) => {
          if(msg.Order_Is_Quote)  data['Order_Is_Quote'] = msg.Order_Is_Quote;
          this.showAllMsgs(data);
        }, (err) => {
          
        })
    }
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosMessages(getPos)
  }


  openErrorPopup(msg) {
    let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
      message: msg
    }).subscribe((res) => { });
  }

  updateUnreadMsgStatus(ids, statuses) {
    statuses.forEach((status, index) => {
      if (status == '1') this.updateToread(Number(ids[index]));
    })
  }

  showAllMsgs(msg) {
    this.msgService.getMsgData(msg.Message_ID)
      .subscribe((data) => {
        this.openMessageInterface(msg, data);
      }, (err) => {
        
      })
  }



  updateParentId(msgData) {
    return this.msgService.updateParentId(msgData)
  }




  updateToread(msgId) {
    this.msgService.updateToRead(msgId)
      .subscribe((data) => {
        this.getUnreadCount();
        this.getInitialMessagesBg();
      }, (err) => {
        
      })
  }

  openMessageInterface(allMsg, msgDataToSent) {
    let title = '';
    let t1, t2, t3 = "";
    if (!msgDataToSent.Order_ID) t1 = 'Direct Message'
    else {
      if(Number(allMsg.Order_Is_Quote)) t1 = "Quote ";
      else t1 = "Order ";
      t2 = msgDataToSent.Titles_ID;
      t3 = "Messages";
    }
    let disposable = this.dialogService.addDialog(MessageComponent, {
      t1: t1,
      t2: t2,
      t3: t3,
      message: allMsg
    })
      .subscribe((res) => {
        if (res) {
          msgDataToSent.Message_Text = res;
          if (allMsg.Parent_Message_ID) msgDataToSent.Parent_Message_ID = allMsg.Parent_Message_ID;
          else msgDataToSent.Parent_Message_ID = allMsg.Message_ID;
          msgDataToSent.SenderId = this.auth.getUserId();
          allMsg.msgDataToSent = msgDataToSent;
          if (!msgDataToSent.Order_ID) msgDataToSent.Order_ID = null;
          if (!allMsg.Parent_Message_ID) this.updateParentId(allMsg)
            .subscribe((data) => {
              if (data) {
                this.getInitialMessagesBg()
                let disposable = this.dialogService.addDialog(SuccessComponent, {
                  title: 'Success',
                  message: 'Message has been sent successfully.'
                })
                  .subscribe((res) => {
                  });
              }
            }, (err) => {
              
              this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
            })
          else if (msgDataToSent.Order_ID) this.sendMessageWithOrderId(res, msgDataToSent, allMsg);
          else this.sendMessageWithoutOrderId(res, msgDataToSent, allMsg);
        }
      });
  }

  sendMessageWithOrderId(text, msgDataToSent, allMsg) {
    var data = {
      Message_Text: text,
      Parent_Message_ID: msgDataToSent.Parent_Message_ID
    }
    this.orderList.sendMessage(msgDataToSent.Order_ID, data)
      .subscribe((data) => {
        if (data) {
          this.getInitialMessagesBg()
          let disposable = this.dialogService.addDialog(SuccessComponent, {
            title: 'Success',
            message: 'Message has been sent successfully.'
          })
            .subscribe((res) => {
            });
        }
      }, (err) => {
        
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }


  sendMessageWithoutOrderId(msgText, msgDataToSent, allMsg) {
    var data = {
      Message_Text: msgText,
      Parent_Message_ID: msgDataToSent.Parent_Message_ID
    }
    this.orderList.sendDirectClientMessage(data)
      .subscribe((result) => {
        if (result) {
          this.getInitialMessagesBg()
          let disposable = this.dialogService.addDialog(SuccessComponent, {
            title: 'Success',
            message: 'Message has been sent successfully.'
          })
            .subscribe((res) => {
            });
        }
      }, (err) => {
        
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getInitialMessages();
  }

  setDisplayMessages() {
    let start = ((this.pageNum - 1) * this.config.getNoOfRecordsPerPage());
    this.messagesToDisplay = this.messages.slice(start, this.totalCount);
    if (this.messagesToDisplay.length > this.config.getNoOfRecordsPerPage()) {
      this.messagesToDisplay = this.messagesToDisplay.slice(0, this.config.getNoOfRecordsPerPage());
    }
  }

  getMessages() {
    this.pageNum = 1;
    this.stateService.setmessages(this);
    this.msgService.getAllUserMessages(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
      .subscribe((result) => {
      this.messages = result.rows;
        this.getMessagesRecordData(result)
      }, () => {
        this.searchInProgress = false;
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getInitialMessages() {
    if(this.pageNum == 1) {
      this.stateService.setmessages(this);
      this.msgService.getAllBackGroundUserMessages(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
      .subscribe((result) => {
        this.getMessagesData(result);
      }, (err) => {
          this.searchInProgress = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
    }else this.getRetainData()
  }
  getRetainData(){
    var ObsCollection = [];
    for(var i=1; i<=this.pageNum; i++){
      this.stateService.setmessages(this)
      ObsCollection.push(this.msgService.getAllUserMessages(i, this.sortField, this.orderBy, this.getSearchString()))
    }
    observableForkJoin(ObsCollection)
    .subscribe((retainedMsg) => {
      if(retainedMsg) this.getRetainedMsg(retainedMsg);
    })
  }

  getInitialMessagesBg() {
    if(this.pageNum == 1){
      this.msgService.getAllBackGroundUserMessages(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
      .subscribe((result) => {
        this.getMessagesData(result);
      }, (err) => {
          this.searchInProgress = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
    }else this.getRetainDataBg()
  }

  getRetainDataBg(){
    var ObsCollection = [];
    for(var i=1; i<=this.pageNum; i++){
      ObsCollection.push(this.msgService.getAllBackGroundUserMessages(i, this.sortField, this.orderBy, this.getSearchString()))
    }
    observableForkJoin(ObsCollection)
    .subscribe((retainedMsg) => {
      if(retainedMsg) this.getRetainedMsg(retainedMsg);
    })
  }

  searchField(search){
    this.scrollService.setScrollPosMessages(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getMessages();
    }else this.findMeaasges();
  }

  findMeaasges() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getInitialMessages();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.getMessages = data.getMessages;
    this.searchInProgress = data.searchInProgress;
    this.getInitialMessages();
  }

  hasUnreadmsg(msg) {
    if (msg.Admin_MsgRead_Status) {
      if (msg.Client_MsgRead_Status == CONSTANTS.messageStatus.unread) {
        return true;
      }
      else false;
    }
  }

  getSearchString() {
    var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }
  handleEvent(event){
    this.scrollEnable=event;
  }
  
  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }
  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.msgService.getAllUserMessages(this.pageNum, this.sortField, this.orderBy, this.getSearchString())
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag();
            this.totalCount = result.count;
            if (this.totalCount > this.messages.length) {
              this.messages = this.messages.concat(result.rows);
              this.pager = this.pagerService.getPagerForRecords(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getMessagesRecordData(result) {
      this.config.resetShowFlag();
      this.config.setClearTimeout();  
      this.setDisplayMessages();
      this.pager = this.pagerService.getPagerForRecords(result, this.pageNum);
      this.searchInProgress = false;
      this.config.setDelayRecords();
  }

  getScrollPosData(){
    if(this.scrollService.getScrollPosMessages()){
      var x = this.scrollService.getScrollPosMessages();
      this.timer.push(setTimeout(function() {
        window.scrollTo(0,x);
      }))
    }
  }

  getMessagesData(result){
    this.messages = result.rows;
    this.totalCount = result.count;                
    this.getMessagesRecordData(this.totalCount)
    this.getScrollPosData();
  }

  getRetainedMsg(retainedMsg){
    var totalOrders = [];
    for(var i=0;i<retainedMsg.length;i++){
      totalOrders = totalOrders.concat(retainedMsg[i]['rows']);
    }
    this.messages = totalOrders;
    this.totalCount = retainedMsg[0]['count'];
    this.getMessagesRecordData(this.totalCount);
    this.getScrollPosData();
  }

  goToOrderDetails(event, orderId, orderQuoteFlag) {
    event.stopPropagation();
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosMessages(getPos)
    if(Number(orderQuoteFlag)) this.router.navigate(['/quote/' + orderId + '/view'])
    else this.router.navigate(['/orders/' + orderId + '/view']);
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  ngOnDestroy(){
    if(this.timer){
      this.timer.forEach(time=> clearTimeout(time))
    }
    }
}
