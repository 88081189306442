import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AuthService, StaticTextService } from '../../services';
import { CONSTANTS } from '../../app.constants'
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent extends DialogComponent<any, any> {
  @ViewChild('okBtn') focusedElement: ElementRef;

  title: any;
  message: any;
  constructor(dialogService: DialogService, public text: StaticTextService,  public auth: AuthService,) {
    super(dialogService);   
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    
    if (this.focusedElement != undefined) {
      this.focusedElement.nativeElement.focus();
    }
  }

  Close() {
    this.result = false;
    this.close()
  }

}
