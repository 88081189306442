
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class SettingsService {

  constructor(
    private router:Router,
    private auth:AuthService,
    private config: ConfigService,
    private http:HttpClient
  ) { }

  getNotificationFlags(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url= this.config.getBasePath() + '/notifications/systemflag';
    return this.http.get(url, httpOptions).pipe(
    map((response:any) => {
      
      return response;
    }),
    catchError((error:any) => observableThrowError(error.error)),);
  }

  getNotificationFlagValue(flagId){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url= this.config.getBasePath() + '/notifications/user/'+ this.auth.getUserId() + '/flag/' + flagId +'/status';
    return this.http.get(url, httpOptions).pipe(
    map((response:any) => {
      
      return response;
    }),
    catchError((error:any) => observableThrowError(error.error)),);
  }

  saveFlagValues(model){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body' //to get full response
    }
    let url= this.config.getBasePath() + '/notifications/setflag';
    return this.http.post(url, model , httpOptions).pipe(
    map((response:any) => {
      
      return response;
    }),
    catchError((error:any) => observableThrowError(error.error)),);
  }

}