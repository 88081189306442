import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { StripeService } from '@services';
import { cxs } from '@styled';
import { Dict, get } from '@utils';
import { PaymentMethodDelete } from './payment-method-delete.component';
import { PaymentMethodEdit } from './payment-method-edit.component';

@Component({
	selector: 'payment-method-card-skeleton',
	template: `
		<div
			sx
			w="100%"
			h="20"
			pr="2"
			pl="5"
			d="flex"
			alignItems="center"
			border="1px solid divider.main"
			borderRadius="md"
			transition="300ms all"
		>
			<h-stack sx spacing="4" align="center" flex="1">
				<skeleton-circle size="8"></skeleton-circle>
				<skeleton-text noOfLines="2" sx w="32"></skeleton-text>
			</h-stack>
			<h-stack sx mr="3" d="['none', null, 'flex']">
				<skeleton sx w="16" h="8"></skeleton>
				<skeleton sx w="16" h="8"></skeleton>
			</h-stack>
			<skeleton-circle
				size="8"
				sx
				borderRadius="sm"
				d="['block', null, 'none !important']"
				mr="2"
			></skeleton-circle>
		</div>
	`,
})
export class PaymentMethodCardSkeleton {}

@Component({
	selector: 'payment-method-card',
	template: `
		<!-- loading -->
		<payment-method-card-skeleton *ngIf="isLoading"></payment-method-card-skeleton>

		<!-- loaded -->
		<div
			*ngIf="!isLoading"
			sx
			w="100%"
			h="20"
			pr="2"
			pl="5"
			d="flex"
			alignItems="center"
			border="1px solid divider.main"
			borderRadius="md"
		>
			<h-stack sx spacing="4" align="center" flex="1">
				<img
					sx
					w="8"
					h="6"
					border="1px solid divider.main"
					borderRadius="sm"
					objectFit="cover"
					overflow="hidden"
					alt="card"
					src="../../../assets/images/card-networks/{{
						stripe.getCardImage(card?.brand)
					}}.png"
				/>
				<v-stack sx flex="1" color="text.secondary" spacing="0" align="flex-start">
					<h-stack>
						<span sx variant="h6">{{ card?.brand }}</span>
						<span sx variant="p">••••</span>
						<span sx variant="h6">{{ card?.last4 }}</span>
					</h-stack>
					<small sx variant="caption" color="text.hint">
						Exp {{ card?.exp_month }}/{{ card?.exp_year }}
					</small>
				</v-stack>

				<!-- actions -->
				<ng-container *ngIf="!actionsAreDisabled">
					<!-- md/lg screen actions -->
					<flex sx mr="0 !important" d="['none', null, 'flex']">
						<button (click)="openEditPaymentMethod(card)" class="btn btn-primary ml-11">
							Edit
						</button>
						<button (click)="openDeletePaymentMethod(card)" class="btn btn-default ml-11">
							Remove
						</button>
					</flex>
					<!-- small screen actions -->
					<mat-menu #menu="matMenu" xPosition="before">
						<button (click)="openEditPaymentMethod(card)" mat-menu-item>Edit</button>
						<button (click)="openDeletePaymentMethod(card)" mat-menu-item>Remove</button>
					</mat-menu>
					<button
						[matMenuTriggerFor]="menu"
						mat-icon-button
						sx
						variant="button.icon"
						d="['block', null, 'none !important']"
					>
						<icon icon="more_vert"></icon>
					</button>
				</ng-container>
			</h-stack>
		</div>
	`,
})
export class PaymentMethodCard {
	/**
	 * If true card actions are rendered, otherwise they are not.
	 */
	@Input() actionsAreDisabled: boolean = false;

	/**
	 * Stripe credit card
	 */
	@Input() card: Dict;

	/**
	 * If true render the content skeleton.
	 */
	@Input() isLoading: boolean = false;

	/**
	 * Callback for when `card` has been deleted.
	 */
	@Output() onDelete: EventEmitter<any> = new EventEmitter();

	/**
	 * Callback for when `card` has been deleted.
	 */
	@Output() onUpdate: EventEmitter<any> = new EventEmitter();

	/* ---------------------------------- */

	constructor(public dialog: MatDialog, protected stripe: StripeService) {}

	/* ---------------------------------- */

	openEditPaymentMethod = (card: Dict) =>
		this.dialog
			.open(PaymentMethodEdit, {
				autoFocus: false,
				data: { card },
				panelClass: cxs({ 'mat-dialog-container': { p: 0 } }),
				width: '500px',
			})
			.afterClosed()
			.subscribe(this.handleEditPaymentMethod);

	handleEditPaymentMethod = (card: Dict) => {
		if (card) this.onUpdate.emit(card);
	};

	/* ---------------------------------- */

	openDeletePaymentMethod = (card: Dict) =>
		this.dialog
			.open(PaymentMethodDelete, {
				data: { card },
				panelClass: cxs({ 'mat-dialog-container': { p: 0 } }),
				width: '400px',
			})
			.afterClosed()
			.subscribe(this.handleDeletePaymentMethod);

	handleDeletePaymentMethod = (res: Dict) => {
		const deleted = get(res, 'deleted');
		if (deleted) this.onDelete.emit(get(res, 'id'));
	};
}
