import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services';

@Component({
	selector: 'app-stripe-ach-payment',
	templateUrl: './stripe-ach-payment.component.html',
	styleUrls: ['./stripe-ach-payment.component.scss'],
})
export class StripeAchPaymentComponent implements OnInit {
	_totalAmount: number;
	bankAccountForm: FormGroup;

	constructor(private dialogRef: MatDialogRef<StripeAchPaymentComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
							private fb: FormBuilder, private auth: AuthService) {
		this._totalAmount = data['totalAmount'];
	}

	ngOnInit() {
		this.initialForm();
	}

	initialForm() {
		const firstName = this.auth.getFirstName();
		const lastName = this.auth.getLastName();
		const type = this.auth.isIndividualUser() ? 'individual' : 'company';
		this.bankAccountForm = this.fb.group({
			'first_name': [firstName, [Validators.required]],
			'last_name': [lastName, [Validators.required]],
			'account_holder_type': [type, [Validators.required]],
			'routing_number': ['110000000', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
			'account_number': ['000123456789', [Validators.required]],
		});

		// numbers for testing:
		// Routing number: 110000000

		// Account number:
		// 000123456789 (success)
		// 000111111116 (failure upon use)
		// 000111111113(account closed)
		// 000222222227 (NSF/insufficient funds)
		// 000333333335 (debit not authorized)
		// 000444444440 (invalid currency)
	}

	async getToken() {
		const values = this.bankAccountForm.value;
		const stripeData = {
			country: 'US',
			currency: 'usd',
			routing_number: values.routing_number,
			account_number: values.account_number,
			account_holder_name: `${values.first_name} ${values.last_name}`,
			account_holder_type: values.account_holder_type,
		};

		const {token, error} = await stripe.createToken('bank_account', stripeData);

		if (token)
			this.dialogRef.close({token});
	}

	get controls() {return this.bankAccountForm.controls;}

	getError(field: string): string {
		const formField = this.bankAccountForm.controls[field] as FormControl;
		if (this.hasError(formField)) {
			const errors = formField.errors;
			let message: string = '';
			if (errors.required) {
				message = `This field is required.`;
			}
			else if (errors.maxLength) {
				message = `Length must be ${errors.maxlength.requiredLength}`;
			}
			else if (errors.minLength) {
				message = `Length must be ${errors.minlength.requiredLength}, actual is ${errors.minlength.actualLength}`;
			}

			return message;
		}
	}

	hasError(field: FormControl): boolean {
		return !!((field.touched || field.dirty) && field.errors);
	}

	showInfo(field: string) {
		if (field === 'account_number') {

		}
		else if (field === 'routing_number') {

		}
	}

	hideInfo(field: string) {
		if (field === 'account_number') {

		}
		else if (field === 'routing_number') {

		}
	}

}
