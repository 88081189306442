// import {catchError, finalize, tap} from 'rxjs/operators';
// import { Injectable } from '@angular/core';
// import { Http, ConnectionBackend, Request, RequestOptions, RequestOptionsArgs, Response, Headers } from '@angular/http';

// import 'rxjs/Rx';
// import { PreloaderService } from '../preloader/preloader.service';
// import { CookieService } from 'ngx-cookie';
// import { Router } from '@angular/router';

// @Injectable()
// export class HttpService extends Http {

//   constructor(backend: ConnectionBackend,
//               defaultOptions: RequestOptions,
//               private preloaderService: PreloaderService,
//               private cookieService:CookieService,
//               private router:Router) {
//     super(backend, defaultOptions);
//   }

//   /**
//    * Performs any type of http request.
//    * @param url
//    * @param options
//    * @returns {Observable<Response>}
//    */
//   request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
//     return super.request(url, options);
//   }

//   /**
//    * Performs a request with `get` http method.
//    * @param url
//    * @param options
//    * @param preloaderType
//    * @returns {Observable<>}
//    */
//   //get(url: string, options?: RequestOptionsArgs, preloaderType?: string): Observable<any> {
//   get(url: string, options?: RequestOptionsArgs): Observable<any> {

//     this.requestInterceptor();

//     let fullUrl = this.getFullUrl(url);

//     return super.get(fullUrl, this.requestOptions(options)).pipe(
//       catchError(this.onCatch),
//       tap((res: Response) => {

//         this.onSubscribeSuccess(res);
//       }, (error: any) => {

//         this.onSubscribeError(error);
//       }),
//       finalize(() => {

//         this.onFinally();
//       }),);
//   }

//   /**
//    * Performs a request with `post` http method.
//    * @param url
//    * @param body
//    * @param options
//    * @returns {Observable<>}
//    */
//   post(url: string, body: any, options?: RequestOptionsArgs): Observable<any> {
//     this.requestInterceptor();
//     let fullUrl = this.getFullUrl(url);

//     return super.post(fullUrl, body, this.requestOptions(options)).pipe(
//       catchError(this.onCatch),
//       tap((res: Response) => {
//         this.onSubscribeSuccess(res);
//       }, (error: any) => {
//         this.onSubscribeError(error);
//       }),
//       finalize(() => {
//         this.onFinally();
//       }),);
//   }

//   /**
//    * Performs a request with `put` http method.
//    * @param url
//    * @param body
//    * @param options
//    * @returns {Observable<>}
//    */
//   put(url: string, body: any, options?: RequestOptionsArgs): Observable<any> {
//
//     this.requestInterceptor();

//     return super.put(this.getFullUrl(url), body, this.requestOptions(options)).pipe(
//       catchError(this.onCatch),
//       tap((res: Response) => {
//         this.onSubscribeSuccess(res);
//       }, (error: any) => {
//         this.onSubscribeError(error);
//       }),
//       finalize(() => {
//         this.onFinally();
//       }),);
//   }

//   /**
//    * Performs a request with `delete` http method.
//    * @param url
//    * @param options
//    * @returns {Observable<>}
//    */
//   delete(url: string, options?: RequestOptionsArgs): Observable<any> {
//
//     this.requestInterceptor();

//     return super.delete(this.getFullUrl(url), options).pipe(
//       catchError(this.onCatch),
//       tap((res: Response) => {
//         this.onSubscribeSuccess(res);
//       }, (error: Error) => {
//         this.onSubscribeError(error);
//       }),
//       finalize(() => {
//         this.onFinally();
//       }),);
//   }

//   //

//   /**
//    * Build full URL for request.
//    * @param str
//    * @returns {string}
//    */
//   private getFullUrl(str): string {
//     // return this.configService.getBasePath() + '/' + str;
//     return str;
//   }

//   /**
//    * Request options.
//    * @param options
//    * @returns {RequestOptionsArgs}
//    */
//   private requestOptions(options?: RequestOptionsArgs): RequestOptionsArgs {
//     if (options == null) {
//       options = new RequestOptions();
//     }

//     if (options.headers == null) {
//       options.headers = new Headers();
//     }

//     return options;
//   }

//   /**
//    * Request interceptor.
//    */
//   //private requestInterceptor(preloaderType = 'full'): void {
//   private requestInterceptor(): void {
//     this.preloaderService.showPreloader();
//   }

//   /**
//    * Response interceptor.
//    */
//   //private responseInterceptor(preloaderType = 'full'): void {
//   private responseInterceptor(): void {
//     this.preloaderService.hidePreloader();
//   }

//   /**
//    * Error handler.
//    * @param error
//    * @param caught
//    * @returns {ErrorObservable}
//    */
//   private onCatch(error: any): Observable<any> {
//
//     return observableThrowError(error);
//   }

//   /**
//    * onSubscribeSuccess
//    * @param res
//    */
//   private onSubscribeSuccess(res: Response): void {
//
//   }

//   /**
//    * onSubscribeError
//    * @param error
//    */
//   private onSubscribeError(error: any): void {
//     //
//     if(error.status == 401 ){
//
//
//       this.cookieService.remove('pippinTitleUsr');
//       this.router.navigate(['/login']);
//     }
//   }

//   /**
//    * onFinally
//    */
//  // private onFinally(preloaderType = 'full'): void {
//   private onFinally(): void {
//     this.responseInterceptor();
//   }
// }

import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpResponse,
} from '@angular/common/http';
import { PreloaderService } from '../preloader/preloader.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import 'rxjs/add/observable/throw';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable()
export class HttpService implements HttpInterceptor {
	constructor(
		private preloaderService: PreloaderService,
		private cookieService: CookieService,
		private router: Router,
		private config: ConfigService
	) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.requestInterceptor();
		return next.handle(request).pipe(
			catchError(this.onCatch),
			tap(
				(ev: HttpEvent<any>) => {
					if (ev instanceof HttpResponse) {
						this.onSubscribeSuccess(ev);
					}
				},
				error => {
					this.onSubscribeError(error);
				},
			),
			finalize(() => this.onFinally()),
		);
	}

	/**
	 * onSubscribeError
	 * @param error
	 */
	private onSubscribeError(error: any): void {
		if (error.status == 401) {
			this.cookieService.remove('pippinTitleUsr');
			this.router.navigate(['/login']);
		} else if (error.status == 404 || error.status == 0){
			this.cookieService.remove('pippinTitleUsr');
			this.config.setSideBarNode(0)
			this.router.navigate(['/maintenance'])
		  }
	}

	/**
	 * onFinally
	 */
	// private onFinally(preloaderType = 'full'): void {
	private onFinally(): void {
		this.responseInterceptor();
	}

	/**
	 * Request interceptor.
	 */
	//private requestInterceptor(preloaderType = 'full'): void {
	private requestInterceptor(): void {
		if (!this.preloaderService.preloaderIsDisabled) this.preloaderService.showPreloader();
	}

	/**
	 * Response interceptor.
	 */
	//private responseInterceptor(preloaderType = 'full'): void {
	private responseInterceptor(): void {
		if (!this.preloaderService.preloaderIsDisabled) this.preloaderService.hidePreloader();
	}

	/**
	 * Error handler.
	 * @param error
	 * @param caught
	 * @returns {ErrorObservable}
	 */
	private onCatch(error: any): Observable<any> {
		return observableThrowError(error);
	}

	/**
	 * onSubscribeSuccess
	 * @param res
	 */
	private onSubscribeSuccess(res: any): void {}
}
