export const scales = {
	backgroundColor: 'colors',
	blockSize: 'sizes',
	border: 'borders',
	borderBlock: 'borders',
	borderBlockEnd: 'borders',
	borderBlockEndStyle: 'borderStyles',
	borderBlockEndWidth: 'borderWidths',
	borderBlockStart: 'borders',
	borderBlockStartStyle: 'borderStyles',
	borderBlockStartWidth: 'borderWidths',
	borderBlockStyle: 'borderStyles',
	borderBlockWidth: 'borderWidths',
	borderBottom: 'borders',
	borderBottomColor: 'colors',
	borderBottomLeftRadius: 'radii',
	borderBottomRightRadius: 'radii',
	borderBottomStyle: 'borderStyles',
	borderBottomWidth: 'borderWidths',
	borderColor: 'colors',
	borderEndEndRadius: 'radii',
	borderEndStartRadius: 'radii',
	borderInline: 'borders',
	borderInlineEnd: 'borders',
	borderInlineEndStyle: 'borderStyles',
	borderInlineEndWidth: 'borderWidths',
	borderInlineStart: 'borders',
	borderInlineStartStyle: 'borderStyles',
	borderInlineStartWidth: 'borderWidths',
	borderInlineStyle: 'borderStyles',
	borderInlineWidth: 'borderWidths',
	borderLeft: 'borders',
	borderLeftColor: 'colors',
	borderLeftStyle: 'borderStyles',
	borderLeftWidth: 'borderWidths',
	borderRadius: 'radii',
	borderRight: 'borders',
	borderRightColor: 'colors',
	borderRightStyle: 'borderStyles',
	borderRightWidth: 'borderWidths',
	borderStartEndRadius: 'radii',
	borderStartStartRadius: 'radii',
	borderStyle: 'borderStyles',
	borderTop: 'borders',
	borderTopColor: 'colors',
	borderTopLeftRadius: 'radii',
	borderTopRightRadius: 'radii',
	borderTopStyle: 'borderStyles',
	borderTopWidth: 'borderWidths',
	borderWidth: 'borderWidths',
	bottom: 'space',
	boxShadow: 'shadows',
	caretColor: 'colors',
	color: 'colors',
	columnGap: 'space',
	columnRuleColor: 'colors',
	fill: 'colors',
	flexBasis: 'sizes',
	fontFamily: 'fonts',
	fontSize: 'fontSizes',
	fontWeight: 'fontWeights',
	gap: 'space',
	gridColumnGap: 'space',
	gridGap: 'space',
	gridRowGap: 'space',
	height: 'sizes',
	inlineSize: 'sizes',
	inset: 'space',
	insetBlock: 'space',
	insetBlockEnd: 'space',
	insetBlockStart: 'space',
	insetInline: 'space',
	insetInlineEnd: 'space',
	insetInlineStart: 'space',
	left: 'space',
	letterSpacing: 'letterSpacings',
	lineHeight: 'lineHeights',
	margin: 'space',
	marginBlock: 'space',
	marginBlockEnd: 'space',
	marginBlockStart: 'space',
	marginBottom: 'space',
	marginInline: 'space',
	marginInlineEnd: 'space',
	marginInlineStart: 'space',
	marginLeft: 'space',
	marginRight: 'space',
	marginTop: 'space',
	marginX: 'space',
	marginY: 'space',
	maxBlockSize: 'sizes',
	maxHeight: 'sizes',
	maxInlineSize: 'sizes',
	maxWidth: 'sizes',
	minBlockSize: 'sizes',
	minHeight: 'sizes',
	minInlineSize: 'sizes',
	minWidth: 'sizes',
	opacity: 'opacities',
	outlineColor: 'colors',
	padding: 'space',
	paddingBlock: 'space',
	paddingBlockEnd: 'space',
	paddingBlockStart: 'space',
	paddingBottom: 'space',
	paddingInline: 'space',
	paddingInlineEnd: 'space',
	paddingInlineStart: 'space',
	paddingLeft: 'space',
	paddingRight: 'space',
	paddingTop: 'space',
	paddingX: 'space',
	paddingY: 'space',
	right: 'space',
	rowGap: 'space',
	size: 'sizes',
	stroke: 'colors',
	textShadow: 'shadows',
	top: 'space',
	width: 'sizes',
	zIndex: 'zIndices',
};
