import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { StaticTextService } from '../../services';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends DialogComponent<any, any> {
  @ViewChild('okBtn') focusedElement: ElementRef;

  title: any;
  message: any;

  constructor(dialogService: DialogService, public text: StaticTextService) {
    super(dialogService);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
    if (this.focusedElement != undefined) {
      this.focusedElement.nativeElement.focus();
    }
  }

  Close() {
    this.result = true;
    this.close();
  }

}
