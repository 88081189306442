import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { QuoteService, StaticTextService, OrderListingService, ProgressBarService, AwsS3Service, ConfigService, AuthService, RoutingStateService, NewOrderService, FileService } from 'app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from '../../app.constants';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { UUID } from 'angular2-uuid';
import { MessageComponent } from 'app/dialog/message/message.component';
import { SuccessComponent } from 'app/dialog/success/success.component';
import * as _ from 'underscore';
import * as momentTimeZone from 'moment-timezone';
import { ConvertOrderComponent } from 'app/dialog/convert-order/convert-order.component';
import { CancelDialogComponent } from 'app/dialog/cancel-dialog/cancel-dialog.component';
import { ConfirmComponent } from 'app/dialog/confirm/confirm.component';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
	public paymentIsRequired: boolean = false;
  quoteDetails: any = {}
  quoteId: any;
  orderStatus: any = {};
  orderIns: any = {};
  messages: any = [];
  documents = [];
  clientDocs: any = [];
  adminDocs: any = [];
  newDocs: any = [];
  constants = CONSTANTS;
  parentMsgId = null;
  primaryColor = '#03a9f4';
  dwnlFlag: boolean = false;
  dwndMessage: string = "";
  timer: any = [];
  products: any;
  constructor(
    private quoteService: QuoteService,
    private route: ActivatedRoute,
    private orderList: OrderListingService,
    public text: StaticTextService,
    private progressServ: ProgressBarService,
    private dialogService: DialogService,
    private s3Service: AwsS3Service,
    private config: ConfigService,
    private router: Router,
    private auth: AuthService,
    private routingState: RoutingStateService,
    private newOrder: NewOrderService,
    private fileService: FileService
  ) { }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.route.params.subscribe(params => {
      this.quoteId = params['quoteId'];
      this.initializeProgressBar();
      this.getQuoteDetails(this.quoteId)
      this.fetchUploadProgress();
      this.checkIfCleintPrePay()
      if(this.route.snapshot.queryParams['showPopup']) window.history.replaceState({}, "Hide", '/quote/'+ this.quoteId +'/view' );
      this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
    })
  }

  checkIfCleintPrePay() {
    this.newOrder.reqUserPrepaymentStatus().subscribe(({ prepayment }) => {
      this.paymentIsRequired = prepayment === 1;
    });    
  }

  getQuoteDetails(quoteId) {
    this.quoteService.getQuoteDetails(quoteId)
      .subscribe((data) => {
        if (data) {
          this.quoteDetails = data
          this.getProductsForCreateOrder();
          this.getOrderAdditionalDetails();
          this.setProgressBarStatus(this.quoteDetails['Order_Status'])
        } else this.router.navigate(['/quotes']);
      }, (err) => {
        console.log(err);
        if (err.code == "PTU10") {
          this.router.navigate(['/orders/current']);
        } else {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          this.auth.logout();
          this.router.navigate(['/login']);
        }
      })
  }

  getOrderAdditionalDetails() {
    this.getOrderNotes(this.quoteId);
    this.getOrderMessages(this.quoteId);
    this.orderStatus = this.orderList.getOrderStatus();
    this.getOrderDocs();
  }

  getOrderNotes(orderId) {
    this.orderList.getOrderNotes(orderId)
      .subscribe((notes) => {
        notes.forEach(note => {
          if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) this.orderIns = note;
        })
      }, (err) => {
        console.log(err);
      })
  }

  getOrderMessages(orderId) {
    this.orderList.getOrderMessages(orderId)
      .subscribe((messages) => {
        this.messages = messages;
        this.messages.forEach((message) => {
          this.isThreadedMessage(message);
        })
      }, (err) => {
        console.log(err);
      })
  }

  isThreadedMessage(msg) {
    if (msg.Parent_Message_ID && this.parentMsgId == msg.Parent_Message_ID) {
      msg.replyFlag = false;
    } else {
      this.parentMsgId = msg.Parent_Message_ID;
      msg.replyFlag = true;
    }
  }

  getOrderDocs() {
    this.orderList.getOrderDocuents(this.quoteId)
      .subscribe((data) => {
        if (data) {
          this.documents = data;
          this.clientDocs = this.documents.filter((x) => x.Document_Type == '2');
          this.adminDocs = this.documents.filter((x) => x.Document_Type == '1');
        }
      }, (err) => {
        console.log(err);
      })
  }

  buildAddress(model) {
    let address = '';
    if (model.Property_Address_1) address += model.Property_Address_1
    if (model.Property_Address_2) {
      if (address) address += '\n'
      address += model.Property_Address_2
    }
    if (model.Property_City) {
      if (address) address += '\n'
      address += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (address) address += ', '
      address += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (address) address += ' '
      address += model.Property_ZipCode
    }
    return address;
  }

  initializeProgressBar() {
    this.progressServ.setPrimaryColor(this.primaryColor);
    this.progressServ.setHeight(2);
    this.progressServ.setStage(0);
    this.progressServ.setProgressState((0 / 4) * 100);
    this.progressServ.setBarType(2);
    this.progressServ.setTotalStages(5);
  }

  setProgressBarStatus(status) {
    var stage = this.getStage(status);
    this.progressServ.setStage(stage);
    this.progressServ.setProgressState(((stage - 1) / 3) * 100);
  }

  getStage(status) {
    if (status == CONSTANTS.orderStatus.received || status == CONSTANTS.orderStatus.assigned) return 1;
    else if (status == CONSTANTS.orderStatus.confirmed) return 2;
    else if (status == CONSTANTS.orderStatus.processing) return 3;
    else if (status == CONSTANTS.orderStatus.quotecompleted) return 4;
  }

  downloadAllFiles(orderId) {
    this.dwnlFlag = true;
    this.afterDwnd();
    this.orderList.downloadAll(orderId)
      .subscribe((docs) => {
        docs.forEach((doc) => {
          var link = document.createElement('a');
          link.target = '_blank';
          link.href = doc.Link;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        this.dwnlFlag = false;
      },
        (err) => {
          this.dwnlFlag = false;
          if (err.code == 'PTU25') {
            this.openErrorPopup(CONSTANTS.apiErrors.docunavailable);
          } else
            this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
        })
  }

  afterDwnd() {
    this.dwndMessage = "Please wait. Your downloads will start";
    this.timer.push(setTimeout(() => this.dwndMessage = "", 3000));
  }

  openErrorPopup(msg) {
    let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
      message: msg
    }).subscribe((res) => { });
  }

  downloadFile(filename, orderDocId) {
    this.orderList.download(orderDocId)
      .subscribe(
        (userFile) => {
          if (userFile) {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.download = filename;
            link.href = userFile.Link;
            link.click();
            document.body.removeChild(link);
          }
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
        })
  }

  uploadDocument(event) {
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      if (this.checksize(event.target.files)) {
        var totDocs = event.target.files.length;
        for (var i = 0; i < event.target.files.length; i++) {
          var docId = UUID.UUID();
          this.newDocs.push({ docId: docId, name: event.target.files[i].name, completed: 0 })
          fileUploads.push(this.uploadToS3AndUpdatePath(docId, event.target.files[i]))
        }
        Promise.all(fileUploads)
          .then((files) => {
            this.removeCompletedDocs(files);
            var succesFiles = files.filter((file) => file !== undefined);
            if (succesFiles.length > 0) {
              this.getOrderDocs();
              if (this.quoteDetails.Order_Status == CONSTANTS.orderStatus.quotecompleted) this.getOrderMessages(this.quoteDetails.Order_ID)
            };
          })
          .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload) })
      }
      else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    }
    else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile)
    }
  }

  validateFilenames(event) {
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(',') > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }


  uploadToS3AndUpdatePath(docId, file) {
    return this.s3Service.onManagedUpload(file, this.quoteId)
      .then((data) => {
        return this.s3Service.updateDocumentPath(this.quoteId,
          this.quoteDetails.Property_ID,
          file,
          data.Key,
          this.extractFilename(data.Key)
        )
          .then(() => { return { docId: docId, name: file.name, completed: 100 } })
          .catch((err) => { throw err; })
      })
      .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  fetchUploadProgress() {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
        if (this.newDocs[matchedIndex]) {
          this.newDocs[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          this.newDocs[matchedIndex].completed = Math.round(this.newDocs[matchedIndex].completed);
        }
      });
  }

  goBack() {
    let arrayHistory = this.routingState.getHistory()
    let startingIndex = _.findIndex(arrayHistory, (x) => x.includes('quote/' + this.quoteDetails.Order_ID + '/view'));
    let lastIndex = _.findLastIndex(arrayHistory, (x) => x.includes('quote/' + this.quoteDetails.Order_ID + '/view'))
    arrayHistory.splice(startingIndex, lastIndex + 1)
    let lastLoc
    if (arrayHistory.length) lastLoc = arrayHistory[arrayHistory.length - 1];
    else this.router.navigate(['/orders/current']);
    if (this.routingState.getPrevUrl() && (
      this.routingState.getPrevUrl().includes("login") ||
      this.routingState.getPrevUrl().includes("view") ||
      this.routingState.getPrevUrl().includes("step2")
    )) {
      this.router.navigate(['/orders/current'])
    }
    else if (lastLoc) {
      if (lastLoc.indexOf("?") > 0) {
        let lastLocation = lastLoc.slice(0, lastLoc.indexOf("?"));
        return this.router.navigate([lastLocation]);
      } else this.router.navigate([lastLoc])
    }
  }

  openMessageInterface(msg, type, cancelQuoteFlag = false) {
    let disposable = this.dialogService.addDialog(MessageComponent, {
      title: this.text.get('BTN13'),
      message: msg
    })
      .subscribe((res) => {
        if (res) {
          if (type == 'cancel') {
            var data = {};
            data['Message_Text'] = res
            data['Order_ID'] = this.quoteDetails.Order_ID;
            data['Sender_ID'] = this.auth.getUserId();
            if (cancelQuoteFlag) data['cancelQuoteFlag'] = cancelQuoteFlag;
            this.orderList.cancelOrderMessage(data)
              .subscribe((data) => {
                this.openSuccessDialog('Success', 'We have received your request. We will get back to you very shortly.')
              }, (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
              })
          }
          else this.sendMessage(res)
        }
      });
  }

  openSuccessDialog(title, msg) {
    let disposable = this.dialogService.addDialog(SuccessComponent, {
      title: title,
      message: msg
    })
      .subscribe((res) => {
        if (res) this.getOrderMessages(this.quoteId);
      });
  }

  sendMessage(text) {
    var data = {
      Message_Text: text
    }
    this.orderList.sendMessage(this.quoteId, data)
      .subscribe((data) => {
        this.getOrderMessages(this.quoteId);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  convertToOrder() {
    if(this.newOrder.quoteData && this.newOrder.quoteData.selectedProduct) this.quoteDetails.selectedProduct = null;
    if(this.newOrder.quoteData && this.newOrder.quoteData.File_ID) this.quoteDetails.File_ID = null;
    this.showOrderPopup();
  }

  showConvertToOrderPopup() {
    if(this.newOrder.quoteData.selectedProduct) this.quoteDetails.selectedProduct = this.newOrder.quoteData.selectedProduct;
    if(this.newOrder.quoteData.File_ID) this.quoteDetails.File_ID = this.newOrder.quoteData.File_ID;
    this.showOrderPopup();
  }
  
  showOrderPopup() {
    let disposable = this.dialogService.addDialog(ConvertOrderComponent, {
      quoteDetails: this.quoteDetails,
      productDetails: this.products,
      isPaymentFlag: this.paymentIsRequired
    })
    .subscribe((orderRes) => {
      if(orderRes && orderRes.state){
        this.setOrderRelatedData(orderRes.orderDetails)
        if(this.paymentIsRequired) {
          this.router.navigate(['/convert/'+ this.quoteDetails.Customer_ID +'/'+ this.quoteDetails.Order_ID +'/step2'])}
        else this.router.navigate(['/convert/'+ this.quoteDetails.Customer_ID +'/'+ this.quoteDetails.Order_ID +'/step3']);
      }
    })
  }

  setOrderRelatedData(orderDetails) {
    this.newOrder.pricingData = {
      Order_Final_Price: orderDetails.Order_Custom_Price,
      Order_Final_Price_Tax:0
    }
    this.newOrder.propertyData = {
      Property_Order_Number:orderDetails.File_ID,
      Property_Products: [orderDetails.selectedProduct],
      selectedProduct: orderDetails.selectedProduct,
      Order_Buyer:orderDetails.Order_Co_Buyer,
      Property_Address_1:orderDetails.Property_Address_1,
      Property_City:orderDetails.Property_City,
      Property_County:orderDetails.Property_County,
      Property_State_Abbr:orderDetails.Property_State_Abbr,
      Property_ZipCode:orderDetails.Property_ZipCode
    }
    this.newOrder.searchType = orderDetails.selectedProduct;
    this.newOrder.quoteData = orderDetails;
  }

  getProductsForCreateOrder() {
    let customerId = this.quoteDetails.Organization_ID ? this.quoteDetails.Organization_ID : this.quoteDetails.Customer_ID;
    this.orderList.getOrderProductsForClient(customerId)
      .subscribe((product) => {
        this.products = product;
        if(this.route.snapshot.queryParams['showPopup']) this.showConvertToOrderPopup()
      })
  }

  openCancelDialog() {
    return this.dialogService.addDialog(CancelDialogComponent, {
      title: 'Cancel Quote',
      message: "Do you really want to cancel the quote?",
      buttonValue : "Confirm"
    })
  }

  checkForCancel() {
    this.openCancelDialog()
      .subscribe((res) => {
        if (res) {
          this.quoteService.cancelQuoteRequest(this.quoteId)
          .subscribe(() => {
            this.getQuoteDetails(this.quoteId)
          })
        }
      })
  }

  deleteFile(filename, orderDocId) {
    this.dialogService.addDialog(ConfirmComponent, {
      title: 'Delete Document',
      message: 'Are you sure want to delete ' + filename + ' ?'
    })
      .subscribe((res) => {
        if (res) {
          this.fileService.delete(orderDocId, this.auth.getUserId())
            .subscribe(
              (data) => {
                this.getOrderDocs();
              },
              (err) => {
                console.log(err);
                this.openErrorPopup(CONSTANTS.apiErrors.docdelete);
              })
        } else {
          console.log("Closed", res)
        }
      });
  }
}
