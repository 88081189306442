import { Component, ChangeDetectorRef } from '@angular/core';
import { DialogService, DialogComponent } from 'ng2-bootstrap-modal';
import { OrderListingService, StaticTextService } from 'app/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';

@Component({
  selector: 'app-convert-order',
  templateUrl: './convert-order.component.html',
  styleUrls: ['./convert-order.component.scss']
})

export class ConvertOrderComponent extends DialogComponent<any, any> {
  quoteDetails : any;
  orderModel : any = {};
  productDetails: any;
  convertOrderForm: FormGroup
  isPaymentFlag: boolean;
  constructor(dialogService: DialogService, private orderList: OrderListingService, private fb: FormBuilder,
    public text: StaticTextService, private cdr: ChangeDetectorRef) {
    super(dialogService);
    this.convertOrderForm = fb.group({
      Property_Order_Number: [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      Order_Type: [null, Validators.required],
    })
  }

  ngOnInit() {
    if(this.quoteDetails.selectedProduct) this.orderModel.selectedProduct = this.quoteDetails.selectedProduct
    if(this.quoteDetails.File_ID)this.orderModel.Property_Order_Number = this.quoteDetails.File_ID;
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {

  }

  Close() {
    this.result = { state: false }
    this.close()
  }

  createOrderForQuote() {
    let productFindIndex = this.productDetails.findIndex((product) => product.Product_ID == this.orderModel.selectedProduct);
    this.quoteDetails['selectedProduct'] = this.orderModel.selectedProduct;
    this.quoteDetails['Product_Description'] = this.productDetails[productFindIndex].Product_Description;
    this.quoteDetails['Product_Name'] = this.productDetails[productFindIndex].Product_Name;
    this.quoteDetails['File_ID'] = this.orderModel.Property_Order_Number;
    this.result = {
      state : true,
      orderDetails : this.quoteDetails
    }
    this.close();
  }

  getTotalStages() {
    if(this.isPaymentFlag) return 3;
    else return 2;
  }
}
