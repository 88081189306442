import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AuthService, StaticTextService, NewOrderService, OrderListingService, PagerService, StateService,
  SocketService, ConfigService, ScrollService
} from '../../services';
import { CONSTANTS } from '../../app.constants'
import { Router } from '@angular/router';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import * as momentTimeZone from 'moment-timezone';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  constants = CONSTANTS
  private ngUnsubscribe = new Subject();
  orders: any = []
  orderStatus: any = {};
  ordersToDisplay: any;
  totalCount: number;
  pager: any = {};
  searchString: string;
  searchInProgress: boolean = false;
  sortField: string;
  orderBy: string;
  pageNum: number;
  listingType = 'my'
  filterBy: any = [];
  timer:any = [];
  userData: any = {};
  scrollEnable: boolean = true;
  srollDistance=this.config.setScrollDistance();
  srollThrottle=this.config.setScrollThrottle();
  searchQuery = new Subject<string>();
  constructor(
    private auth: AuthService,
    private router: Router,
    public text: StaticTextService,
    private newOrder: NewOrderService,
    private orderList: OrderListingService,
    private pagerService: PagerService,
    private config: ConfigService,
    private preloaderService: PreloaderService,
    private socket: SocketService,
    private stateService: StateService,
    private dialogService: DialogService,
    private scrollService: ScrollService,
    private cdr : ChangeDetectorRef
  ) {
    this.pager.pages = [];
    if(this.pageNum == undefined)
    this.pageNum = 1;
    this.orderBy = 'DESC';
    this.sortField = 'Order_Creation_Date';
    this.searchString = '';
      this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {  
    this.auth.isMultiCompany$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        if(state) this.router.navigate(['accounts']);
      });
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.orderStatus = this.orderList.getOrderStatus();
    if (this.stateService.getopenOrders()) {
      this.initializeComponentData(this.stateService.getopenOrders());
    } else{
      this.checkListingType()
      this.getOrders();
    }
    this.socket.syncMessage("order").subscribe((result) => {
      console.log("CAUGHT EVENT EMITTED ON CREATE............")
      this.getOrdersOnBg();
    })
    this.timer.push(setTimeout(() => this.config.setSideBarNode(1), 0));
    this.cdr.detectChanges();
  }

  goToNewOrder() {
    this.newOrder.goToNewOrder()
  }

  checkListingType(){
    if (!this.auth.isIndividualUser()) {
      if (this.listingType == 'my') this.filterBy.push({ Customer_ID: this.auth.getUserId() });
      else this.filterBy.pop('Customer_ID');
    }
  }

  detectTypeChange(event) {
    this.pageNum = 1;
    document.documentElement.scrollTop = 0
    var x = document.documentElement.scrollTop
    this.scrollService.setScrollPosOpendOrd(x);
    this.checkListingType();
    this.getOrders();
  }

  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayOrders();
  //   this.getOrders();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getOrders();
  }

  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.ordersToDisplay = this.orders.slice(start, this.totalCount);
    if (this.ordersToDisplay.length > this.config.getNumRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  getOrders() {
    if(this.pageNum == 1){
      this.stateService.setopenOrders(this);
      this.orderList.getOpenOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
        .subscribe((result) => {
          this.getOrdersData(result);
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          // this.auth.logout();
          // this.router.navigate(['/login']);
        })
    }else this.getRetainData()

  }

  getRetainData(){
    var ObsCollection = [];
    for(var i=1; i<=this.pageNum; i++){
      ObsCollection.push(this.orderList.getOpenOrders(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedOrder) => {
        if(retainedOrder)
          this.getRetainedOrder(retainedOrder)
      })
  }

  getOrdersOnBg() {
    if(this.pageNum == 1){
      this.orderList.getBackgroundOpenOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
        .subscribe((result) => {
          this.getOrdersData(result);
        })
    }else{
      this.getRetainDataBg()
    }
  }

  getRetainDataBg(){
    var ObsCollection = [];
    for(var i=1; i<=this.pageNum; i++){
      ObsCollection.push(this.orderList.getBackgroundOpenOrders(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedOrder) => {
        if(retainedOrder)
          this.getRetainedOrder(retainedOrder);
      })
  }

  // search(event) {
  //   this.searchString = event;
  //   var userComp = this;
  //   function waitForIt(curStr) {
  //     if (userComp.searchInProgress) {
  //       this.timer.push(setTimeout(function () { waitForIt(curStr) }, 100));
  //     } else {
  //       if (userComp.searchString == curStr) userComp.findOrders();
  //     };
  //   }
  //   waitForIt(this.searchString);
  // }

  searchField(search){
    this.scrollService.setScrollPosOpendOrd(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if(!format.test(this.searchString)&& this.searchString==" "){
      this.searchString='';
      this.getOrders();
    }else this.findOrders();
  }

  findOrders() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getOrders();
  }

  goToOrderDetails(orderId) {
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosOpendOrd(getPos);
    this.router.navigate(['/orders/' + orderId + '/view'])
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.ordersToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.listingType = data.listingType;
    this.checkListingType();
    this.getOrders();
  }

  openErrorPopup(msg) {
    let disposable = this.dialogService.addDialog(ErrorDialogComponent, {
      message: msg
    }).subscribe((res) => { });
  }

  isIndividualUser() {
    return this.auth.isIndividualUser();
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  handleEvent(event){
    this.scrollEnable=event;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }
  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.orderList.getOpenOrders(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy)
        .subscribe((result) => {
          if (result) {
            // this.config.resetShowFlag()
            this.totalCount = result.count;
            if (this.totalCount > this.orders.length) {
              this.orders = this.orders.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getOrdersRecordData(result) {
    // this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPager(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  getOrdersData(result){
    this.orders = result.rows;
    this.totalCount = result.count;
    this.getOrdersRecordData(this.totalCount)
    this.getScrollPosData();
  }

  getScrollPosData(){
    if(this.scrollService.getScrollPosOpenOrd()){
      var x = this.scrollService.getScrollPosOpenOrd();
      this.timer.push(setTimeout(function() {
        window.scrollTo(0,x);
      }))
    }
  }

  getRetainedOrder(retainedOrder){
    var totalOrders = [];
    for(var i=0; i<retainedOrder.length; i++){
      this.stateService.setopenOrders(this);
      totalOrders = totalOrders.concat(retainedOrder[i]['rows']);
    }
    this.orders = totalOrders;
    this.totalCount = retainedOrder[0]['count'];
    this.getOrdersRecordData(this.totalCount)
    this.getScrollPosData()
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  ngOnDestroy(){
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    if(this.timer){
    this.timer.forEach(time => clearTimeout(time));
    }
  }
}
