import { Component, OnInit } from '@angular/core';
import { theme } from '@styled';
import { ProgressBarService, AuthService, OrderListingService, ProductService,StripeService, StaticTextService, AboutService } from '@services';
import * as newOrderService from '@services/new-order';
import { addressToString, createError, get, __DEV__ } from '@utils';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { DialogService } from 'ng2-bootstrap-modal';
import { ConvertOrderComponent } from '@dialog/convert-order/convert-order.component';
import { Location } from '@angular/common';
import { SuccessComponent } from '@dialog/success/success.component';
import { TermsConditionsComponent } from '@dialog/terms-conditions/terms-conditions.component';

@Component({
  selector: 'app-quote-review',
  templateUrl: './quote-review.component.html',
  styleUrls: ['./quote-review.component.scss']
})
export class QuoteReviewComponent implements OnInit {
	public ETA: string;
	public propertyAddress: string;
	public paymentIsRequired: boolean = false;
	public depositAmount: number = 0;
	public searchRequirements: any;
	public termsAccepted: boolean = false;
	private primaryColor: string = '#0F9D58';
  public billingAddress: string;
  public searchType: string;
  public productDescription: string;
	public cardDetails: any;
  quoteId: any;
  constructor(
    private progressService: ProgressBarService,
    public order: newOrderService.service,
    private router: Router,
    private auth: AuthService,
    private productService: ProductService,
    private orderListService: OrderListingService,
    private stripeService: StripeService,
    public staticText: StaticTextService,
    private dialogService: DialogService,
    private route: ActivatedRoute,
		public aboutService: AboutService
    ) { }

  ngOnInit() {
    this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
    this.quoteId = this.route.snapshot.params['orderId'];
    document.body.style.background = theme.colors.bg.level1;
    this.setProgressBar();
    this.rehydrateState();
  }

  private setProgressBar() {
		this.progressService.setPrimaryColor(this.primaryColor);
		this.progressService.setHeight(2);
		this.progressService.setProgressState(100);
		this.progressService.setBarType(1);
  }


  
  private rehydrateState = () => {
		if (this.order.propertyData) {
			this.order.reqUserPrepaymentStatus().subscribe(({ prepayment }) => {
				this.paymentIsRequired = prepayment === 1;
				if (prepayment === 1) this.depositAmount = 100;
				this.setTotalStages();
			});
      this.billingAddress = addressToString(this.order.billingData);
      if(this.order.propertyData.Property_Address_1) this.propertyAddress = addressToString(this.order.propertyData)
			this.cardDetails = this.order.stripeCard;

			this.reqProducts().subscribe(products => {
				this.searchType = get(
					products.find(p => p.Product_ID === this.order.searchType),
					'Product_Description',
				);
      this.productDescription = get(
        products.find(p => p.Product_ID === this.order.searchType),
        'Product_Name',
      );
			});

			this.reqProductRequirements(this.order.searchType).subscribe(requirements => {
				this.searchRequirements = requirements;
			});
      this.ETA =  this.order.quoteData.Order_Estimated_Time;
		} else this.router.navigate(['/quote/'+ this.quoteId +'/view']);
  };
  
  private setTotalStages() {
		if (this.paymentIsRequired) {
			this.progressService.setStage(2);
			this.progressService.setTotalStages(3);
		} else {
			this.progressService.setStage(3);
			this.progressService.setTotalStages(2);
		}
  }

  private get userId() {
		return this.auth.getUserOrgId() || this.auth.getUserId();
	}
  
  private reqProductRequirements = (productId: number) =>
  this.productService.getProductRequirements(productId);

  private reqProducts = () =>
  this.orderListService.getOrderProductsForClient(this.userId);

  private reqProductETA = (productId: number) =>
  this.productService.getProductEstimatedTime(`${productId}`);

  public getCardImageByBrand(brand: string) {
		return this.stripeService.getCardImageByBrand(brand);
  }
  
  checkForProductDesc() {
		if(this.productDescription && this.productDescription.match(/_/g)) this.productDescription = this.productDescription.replace(/_/g," ");
		return this.productDescription
	}
  
  prevStep() {
    // if(this.order.stripeCardId) this.router.navigate([`/convert/${this.auth.getUserId()}/${this.quoteId}/step2`]);
    // else {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          showPopup:true
        },
      };
      this.router.navigate(['/quote/'+ this.quoteId +'/view'], navigationExtras)
    // }
  }

  createQuote() {
    this.auth.checkIfQualaiUser(this.auth.getUserId()).subscribe((data) => {
      this.order.convertToOrderFromQuotes(this.order.quoteData, data.status)
      .subscribe((data) => {
        this.openSuccessDialog('Success', 'Quote '+ this.quoteId+' converted into order successfully.')
        this.router.navigate(['/orders/'+ this.quoteId + '/view']);
      })
    })    
  }

  openSuccessDialog(title, msg) {
    let disposable = this.dialogService.addDialog(SuccessComponent, {
      title: title,
      message: msg
    })
      .subscribe((res) => {});
  }

  public showTermsPopup = () => {
		this.aboutService.getActiveEULA().subscribe(eula => {
			this.dialogService
				.addDialog(TermsConditionsComponent, { eulaData: eula })
				.subscribe(() => {});
		});
	};
}

