import { Directive,ElementRef, HostListener,OnChanges,Input } from '@angular/core';

@Directive({
  selector: '[autogrow]'
})
export class AutogrowDirective implements OnChanges {

  @Input() public input: any;

  constructor(
    private el: ElementRef
  ) { }
 
  @HostListener('input', ['$event'])
  onInput(textarea: HTMLAreaElement): void {
    this.adjust();
  }
 
 
  adjust(): void {
    let nativeElement = this.el.nativeElement;
    nativeElement.style.height = 82 + 'px';
    let hgt = 230;
    let hgt1 = nativeElement['scrollHeight'] + 5
    if (hgt1 < hgt) {
      nativeElement.style.overflow = 'hidden';
      nativeElement.style.height = 'auto';
      nativeElement.style.height = hgt1 + "px";
    } else {
      nativeElement.style.height = hgt + "px";
      nativeElement.style.overflowY = "scroll";
    }
  }
  ngOnChanges(changes): void {
    if (changes.input) {
      this.adjust();
    }
  }
}
