import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@utils';

@Pipe({
	name: 'isNotNil',
})
export class IsNotNilPipe implements PipeTransform {
	transform(value: any) {
		return !isNil(value);
	}
}
