import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {
	AuthService,
	PricingService,
	ProgressBarService,
	StaticTextService,
	StripeService,
} from '@services';
import * as newOrderService from '@services/new-order';
import { theme } from '@styled';
import * as utils from '@utils';
import {
	addressToObj,
	Dict,
	get,
	scrollToFocusElements,
	to,
	__DEV__,
	isObj,
} from '@utils';
import { ErrorDialogComponent } from 'app/dialog/error-dialog/error-dialog.component';
import { SuccessComponent } from 'app/dialog/success/success.component';
import { DialogService } from 'ng2-bootstrap-modal';
import { Subject } from 'rxjs';
import { ErrorHandler } from '../../../error-handler/error.handler';

@Component({
	selector: 'app-new-order2',
	templateUrl: './new-order2.component.html',
	styleUrls: ['./new-order2.component.scss'],
})
export class NewOrder2Component implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('addressRef') addressRef: any;

	@ViewChild('nextStepBtn') nextStepRef: ElementRef;

	@ViewChild('paymentMethod') paymentMethod: any;

	/* ---------------------------------- */

	error: any;

	isValid: boolean = false;

	isLoading: boolean = true;

	timer: any;

	states: any[];

	primaryColor: string = '#0F9D58';

	unsubscribe = new Subject();

	removeFocusListeners: () => void;

	/* ---------------------------------- */

	constructor(
		protected auth: AuthService,
		protected cdr: ChangeDetectorRef,
		protected dialogService: DialogService,
		protected errorHandler: ErrorHandler,
		protected formBuilder: FormBuilder,
		protected pricingService: PricingService,
		protected progressBarService: ProgressBarService,
		protected router: Router,
		protected stripe: StripeService,
		public order: newOrderService.service,
		public staticText: StaticTextService,
	) {
		if (__DEV__) {
			window['utils'] = window['utils'] || utils;
			window['order'] = { ...window['order'], step2: this };
		}
	}

	ngOnInit() {
		this.auth.getHubSpotTokenDetails();
		this.auth.loadHubSpotChatToken();
		this.setProgressBar();
		this.rehydrateOrRedirect();
	}

	ngAfterViewInit() {
		// Set UI theme
		document.body.style.background = theme.colors.bg.level1;

		// Scroll to top
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 300);
		// Listen for scroll events
		this.removeFocusListeners = scrollToFocusElements(
			get(this, 'ref.rootNodes.0.parentElement', document.body),
		);
	}

	ngOnDestroy() {
		// Restore UI
		document.body.style.background = '';
		// Unsubscribe
		this.unsubscribe.next();
		this.unsubscribe.complete();
		if (this.removeFocusListeners) this.removeFocusListeners();
	}

	/* ---------------------------------- */

	/**
	 * Redirect to step 1 if user needs to do it (null),
	 * else rehydrate data.
	 */
	rehydrateOrRedirect = async () => {
		const { billingData, propertyData, stripeCard } = this.order;

		if (propertyData) {
			const states = await to.obj(this.reqStates());
			if (!states.err) this.states = states.data;

			if (!this.paymentMethod.isLoading) {
				// Payment method/address from prev step or query fallbacks
				if (isObj(stripeCard)) {
					// Payment method data sets address.
					this.addressRef.patchValue(stripeCard);
					this.paymentMethod.selected = stripeCard;
				} else if (billingData) {
					// Patch address data from a prev step.
					this.addressRef.patchValue(billingData);
				} else {
					// Patch address with user default address.
					const [err, address] = await to(this.auth.getDefaultAddress());
					if (!err) this.addressRef.patchValue(address);
				}
			}

			// Request pricing data
			const [, pricing] = await to(this.reqProductPricing());
			this.order.pricingData = {
				Order_Max_Tat: get(pricing, 'Cost_Max_Tat'),
				Order_Min_Tat: get(pricing, 'Cost_Min_Tat'),
				Order_Final_Price: get(pricing, 'Cost_Amount', 0),
				Order_Final_Price_Tax: get(pricing, 'Cost_Tax_Amount', 0),
			};

			// Enable the UI
			this.isLoading = false;
			this.cdr.detectChanges();
		} else {
			this.prevStep();
		}
		return this.cdr.detectChanges();
	};

	/* ---------------------------------- */

	protected setProgressBar = () => {
		this.progressBarService.setBarType(1);
		this.progressBarService.setHeight(2);
		this.progressBarService.setPrimaryColor(this.primaryColor);
		this.progressBarService.setProgressState(50);
		this.progressBarService.setStage(1);
		this.progressBarService.setTotalStages(3);
	};

	public handleValid = () => {
		if (this.paymentMethod.isValid && this.addressRef.valid) {
			this.isValid = true;
			this.cdr.detectChanges();
		}
	};

	public handleInvalid = () => {
		if (this.paymentMethod.isValid && this.addressRef.valid) {
			this.isValid = true;
			this.cdr.detectChanges();
		}
	};

	public handleCardChange = (card: Dict) => {
		this.isValid = this.addressRef.valid && get(this, 'paymentMethod.isValid');
		this.cdr.detectChanges();
		this.isValid && this.nextStepRef['focus']();
	};

	public handleCardLoaded = async (card: Dict) => {
		if (isObj(card)) {
			this.addressRef.patchValue(addressToObj(card));
		} else {
			const [err, address] = await to(this.auth.getDefaultAddress());
			if (!err) this.addressRef.patchValue(address);
		}
		this.isLoading = false;
		return this.cdr.detectChanges();
	};

	public handleCardSelect = (card: Dict) => {
		this.addressRef.patchValue(addressToObj(card));
		this.isValid = this.addressRef.valid && get(this, 'paymentMethod.isValid');
		this.cdr.detectChanges();
		 this.isValid && this.nextStepRef &&this.nextStepRef.nativeElement.focus();

	};

	protected reqProductPricing = () =>{
		let borrowerAddress = this.order.propertyData.Borrower_Address;
		if(borrowerAddress) {
			this.order.propertyData.Property_State_Abbr = borrowerAddress['Property_State_Abbr'];
			this.order.propertyData.Property_County = borrowerAddress['Property_County'];
		}
		return this.order.reqProductPricing(
			this.order.propertyData.Property_State_Abbr,
			this.order.propertyData.Property_County,
			this.order.searchType,
		);
	}


	protected reqStates = () => this.pricingService.getAvailableStateList();

	/* ---------------------------------- */

	public prevStep = () => {
		this.order.billingData = this.addressRef.value;
		this.order.stripeCard = get(this, 'paymentMethod.card');
		this.order.stripeCardId = get(this, 'paymentMethod.card.id');
		if(this.paymentMethod && this.paymentMethod.stripeUser) this.order.stripeCustomerId = get(this, 'paymentMethod.stripeUser.id');
		else this.order.stripeCustomerId = get(this, 'paymentMethod.card.customer');
		this.router.navigate([`/user/${this.auth.getUserId()}/orders/new/step1`]);
	};

	public nextStep = async () => {
		this.order.billingData = this.addressRef.value;
		console.log("Checking paymentMethod =",this.paymentMethod);
		if (get(this, 'paymentMethod.cardId')) {
			this.order.stripeCard = get(this, 'paymentMethod.card');
			this.order.stripeCardId = get(this, 'paymentMethod.card.id');
			if(this.paymentMethod && this.paymentMethod.stripeUser) this.order.stripeCustomerId = get(this, 'paymentMethod.stripeUser.id');
			else this.order.stripeCustomerId = get(this, 'paymentMethod.card.customer');
		} else {
			try {
				const card = get(this, 'paymentMethod.newCard.card');
				const address = this.order.billingData;
				const orderToken = await this.stripe.createToken(card, address);
				const incidentalToken = await this.stripe.createToken(card, address);

				this.order.stripeCardId = null;
				this.order.stripeOrderToken = get(orderToken, 'id');
				this.order.stripeIncidentalToken = get(incidentalToken, 'id');
			} catch (error) {
				throw new Error(error);
			}
		}

		this.router.navigate([`/user/${this.auth.getUserId()}/orders/new/step3`]);
	};

	/* ---------------------------------- */

	public openDialog = (title: string, message: string) =>
		this.dialogService
			.addDialog(SuccessComponent, { message, title })
			.subscribe(() => this.router.navigate(['/']));

	public openDialogError = (message: string) =>
		this.dialogService.addDialog(ErrorDialogComponent, { message }).subscribe();

	/* ---------------------------------- */

	/**
	 * Get's error message if any for the field given with help of Error Handler Service
	 * @param field          FormControlName as a string
	 * @return message      Error message as a sting
	 */
	getError(field: string): string {
		const errorMessage = this.errorHandler.getError(this.addressRef.fc, field);
		return this.removeBillingPrefix(errorMessage);
	}

	removeBillingPrefix = (message: string) => message.substring('Billing'.length);
}
