import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfigService, PreloaderService, StripeService } from '@services';
import { cxs } from '@styled';
import { Dict, isNil, to, __DEV__ } from '@utils';
import { PaymentMethodAdd } from './payment-method-add.component';

@Component({
	selector: 'payment-methods',
	template: `
		<stack direction="['column', null, 'row']" spacing="4">
			<h5 sx variant="title" w="72">Payment Methods</h5>

			<card sx flex="1">
				<!-- loading ▸ cards -->
				<card-content *ngIf="!cards">
					<ng-container *ngFor="let loading of [0, 1, 2]; last as isLast">
						<payment-method-card [isLoading]="!cards"></payment-method-card>
						<div *ngIf="!isLast" sx mt="4"></div>
					</ng-container>
				</card-content>

				<!-- loaded ▸ no cards  -->
				<card-content *ngIf="cards && cards.length === 0">
					<flex align="center" justify="center" direction="column">
						<icon colorScheme="grey.200" icon="payment" size="4rem"></icon>
						<p sx variant="subtitle" color="text.hint">You don't have any saved cards.</p>
					</flex>
				</card-content>

				<!-- loaded ▸ cards-->
				<card-content *ngIf="cards && cards.length > 0" sx maxH="80" overflow="auto">
					<ng-container *ngFor="let card of cards; last as isLast">
						<payment-method-card
							[card]="card"
							(onDelete)="handleDeletePaymentMethod($event)"
							(onUpdate)="handleUpdatePaymentMethod($event)"
						></payment-method-card>
						<div *ngIf="!isLast" sx mt="4"></div>
					</ng-container>
				</card-content>

				<!-- actions -->
				<card-actions hasDivider="true">
					<button
						*ngIf="!isOpen"
						(click)="openAddPaymentMethod()"
						[disabled]="isLoading"
						class="btn btn-default"
						sx
						variant="button.text button.contentIsSpaced"
					>
						<icon colorScheme="inherit" icon="credit_card"></icon>
						<span>Add a card</span>
					</button>
				</card-actions>
			</card>
		</stack>
	`,
})
export class PaymentMethods implements OnInit, AfterViewInit, OnDestroy {
	cards: any;

	isOpen: boolean = false;

	isValid: boolean = false;

	isLoading: boolean = true;

	/* ---------------------------------- */

	@ViewChild('addCard') newCard: any;

	@ViewChild('newCardBtn') newCardBtn: ElementRef;

	/* ---------------------------------- */

	constructor(
		protected cdr: ChangeDetectorRef,
		protected conf: ConfigService,
		protected stripe: StripeService,
		protected appPreloader: PreloaderService,
		public dialog: MatDialog,
	) {
		// If this is a dev environment, enhance the dev ergonomics by making global var available.
		if (__DEV__) {
			if (isNil(window['paymentMethods'])) window['paymentMethods'] = this;
		}
	}

	async ngOnInit() {
		const [err, cards] = await to(this.stripe.getCards());

		this.isLoading = false;
		if (!err) {
			this.cards = cards.reverse();
		}
		return this.cdr.detectChanges();
	}

	ngAfterViewInit() {
		document.body.style.backgroundColor = '#f8f9fa';
	}

	ngOnDestroy() {
		document.body.style.backgroundColor = '';
	}

	/* ---------------------------------- */

	openAddPaymentMethod = () =>
		this.dialog
			.open(PaymentMethodAdd, {
				autoFocus: false,
				panelClass: cxs({ 'mat-dialog-container': { p: 0 } }),
				width: '500px',
			})
			.afterClosed()
			.subscribe(this.handleAddPaymentMethod);

	handleAddPaymentMethod = (card: Dict) => {
		if (card) {
			this.cards = [card, ...this.cards];
		}
		return this.cdr.detectChanges();
	};

	handleUpdatePaymentMethod = (card: Dict) => {
		if (card) {
			const cardIdx = this.cards.findIndex(({ id }) => id === card.id);
			if (cardIdx > -1) {
				this.cards[cardIdx] = card;
			}
		}
		return this.cdr.detectChanges();
	};

	handleDeletePaymentMethod = (cardId: string) => {
		if (cardId) {
			this.cards = this.cards.filter(({ id }) => id !== cardId);
		}
		return this.cdr.detectChanges();
	};
}
