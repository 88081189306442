import { Injectable } from '@angular/core';

@Injectable()
export class StateService {

  pricings:any=null;
  messages:any=null;
  completedOrders:any=null;
  openOrders:any=null;
  cancelledOrders:any=null;
  quotes:any = null;
  constructor() { }

  resetAll(){    
    this.pricings=null;
    this.messages=null;
    this.completedOrders=null;
    this.openOrders=null;
    this.cancelledOrders=null;
    this.quotes=null;
  }

  
  setopenOrders(openOrders){
    this.openOrders=openOrders;    
  }

  getopenOrders(){
    if(this.openOrders) {      
      return this.openOrders
    }
  }
  setcancelledOrders(cancelledOrders){
    this.cancelledOrders=cancelledOrders;    
  }

  getcancelledOrders(){
    if(this.cancelledOrders) {      
      return this.cancelledOrders
    }
  }

  setmessages(messages){
    this.messages=messages;    
  }

  getmessages(){
    if(this.messages) {      
      return this.messages
    }
  }

  setCompletedOrders(completedOrders){
    this.completedOrders=completedOrders;    
  }

  getCompletedOrders(){
    if(this.completedOrders) {      
      return this.completedOrders
    }
  }


  setpricings(pricings){
    this.pricings=pricings;    
  }

  getpricings(){
    if(this.pricings) {      
      return this.pricings
    }
  }

  setQuoteRequest(quotes) {
    this.quotes = quotes;
  }

  getQuoteRequest(){
    if(this.quotes) return this.quotes;
  }

}
