import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { AuthService, ConfigService, StripeService } from '@services';
import * as newOrderService from '@services/new-order';
import { cxs } from '@styled';
import { Dict, get, isBool, isNil, to, __DEV__ } from '@utils';
import { PaymentMethodAdd } from './payment-method-add.component';

@Component({
	selector: 'payment-method-select',
	template: `
		<div>
			<!-- loading -->
			<skeleton *ngIf="isLoading" sx h="10" mt="8"></skeleton>

			<!-- loaded -->
			<mat-form-field
				*ngIf="!isLoading && cards"
				appearance="standard"
				floatLabel="never"
				sx
				w="100%"
				flex="1"
			>
				<mat-select
					(ngModelChange)="handleSelect($event)"
					[(ngModel)]="cardId"
					[ngModelOptions]="{ standalone: true }"
					floatLabel="never"
					name="creditCard"
				>
					<mat-select-trigger>
						<flex align="center">
							<ng-container *ngIf="cardId === 'default'">
								<h-stack align="center">
									<span sx mb="0" color="text.secondary">select a payment method</span>
								</h-stack>
							</ng-container>

							<ng-container *ngIf="cardId === 'addCard'">
								<h-stack align="center">
									<icon icon="credit_card" sx fontSize="5 !important"></icon>
									<span sx mb="0" color="text.secondary">use new card</span>
								</h-stack>
							</ng-container>

							<ng-container
								*ngIf="cardId !== 'addCard' && cardId !== 'default' && cardId"
							>
								<img
									alt="card"
									src="../../../assets/images/card-networks/{{
										stripe.getCardImage(card.brand)
									}}.png"
									sx
									h="20px"
									w="30px"
									mr="4"
									borderRadius="3px"
									border="1px solid divider.light"
									objectFit="cover"
								/>
								<span sx mb="0"> {{ card.brand }}&nbsp; •••• {{ card.last4 }} </span>
								<small sx color="text.hint">
									&nbsp;&nbsp;&nbsp;Exp {{ card.exp_month }}/{{ card.exp_year }}
								</small>
							</ng-container>
						</flex>
					</mat-select-trigger>

					<!-- no selection -->
					<mat-option value="default" [disabled]="cardId !== 'default'">
						<span sx variant="p" color="text.secondary">select a payment method</span>
					</mat-option>

					<!-- add card -->
					<mat-option value="addCard">
						<h-stack align="center">
							<icon icon="credit_card" sx fontSize="5 !important"></icon>
							<span sx variant="p" color="text.secondary">use new card </span>
						</h-stack>
					</mat-option>

					<!-- saved card -->
					<mat-optgroup *ngIf="cards.length > 0" label="Your Cards">
						<mat-option *ngFor="let card of cards" [value]="card.id">
							<flex align="center">
								<img
									alt="card"
									src="../../../assets/images/card-networks/{{
										stripe.getCardImage(card.brand)
									}}.png"
									sx
									h="20px"
									w="30px"
									mr="4"
									borderRadius="3px"
									border="1px solid divider.light"
									objectFit="cover"
								/>
								<span sx mb="0"> {{ card.brand }}&nbsp; •••• {{ card.last4 }} </span>
								<small sx color="text.hint">
									&nbsp;&nbsp;&nbsp;Exp {{ card.exp_month }}/{{ card.exp_year }}
								</small>
							</flex>
						</mat-option>
					</mat-optgroup>
				</mat-select>
				<mat-error>{{ error }}</mat-error>
			</mat-form-field>
		</div>
	`,
})
export class PaymentMethodSelect implements OnInit {
	// Payment method.
	card: Dict;

	// Saved stripe cards.
	cards: Dict[];

	// Payment method ID.
	cardId: string = 'default';

	// User Stripe account.
	stripeUser: Dict;

	// Stripe tokens.
	tokens: string[];

	// If the payment method is valid.
	isValid: boolean = false;

	// Any errors
	error: string;

	/* ---------------------------------- */

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	@Output() onError: EventEmitter<any> = new EventEmitter();

	@Output() onInvalid: EventEmitter<any> = new EventEmitter();

	@Output() onValid: EventEmitter<any> = new EventEmitter();

	@Output() onSelect: EventEmitter<any> = new EventEmitter();

	@Output() onLoaded: EventEmitter<any> = new EventEmitter();

	/* ---------------------------------- */

	@Input()
	get selected(): any {
		return this.card;
	}
	set selected(val: any) {
		this.card = val;
		this.onSelect.emit(val);
	}

	@Input()
	get isLoading(): boolean {
		return this._isLoading;
	}
	set isLoading(val: boolean) {
		if (isBool(val)) {
			this._isLoading = val;
		}
	}
	_isLoading: boolean = true;

	/* ---------------------------------- */

	constructor(
		protected auth: AuthService,
		protected cdr: ChangeDetectorRef,
		protected config: ConfigService,
		protected http: HttpClient,
		public order: newOrderService.service,
		public ref: ElementRef,
		public stripe: StripeService,
		public dialog: MatDialog,
	) {
		if (__DEV__) {
			window['paymentMethod'] = this;
		}
	}

	async ngOnInit() {
		const [err, acc] = await to(this.stripe.getCustomer());

		if ((isNil(this.card) && err) || isNil(get(acc, 'sources.data.0'))) {
			this.cards = [];
			this.cardId = 'default';
		} else {
			this.cards = get(acc, 'sources.data', []);
			this.card = get(this, 'card', get(this, 'cards.0'));
			this.cardId = get(this, 'cards.0.id', 'default');
			this.stripeUser = acc;
			this.isValid = true;
			this.onSelect.emit(this.card);
		}
		this.isLoading = false;
		this.onLoaded.emit(this.card);
		return this.cdr.detectChanges();
	}

	/* ---------------------------------- */

	_clearError = () => {
		this.error = null;
		return this.cdr.detectChanges();
	};

	_addPaymentMethod = () =>
		this.dialog
			.open(PaymentMethodAdd, {
				autoFocus: false,
				panelClass: cxs({
					'mat-dialog-container': { p: 0 },
				}),
				width: '500px',
			})
			.afterClosed()
			.subscribe(this._resolvePaymentMethod);

	_resolvePaymentMethod = (card: Dict) => {
		if (card) {
			this.cards = [card, ...this.cards];
			this.handleSelect(card.id);
		} else {
			this.handleSelect(get(this, 'cards.0.id', 'default'));
		}
	};

	handleChange = (event: any) => {
		if (event.complete && !event.error) {
			this.isValid = true;
			this.onValid.emit();
		} else if (!event.complete || event.error) {
			this.isValid = false;
			this.onInvalid.emit();
		}

		if (event.error) {
			this.onError.emit((this.error = event.error));
		}
		this.onChange.emit(event);
	};

	handleSelect = (cardId: string) => {
		const card = this.cards.find(card => card.id === cardId);

		if (cardId === 'addCard') {
			this.cardId = 'addCard';
			this._addPaymentMethod();
		} else if (cardId === 'default') {
			this.cardId = 'default';
			this.isValid = false;
		} else if (isNil(cardId)) {
			this.cardId = 'addCard';
			this.isValid = false;
		} else {
			this.card = card;
			this.cardId = cardId;
			this.isValid = Boolean(this.cardId);
		}
		this.onSelect.emit(card);
	};
}
