import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn, ValidationErrors } from '@angular/forms';


@Directive({
    selector: '[validateEmail][formControlName],[validateEmail][formControl],[validateEmail][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidator), multi: true }]
})
export class EmailValidator implements Validator {

    validate(control: AbstractControl): ValidationResult {
        //
        var EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        if (control.value && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
            return { validateEmail: false };
        } else {
            if (control.errors) delete control.errors['validateEmail'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}

@Directive({
    selector: '[validatePhone][formControlName],[validatePhone][formControl],[validatePhone][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PhoneValidator), multi: true }]
})
export class PhoneValidator implements Validator {

    validate(control: AbstractControl): ValidationResult {
        var PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if (control.value && (control.value.length != 12 || !PHONE_REGEXP.test(control.value))) {
            return { validatePhone: false };
        } else {
            if (control.errors) delete control.errors['validatePhone'];
            if (control.errors && !Object.keys(control.errors).length) control.setErrors(null);
        }

        return null;
    }

}

@Directive({
    selector: '[validatePassword][formControlName],[validatePassword][formControl],[validatePassword][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PasswordValidator), multi: true }]
})

export class PasswordValidator implements Validator{
    validate(control: AbstractControl): ValidationErrors | null {
        let value: string = control.value || '';
        
        if (!value) {
          return null
        }      
        let upperCaseCharacters = /[A-Z]+/g;
        let lowerCaseCharacters = /[a-z]+/g;
        let numberCharacters = /[0-9]+/g;
        let alphanumeric = /[a-zA-Z0-9_]+/g;
        let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            
        if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false) {
            return { passwordStrength: `Have an uppercase and a lowercase character` };
        }           
        // if (numberCharacters.test(value) === true) {
        //     return { passwordNumber: `Have at least 1 numeric value` }
        // }   
      
      }
} 


@Directive({
    selector: '[validateMultiPassword][formControlName],[validateMultiPassword][formControl],[validateMultiPassword][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => PasswordMultiValidator), multi: true }]
})

export class PasswordMultiValidator implements Validator{
    validate(control: AbstractControl): ValidationErrors | null {
        let value: string = control.value || '';
                
        if (!value) {
          return null
        }      
       
        let numberCharacters = /[0-9]+/g;
        let alphanumeric = /[a-zA-Z0-9]+/g;      
              
        if (numberCharacters.test(value) === false || alphanumeric.test(value) === false) {
            return { passwordNumber: `Have atleast 1 numeric` }
        }   
      
      }
} 


@Directive({
    selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true }
    ]
})

export class EqualValidator implements Validator {
    constructor( @Attribute('validateEqual') public validateEqual: string,
        @Attribute('reverse') public reverse: string) {
    }

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true : false;
    }

    validate(c: AbstractControl): ValidationResult {
        // self value
        let v = c.value;
        // control vlaue
        let e = c.root.get(this.validateEqual);

        // value not equal
        if (e && v !== e.value && !this.isReverse) {
            return {
                validateEqual: false
            }
        }

        // value equal and reverse
        if (e && v === e.value && this.isReverse) {
            delete e.errors['validateEqual'];
            if (!Object.keys(e.errors).length) e.setErrors(null);
        }

        // value not equal and reverse
        if (e && v !== e.value && this.isReverse) {
            e.setErrors({ validateEqual: false });
        }

        return null;
    }
}

export function NoWhitespaceValidator(): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {

        // messy but you get the idea
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': 'value is only whitespace' }

    };
}

interface ValidationResult {
    [key: string]: boolean;
}
