import { Injectable } from '@angular/core';
import { css, cx, cxs, globalCss, setTheme, theme } from '@styled';
import { isObj } from '@utils';

@Injectable({ providedIn: 'root' })
export class ThemeService {
	constructor() {}

	public get theme() {
		return theme;
	}

	public set theme(updatedTheme: object) {
		if (isObj(updatedTheme)) setTheme(updatedTheme);
	}

	public css = (args: object = {}) => css(args);

	public cx = (obj: any) => cx(obj);

	public cxs = (...args: any) => cxs(...args);

	public global = (args: object = {}) => globalCss(args);
}
